import { themeToVars as r } from "@mantine/vanilla-extract";
import { rem as e, createTheme as s } from "@mantine/core";
const o = {
  xl: {
    fontSize: e(24),
    fontWeight: 400,
    letterSpacing: 0.02,
    lineHeight: "135%"
  },
  lg: {
    fontSize: e(20),
    fontWeight: 400,
    letterSpacing: -0.01,
    lineHeight: "130%"
  },
  md: {
    fontSize: e(16),
    fontWeight: 400,
    letterSpacing: -0.01,
    lineHeight: "135%"
  },
  sm: {
    fontSize: e(14),
    fontWeight: 400,
    letterSpacing: -0.01,
    lineHeight: "130%"
  },
  xs: {
    fontSize: e(12),
    fontWeight: 400,
    letterSpacing: -0.01,
    lineHeight: "120%"
  }
}, F = {
  h1: {
    fontSize: e(64),
    fontWeight: 600,
    letterSpacing: -0.4,
    lineHeight: "100%"
  },
  h2: {
    fontSize: e(52),
    fontWeight: 600,
    letterSpacing: 0.04,
    lineHeight: "100%"
  },
  h3: {
    fontSize: e(40),
    fontWeight: 600,
    letterSpacing: -0.4,
    lineHeight: "100%"
  },
  h4: {
    fontSize: e(28),
    fontWeight: 600,
    letterSpacing: -0.4,
    lineHeight: "100%"
  },
  h5: {
    fontSize: e(20),
    fontWeight: 600,
    letterSpacing: -0.4,
    lineHeight: "100%"
  }
};
e(136), e(96);
const f = {
  100: "#EEF2FD",
  200: "#B9CCF6",
  300: "#97B3F1",
  400: "#7499ED",
  500: "#5180E8",
  600: "#225EE2",
  700: "#1B53CF",
  800: "#184AB9",
  900: "#1541A2"
}, h = {
  100: "#EAF8ED",
  200: "#ABE3B9",
  300: "#82D495",
  400: "#58C672",
  500: "#2EB84F",
  600: "#29A346",
  700: "#248F3D",
  800: "#1F7A35",
  900: "#145223"
}, a = {
  100: "#FFFFFF",
  200: "#FAFAFA",
  300: "#F5F5F5",
  400: "#E5E5E5",
  500: "#CCCCCC",
  600: "#999999",
  700: "#666666",
  800: "#333333",
  900: "#000000"
}, E = {
  100: "#FFE6DA",
  200: "#FFCCB6",
  300: "#FFB391",
  400: "#FF996D",
  500: "#FF8048",
  600: "#FF5C14",
  700: "#FA4C00",
  800: "#E04500",
  900: "#C73D00"
}, p = {
  100: "#FFEBEB",
  200: "#FFD6D6",
  300: "#FFC2C2",
  400: "#FFADAD",
  500: "#FF9999",
  600: "#FF8080",
  700: "#FF6666",
  800: "#FF4C4C",
  900: "#FF1919"
}, S = {
  100: "#F1EDFD",
  200: "#C6B9F7",
  300: "#AA96F2",
  400: "#8D73EE",
  500: "#7150EA",
  600: "#603BE7",
  700: "#4E24E5",
  800: "#421AD6",
  900: "#3515AD"
}, c = {
  100: "#FEFEFD",
  200: "#FCFAF6",
  300: "#F7F2E9",
  400: "#F0E7D6",
  500: "#E9DCC3",
  600: "#E2D1B0",
  700: "#DCC69E",
  800: "",
  900: "#DCC69E"
}, C = {
  100: "#FEF6DA",
  200: "#FCEEB6",
  300: "#FBE591",
  400: "#F9DD6D",
  500: "#F8D448",
  600: "#F6C918",
  700: "#ECBD09",
  800: "#D3AA08",
  900: "#BB9607"
}, x = {
  500: "#2EC4A0",
  900: "#178268",
  100: "#EAF9F6"
}, D = {
  500: "#E56F43",
  900: "#9D3A16",
  100: "#FCF1EC"
}, z = {
  500: "#E33C5A",
  900: "#A11730",
  100: "#FCECEF"
}, t = {
  purple: S,
  blue: f,
  green: h,
  neutral: a,
  sand: c,
  orange: E,
  pink: p,
  yellow: C,
  positive: x,
  warning: D,
  negative: z
};
function n(g) {
  return Object.values(g);
}
const m = {
  neutral: n(t.neutral),
  sand: n(t.sand),
  blue: n(t.blue),
  purple: n(t.purple),
  green: n(t.green),
  positive: n(t.positive),
  negative: n(t.negative),
  warning: n(t.warning),
  orange: n(t.orange),
  pink: n(t.pink),
  yellow: n(t.yellow)
}, i = {
  xs: 4,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 24,
  "2xl": 32,
  "3xl": 40
}, l = {
  1: "0px 2px 10px 0px rgba(0, 0, 0, 0.08)",
  2: "0px 4px 16px 0px rgba(0, 0, 0, 0.16)",
  3: "0px 6px 32px 0px rgba(0, 0, 0, 0.16)"
}, A = s({
  colors: m,
  shadows: {
    1: l[1],
    2: l[1],
    3: l[3]
  },
  breakpoints: { xxl: "112.5em" },
  spacing: {
    xs: e(i.xs),
    sm: e(i.sm),
    md: e(i.md),
    lg: e(i.lg),
    xl: e(i.xl),
    "2xl": e(i["2xl"]),
    "3xl": e(i["3xl"])
  },
  primaryColor: "neutral",
  fontFamily: "Telegraf",
  fontSizes: {
    xl: o.xl.fontSize,
    lg: o.lg.fontSize,
    md: o.md.fontSize,
    sm: o.sm.fontSize,
    xs: o.xs.fontSize
  },
  headings: {
    sizes: {
      h1: { fontSize: F.h1.fontSize },
      h2: { fontSize: F.h2.fontSize },
      h3: { fontSize: F.h3.fontSize },
      h4: { fontSize: F.h4.fontSize },
      h5: { fontSize: F.h5.fontSize }
    }
  }
}), u = r(A);
export {
  A as _,
  u as t
};
