import Layout from '@shared/layout/index';
import Authentication from '@modules/core/pages/Authentication';

import DocumentsOverview from '@modules/documents/pages/Overview';
import Home from '@modules/core/pages/Home';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import KYCOverview from '@modules/kyc/pages/Overview';
import KYCPersonal from '@modules/kyc/pages/Category/Personal';
import KYCCategory from '@modules/kyc/pages/Category';
import KYCResidency from '@modules/kyc/pages/Category/Residency';
import KYCEmployment from '@modules/kyc/pages/Category/Employment';
import KYCFinance from '@modules/kyc/pages/Category/Liability';
import { layoutStyles } from '@shared/layout/v1/styles/index.css';
import { lazy } from 'react';

const ProposalPreviewPage = lazy(() => import('@modules/proposals/pages/Preview/index'));

export const router: ReturnType<typeof createBrowserRouter> = createBrowserRouter([
  {
    path: '/login/:oppId?',
    element: (
      <Authentication />
    ),
  },
  {
    path: '/proposal/:proposalId',
    element: (
      <ProposalPreviewPage />
    ),
  },
  {
    path: '/',
    element: <Layout
      headerTitlePath='documents.title'
      subTitlePath='documents.subtitle'
    />,
    children: [
      {
        path: '/',
        element: <Home />,
      }
    ],
  },
  {
    index: false,
    element: <Layout
      headerTitlePath='documents.title'
      subTitlePath='documents.subtitle'
      headerClassName={ layoutStyles.documentsHeaderStyles }
    />,
    children: [
      {
        path: '/documents',
        element: <DocumentsOverview />,
      }
    ],
  },
  {
    index: false,
    element: <Layout
      headerTitlePath='kyc.title'
      subTitlePath='kyc.subtitle'
      headerClassName={ layoutStyles.kycHeaderStyles }
    />,
    children: [
      {
        path: '/kyc',
        element: <KYCOverview />,
      },
      {
        path: '/kyc',
        index: false,
        element: <KYCCategory />,
        children: [
          {
            path: 'personal/:applicantId',
            element: <KYCPersonal />,
          },
          {
            path: 'residency/:applicantId',
            element: <KYCResidency />,
          },
          {
            path: 'employment/:applicantId',
            element: <KYCEmployment />,
          },
          {
            path: 'finance/:applicantId',
            element: <KYCFinance />,
          }
        ],
      }
    ],
  },
  {
    path: '*',
    element: <Navigate to='/' />,
  }
]);
