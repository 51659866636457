// eslint-disable-next-line sonarjs/cognitive-complexity
export const retrieveFormErrors = (errors: Record<string, any>, recursiveKey?: string) => {
  let errorsToSet: Record<string, string> = {};
  if (errors) {
    const apiErrors = Object.entries(errors);
    if (apiErrors.length > 0) {
      apiErrors.forEach(([key, value]) => {
        if (Array.isArray(value)) {
          const errorKey = recursiveKey ? `${recursiveKey}.${key}` : key;
          // eslint-disable-next-line prefer-destructuring
          errorsToSet[errorKey] = value[0];
        } else if (typeof value === 'object') {
          const res = retrieveFormErrors(value, recursiveKey ? `${recursiveKey}.${key}` : key);
          errorsToSet = { ...errorsToSet, ...res };
        }
      });
    }
  }
  return errorsToSet;
};
