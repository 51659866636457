import {
  Box,
  CardProps,
  Flex,
  Image, Card as MantineCard, Skeleton, Stack, rem
} from '@mantine/core';
import {
  Button, Text, Tooltip, theme
} from '@huspy/forge';
import {
  IconArrowRight, IconCircleCheckFilled, IconExclamationCircle
} from '@tabler/icons-react';
import cx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { iconsPath } from '@shared/css.const';
import { useTranslation } from 'react-i18next';
import { DocumentStatus } from '@modules/documents/api/types';
import { DOCUMENT_STATUS } from '@modules/documents/api/const';
import { categoryNavigatorCardStyles } from './styles/index.css';
import CardProgressBar from '../CardProgressBar';

type Props = {
  title: string
  description?: string;
  note?: string;
  buttonText?: string;
  progress?: number;
  isLoading?: boolean;
  to: string;
  trackEvent?(): void;
  iconName?: string;
  iconSize?: number;
  progressBarText?: string;
  hideContent?: boolean;
  progressBarFullWidth?: boolean;
  disabled?: boolean;
  status?: DocumentStatus;
} & Pick<CardProps, 'classNames' | 'styles' | 'mt' | 'mb' | 'ml' | 'mr'>;

const NavigatorCard = ({
  title,
  description,
  note,
  buttonText,
  classNames,
  progress,
  disabled,
  trackEvent,
  progressBarText,
  hideContent = false,
  progressBarFullWidth = true,
  iconName = 'documents.svg',
  to,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isLoading,
  status,
  ...props
}: Props) => {
  // @ts-expect-error
  const { root } = { ...classNames };
  const iconUrl = `${iconsPath}/${iconName}`;
  const navigate = useNavigate();
  const progressComplete = !!(progress && progress === 100);
  const { t } = useTranslation();

  const handleNavigate = () => {
    if (!disabled) {
      navigate(to);
      trackEvent?.();
    }
  };

  const getCardIcon = () => {
    if (status === DOCUMENT_STATUS.invalid) {
      return (
        <Tooltip label='There are one or more documents that require your attention'>
          <IconExclamationCircle
            size={ 20 }
            style={ { color: theme.colors.orange[4], alignSelf: 'center' } }
          />
        </Tooltip>
      );
    }
    return (
      <Image
        src={ iconUrl }
        className={ categoryNavigatorCardStyles.icon }
      />
    );
  };

  const cardContent: React.ReactNode | null = (
    <>
      <Text size='sm' c='neutral.6'>{ description }</Text>
      { !disabled && (
        <Box mt={ theme.spacing.sm }>
          { progress !== undefined
            ? (
              <CardProgressBar
                progressBarText={ progressBarText ?? t('home.documentsOverview.documentsCardProgress', { value: progress }) }
                value={ progress }
              />
            )
            : (
              <Button variant='secondary' size='sm' disabled={ disabled }>{ buttonText }</Button>
            )}
        </Box>
      )}
    </>
  );

  return (
    <MantineCard
      classNames={ { root: cx(categoryNavigatorCardStyles.root({ status, disabled }), root), ...classNames } }
      display='flex'
      { ...props }
      onClick={ handleNavigate }
    >
      <MantineCard.Section
        display='flex'
        styles={ {
          section: {
            display: 'flex',
            alignItems: 'flex-start',
            flexGrow: 1,
            ...(progressBarFullWidth ? { flexGrow: 1 } : {}),
          },
        } }
      >
        <Flex
          align='center'
          justify='center'
        >
          {progressComplete ? (
            <IconCircleCheckFilled
              size={ 16 }
              style={ { color: theme.colors.green[4], alignSelf: 'center' } }
            />
          ) : getCardIcon()}

        </Flex>
        <Box ml={ theme.spacing.lg } { ...(progressBarFullWidth ? { w: '100%' } : {}) }>
          <Stack gap={ theme.spacing.xs }>
            <Text size='md' fw={ 600 } { ...(disabled && { c: 'neutral.5' }) }>{ title }</Text>
            {!hideContent && (
              <>
                { cardContent }
                {note && <Text size='sm' c='neutral.6' mt='lg'>{ note }</Text>}
              </>
            ) }
          </Stack>
        </Box>

      </MantineCard.Section>
      {!disabled && (
        <MantineCard.Section styles={ {
          section: {
            display: 'flex',
            alignSelf: 'flex-start',
          },
        } }
        >
          <IconArrowRight color={ theme.colors.neutral[5] } size={ 18 } cursor='pointer' />
        </MantineCard.Section>
      )}

    </MantineCard>
  );
};

// eslint-disable-next-line react/display-name
NavigatorCard.Skeleton = ({ noMarginTop }: { noMarginTop?: boolean }) =>
  (<Skeleton height={ rem(101) } radius='12px' { ...(!noMarginTop ? { mt: theme.spacing.lg } : {}) } />);

export default NavigatorCard;
