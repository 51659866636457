import AppSkeleton from '@modules/core/components/Skeleton';
import { Suspense, lazy } from 'react';
import { useOutletContext } from 'react-router-dom';

const KYCEmploymenUAE = lazy(() => import('./features/KYCEmployment/ae'));
const KYCEmploymenES = lazy(() => import('./features/KYCEmployment/es'));

const KYCEmployment = () => {
  const { isSpainBusiness } = useOutletContext<{ isSpainBusiness: boolean }>();

  return (
    <Suspense fallback={ <AppSkeleton.AplicantKycForm /> }>
      {isSpainBusiness ? <KYCEmploymenES /> : <KYCEmploymenUAE />}
    </Suspense>
  );
};

export default KYCEmployment;
