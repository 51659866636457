import { Container, ContainerProps } from '@mantine/core';
import clsx from 'clsx';
import { floatingContainerStyles } from './styles/index.css';

type Props = {
  children: React.ReactNode;
  className?: string;
} & ContainerProps;

const FloatingContainer = ({ children, className, ...rest }: Props) => (
  <Container className={ clsx(floatingContainerStyles, className) } w='100%' { ...rest }>
    { children }
  </Container>
);

export default FloatingContainer;
