import { kycApi } from '@modules/kyc/api';
import { useQuery } from '@tanstack/react-query';

const useGetBanks = () => {
  const {
    data, isLoading, error, refetch,
  } = useQuery({
    queryKey: ['banks'],
    queryFn: () => kycApi.getBanks(),
    staleTime: Number.POSITIVE_INFINITY,
  });

  return {
    data, isLoading, error, refetch,
  };
};

export default useGetBanks;
