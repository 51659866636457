import { z } from 'zod';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const emailSchema = z.union([
  z.string().email().max(100),
  z.literal(''),
  z.null()
]);

export const urlSchema = z.union([
  z.string().regex(
    /^(https?:\/\/(www\.)?|www\.)([\w#%+.:=@~-]+)+((\.[A-Za-z]{2,3})+)(\/(.)*)?(\?(.)*)?/,
    'Invalid url'
  ),
  z.string().regex(
    /^([\w#%+.:=@~-]+)+((\.[A-Za-z]{2,3})+)(\/(.)*)?(\?(.)*)?/,
    'Invalid url'
  ),
  z.literal(''),
  z.null()
]);

export const stringSchema = z.string().optional().nullable();
export const stringSchemaMax = (max: number) =>
  z.string().max(max).optional().nullable();

export const numberSchema = z.number().optional().nullable();

export const enumSchema = (list: [string, ...string[]]) =>
  z.union([z.enum(list), z.literal(''), z.null()]).optional();

export const phoneNumberSchema = z
  .string()
  .refine(
    (value) => {
      if (!value) return true;
      try {
        const phoneNumber = parsePhoneNumberFromString(value);
        return phoneNumber?.isValid() ?? false;
      } catch {
        return false;
      }
    },
    { message: 'Invalid phone number format' }
  )
  .optional()
  .nullable();
