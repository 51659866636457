import { ApplicantProgressKyc } from '@modules/core/api/types';
import { useEffect, useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

export const kycStepsInOrderAe = ['personal', 'residency', 'employment', 'finance'];
export const kycStepsInOrderEs = ['personal', 'employment', 'finance'];

const kycStepsInOrderByIdentifier = {
  ae: kycStepsInOrderAe,
  es: kycStepsInOrderEs,
};

const useKycGateProgression = (
  progress: {
    [key in (keyof ApplicantProgressKyc['progress_per_category'])]: number | undefined;
  },
  isFetched: boolean,
  isSpainBusiness: boolean = false
) => {
  const match = useMatch('/kyc/:category/:applicantId');
  const category = match?.params.category;
  const navigate = useNavigate();
  const applicantId = match?.params.applicantId;

  const kycStepsInOrder = kycStepsInOrderByIdentifier[isSpainBusiness ? 'es' : 'ae'];

  const stateProgress = useMemo<{ [key in(typeof kycStepsInOrder[number])]: boolean }>(() => ({
    personal: true,
    residency: isSpainBusiness ? false : progress?.personal_information === 100,
    employment: isSpainBusiness ? progress?.personal_information === 100 : progress?.residential_information === 100,
    finance: progress?.employment_information === 100,
  }), [
    isSpainBusiness,
    progress?.employment_information,
    progress?.personal_information,
    progress?.residential_information
  ]);

  const currentAcitveStep = kycStepsInOrder.find((val, idx) =>
    stateProgress[val] === true && !stateProgress[kycStepsInOrder[idx + 1]!]);

  const currentAcitveStepIdx = kycStepsInOrder.indexOf(currentAcitveStep!);

  const goToNextStep = (idx: number) => {
    if (currentAcitveStepIdx < idx) {
      return;
    }

    let step = idx;
    if (idx > kycStepsInOrder.length || idx < 0) {
      step = 0;
    }
    navigate(`/kyc/${kycStepsInOrder[step]}/${applicantId}`);
  };

  const canSkipToTheNextStep = (stepToAdvance: typeof kycStepsInOrder[number]) =>
    kycStepsInOrder.indexOf(stepToAdvance) <= currentAcitveStepIdx;

  useEffect(() => {
    if (!category?.length) {
      navigate('/kyc');
    }
  }, [category, navigate]);

  useEffect(() => {
    if (isFetched && category && !stateProgress[category as keyof typeof stateProgress]) {
      const currentStepIdx = kycStepsInOrder.indexOf(category as typeof kycStepsInOrder[number]);
      navigate(`/kyc/${kycStepsInOrder[currentStepIdx - 1] ?? 'personal'}/${applicantId}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    category,
    navigate,
    stateProgress
  ]);

  return {
    currentAcitveStep,
    currentAcitveStepIdx,
    goToNextStep,
    canSkipToTheNextStep,
  };
};

export default useKycGateProgression;
