import { z } from 'zod';
import {
  enumSchema, numberSchema, stringSchema
} from '@shared/schema';
import { LIABILITY_TYPE } from '@modules/kyc/api/const';

const LiabilityTypeSchema = enumSchema([
  LIABILITY_TYPE.creditCard,
  LIABILITY_TYPE.personalLoan,
  LIABILITY_TYPE.autoLoan,
  LIABILITY_TYPE.mortgage
]);

export const liabilitySchema = z.object({
  type: LiabilityTypeSchema,
  value: numberSchema,
  description: stringSchema,
  outstanding_amount: numberSchema,
  monthly_cost: numberSchema,
  external_id: stringSchema,
});

export const liabilitiesSchema = z.array(
  z.object({
    type: LiabilityTypeSchema,
    value: numberSchema,
    description: stringSchema,
    outstanding_amount: numberSchema,
    monthly_cost: numberSchema,
    external_id: stringSchema,
  })
);
