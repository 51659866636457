import { API_V2_URL } from '@shared/const';
import http from '@shared/http';
import {
  KYCCountry, KYCData, UpdateKycData
} from './types';

export const kycApi = {
  getKYCData: <TCountry extends KYCCountry = 'AE'>(opportunityId: string) =>
    http.get<KYCData<TCountry>>(`${API_V2_URL}/vaults/${opportunityId}/applicants`),
  getBanks: () =>
    http.get<{
      external_id: string;
      logo: string;
      title: string;
    }[]>(`${API_V2_URL}/vaults/banks`),
  updateKYCData:
    <TCountry extends KYCCountry = 'AE'>(opportunityId: string, applicantId: string, body: UpdateKycData<TCountry>) =>
      http.post(`${API_V2_URL}/vaults/${opportunityId}/applicants/${applicantId}`, body),
} as const;
