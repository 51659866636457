import { documentsApi } from '@modules/documents/api';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';

export type RequestPresignedUrlType = {
  url: string;
  fields: any;
  body: FormData;
  opportunityExternalID: string;
  opportunityApplicantExternalID: string;
  opportunityApplicantDocumentExternalID?: string;
  fileName?: string;
};

const useUploadFileToS3 = (
  opportunityExternalID: string,
  opportunityApplicantExternalID: string
) => {
  const { t } = useTranslation();

  const {
    error, mutate, mutateAsync, isPending,
  } = useMutation({
    mutationKey: [
      'uploadFileToS3',
      opportunityExternalID,
      opportunityApplicantExternalID
    ],
    mutationFn: (request: RequestPresignedUrlType) =>
      documentsApi.uploadDocumentUsingPresignedUrl(
        request.url,
        request.fields,
        request.body
      ),
    onSuccess: (_, request: RequestPresignedUrlType) => {
      const {
        opportunityApplicantDocumentExternalID,
        fileName,
      } = request;
      if (opportunityApplicantDocumentExternalID && fileName) {
        toast('info', { message: t('documents.notifications.documentUploaded') });
      }
    },
    onError: (data) => {
      toast('error', {
        message:
          data.message || t('documents.notifications.documentUploadFailed'),
      });
    },
  });

  return {
    mutate,
    mutateAsync,
    error,
    isPending,
  };
};

export default useUploadFileToS3;
