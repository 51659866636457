import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import useGetFeatureFlagsDirectly from '@modules/core/hooks/queries/useGetFeatureFlagsDirectly';
import { useAuthStore } from '@modules/core/store';
import HomeV2 from './v2/Home';
import HomeV1 from './v1/Home';

const Home = () => {
  const { oppId } = useAuthStore();
  const { data: isSimplifiedVault } = useGetFeatureFlagsDirectly(
    oppId!,
    AMPLITUDE_FEATURE_FLAGS.CLIENT_VAULT_SIMPLIFIED
  );

  if (isSimplifiedVault) {
    return <HomeV2 />;
  }

  return <HomeV1 />;
};

export default Home;
