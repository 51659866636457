import { EMPLOYMENT_STATUS } from '@modules/kyc/api/const';
import {
  enumSchema,
  numberSchema,
  phoneNumberSchema,
  stringSchema,
} from '@shared/schema';
import { z } from 'zod';

const employmentTypeSchema = enumSchema([
  EMPLOYMENT_STATUS.salaried,
  EMPLOYMENT_STATUS.selfEmployed,
  EMPLOYMENT_STATUS.unemployed,
  EMPLOYMENT_STATUS.soleProprietorship,
  EMPLOYMENT_STATUS.government,
  EMPLOYMENT_STATUS.temporary,
  EMPLOYMENT_STATUS.other
]);

export const employmentSchemaEs = z.object({
  employment_type: employmentTypeSchema,
  company_name: stringSchema,
  industry: stringSchema,
  job_title: stringSchema,
  company_contact_no: phoneNumberSchema,
  no_of_employees: numberSchema,
  start_date: stringSchema,
  no_of_annual_payments: z.number().max(50).optional().nullable(),
});
