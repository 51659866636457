import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import useGetFeatureFlagsDirectly from '@modules/core/hooks/queries/useGetFeatureFlagsDirectly';
import { useAuthStore } from '@modules/core/store';
import { ReactNode } from 'react';

const BehindMaintenance = ({ children }: { children: ReactNode }) => {
  const { oppId } = useAuthStore();

  const featureFlag = useGetFeatureFlagsDirectly(
    oppId ?? '',
    AMPLITUDE_FEATURE_FLAGS.CLIENT_VAULT_MAINTENANCE_MODE
  );

  if (featureFlag.data) {
    window.location.replace('/maintenance');
    return;
  }

  return children;
};

export default BehindMaintenance;
