const a = [
  {
    name: "Afghanistan",
    alpha2: "AF",
    alpha3: "AFG",
    states: []
  },
  {
    name: "Albania",
    alpha2: "AL",
    alpha3: "ALB",
    states: []
  },
  {
    name: "Algeria",
    alpha2: "DZ",
    alpha3: "DZA",
    states: []
  },
  {
    name: "Andorra",
    alpha2: "AD",
    alpha3: "AND",
    states: []
  },
  {
    name: "Angola",
    alpha2: "AO",
    alpha3: "AGO",
    states: []
  },
  {
    name: "Anguilla",
    alpha2: "AI",
    alpha3: "AIA",
    states: []
  },
  {
    name: "Antigua and Barbuda",
    alpha2: "AG",
    alpha3: "ATG",
    states: []
  },
  {
    name: "Argentina",
    alpha2: "AR",
    alpha3: "ARG",
    states: []
  },
  {
    name: "Armenia",
    alpha2: "AM",
    alpha3: "ARM",
    states: []
  },
  {
    name: "Aruba",
    alpha2: "AW",
    alpha3: "ABW",
    states: []
  },
  {
    name: "Australia",
    alpha2: "AU",
    alpha3: "AUS",
    states: [
      {
        name: "Australian Capital Territory",
        stateCode: "ACT"
      },
      {
        name: "New South Wales",
        stateCode: "NSW"
      },
      {
        name: "Northern Territory",
        stateCode: "NT"
      },
      {
        name: "Queensland",
        stateCode: "QLD"
      },
      {
        name: "South Australia",
        stateCode: "SA"
      },
      {
        name: "Tasmania",
        stateCode: "TAS"
      },
      {
        name: "Victoria",
        stateCode: "VIC"
      },
      {
        name: "Western Australia",
        stateCode: "WA"
      }
    ]
  },
  {
    name: "Austria",
    alpha2: "AT",
    alpha3: "AUT",
    states: []
  },
  {
    name: "Azerbaijan",
    alpha2: "AZ",
    alpha3: "AZE",
    states: []
  },
  {
    name: "Bahamas",
    alpha2: "BS",
    alpha3: "BHS",
    states: []
  },
  {
    name: "Bahrain",
    alpha2: "BH",
    alpha3: "BHR",
    states: []
  },
  {
    name: "Bangladesh",
    alpha2: "BD",
    alpha3: "BGD",
    states: []
  },
  {
    name: "Barbados",
    alpha2: "BB",
    alpha3: "BRB",
    states: []
  },
  {
    name: "Belarus",
    alpha2: "BY",
    alpha3: "BLR",
    states: []
  },
  {
    name: "Belgium",
    alpha2: "BE",
    alpha3: "BEL",
    states: []
  },
  {
    name: "Belize",
    alpha2: "BZ",
    alpha3: "BLZ",
    states: []
  },
  {
    name: "Benin",
    alpha2: "BJ",
    alpha3: "BEN",
    states: []
  },
  {
    name: "Bermuda",
    alpha2: "BM",
    alpha3: "BMU",
    states: []
  },
  {
    name: "Bhutan",
    alpha2: "BT",
    alpha3: "BTN",
    states: []
  },
  {
    name: "Bolivia, Plurinational State of",
    alpha2: "BO",
    alpha3: "BOL",
    states: []
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    alpha2: "BQ",
    alpha3: "BES",
    states: []
  },
  {
    name: "Bosnia and Herzegovina",
    alpha2: "BA",
    alpha3: "BIH",
    states: []
  },
  {
    name: "Botswana",
    alpha2: "BW",
    alpha3: "BWA",
    states: []
  },
  {
    name: "Brazil",
    alpha2: "BR",
    alpha3: "BRA",
    states: [
      {
        name: "Acre",
        stateCode: "AC"
      },
      {
        name: "Alagoas",
        stateCode: "AL"
      },
      {
        name: "Amazonas",
        stateCode: "AM"
      },
      {
        name: "Amapá",
        stateCode: "AP"
      },
      {
        name: "Bahia",
        stateCode: "BA"
      },
      {
        name: "Ceará",
        stateCode: "CE"
      },
      {
        name: "Distrito Federal",
        stateCode: "DF"
      },
      {
        name: "Espírito Santo",
        stateCode: "ES"
      },
      {
        name: "Goiás",
        stateCode: "GO"
      },
      {
        name: "Maranhão",
        stateCode: "MA"
      },
      {
        name: "Minas Gerais",
        stateCode: "MG"
      },
      {
        name: "Mato Grosso do Sul",
        stateCode: "MS"
      },
      {
        name: "Mato Grosso",
        stateCode: "MT"
      },
      {
        name: "Pará",
        stateCode: "PA"
      },
      {
        name: "Paraíba",
        stateCode: "PB"
      },
      {
        name: "Pernambuco",
        stateCode: "PE"
      },
      {
        name: "Piauí",
        stateCode: "PI"
      },
      {
        name: "Paraná",
        stateCode: "PR"
      },
      {
        name: "Rio de Janeiro",
        stateCode: "RJ"
      },
      {
        name: "Rio Grande do Norte",
        stateCode: "RN"
      },
      {
        name: "Rondônia",
        stateCode: "RO"
      },
      {
        name: "Roraima",
        stateCode: "RR"
      },
      {
        name: "Rio Grande do Sul",
        stateCode: "RS"
      },
      {
        name: "Santa Catarina",
        stateCode: "SC"
      },
      {
        name: "Sergipe",
        stateCode: "SE"
      },
      {
        name: "São Paulo",
        stateCode: "SP"
      },
      {
        name: "Tocantins",
        stateCode: "TO"
      }
    ]
  },
  {
    name: "Brunei Darussalam",
    alpha2: "BN",
    alpha3: "BRN",
    states: []
  },
  {
    name: "Bulgaria",
    alpha2: "BG",
    alpha3: "BGR",
    states: []
  },
  {
    name: "Burkina Faso",
    alpha2: "BF",
    alpha3: "BFA",
    states: []
  },
  {
    name: "Burundi",
    alpha2: "BI",
    alpha3: "BDI",
    states: []
  },
  {
    name: "Cambodia",
    alpha2: "KH",
    alpha3: "KHM",
    states: []
  },
  {
    name: "Cameroon",
    alpha2: "CM",
    alpha3: "CMR",
    states: []
  },
  {
    name: "Canada",
    alpha2: "CA",
    alpha3: "CAN",
    states: [
      {
        name: "Alberta",
        stateCode: "AB"
      },
      {
        name: "British Columbia",
        stateCode: "BC"
      },
      {
        name: "Manitoba",
        stateCode: "MB"
      },
      {
        name: "New Brunswick",
        stateCode: "NB"
      },
      {
        name: "Newfoundland and Labrador",
        stateCode: "NL"
      },
      {
        name: "Nova Scotia",
        stateCode: "NS"
      },
      {
        name: "Northwest Territories",
        stateCode: "NT"
      },
      {
        name: "Nunavut",
        stateCode: "NU"
      },
      {
        name: "Ontario",
        stateCode: "ON"
      },
      {
        name: "Prince Edward Island",
        stateCode: "PE"
      },
      {
        name: "Quebec",
        stateCode: "QC"
      },
      {
        name: "Saskatchewan",
        stateCode: "SK"
      },
      {
        name: "Yukon Territories",
        stateCode: "YT"
      }
    ]
  },
  {
    name: "Cape Verde",
    alpha2: "CV",
    alpha3: "CPV",
    states: []
  },
  {
    name: "Cayman Islands",
    alpha2: "KY",
    alpha3: "CYM",
    states: []
  },
  {
    name: "Central African Republic",
    alpha2: "CF",
    alpha3: "CAF",
    states: []
  },
  {
    name: "Chad",
    alpha2: "TD",
    alpha3: "TCD",
    states: []
  },
  {
    name: "Chile",
    alpha2: "CL",
    alpha3: "CHL",
    states: []
  },
  {
    name: "China",
    alpha2: "CN",
    alpha3: "CHN",
    states: [
      {
        name: "Beijing",
        stateCode: "11"
      },
      {
        name: "Tianjin",
        stateCode: "12"
      },
      {
        name: "Hebei",
        stateCode: "13"
      },
      {
        name: "Shanxi",
        stateCode: "14"
      },
      {
        name: "Nei Mongol",
        stateCode: "15"
      },
      {
        name: "Liaoning",
        stateCode: "21"
      },
      {
        name: "Jilin",
        stateCode: "22"
      },
      {
        name: "Heilongjiang",
        stateCode: "23"
      },
      {
        name: "Shanghai",
        stateCode: "31"
      },
      {
        name: "Jiangsu",
        stateCode: "32"
      },
      {
        name: "Zhejiang",
        stateCode: "33"
      },
      {
        name: "Anhui",
        stateCode: "34"
      },
      {
        name: "Fujian",
        stateCode: "35"
      },
      {
        name: "Jiangxi",
        stateCode: "36"
      },
      {
        name: "Shandong",
        stateCode: "37"
      },
      {
        name: "Henan",
        stateCode: "41"
      },
      {
        name: "Hubei",
        stateCode: "42"
      },
      {
        name: "Hunan",
        stateCode: "43"
      },
      {
        name: "Guangdong",
        stateCode: "44"
      },
      {
        name: "Guangxi",
        stateCode: "45"
      },
      {
        name: "Hainan",
        stateCode: "46"
      },
      {
        name: "Chongqing",
        stateCode: "50"
      },
      {
        name: "Sichuan",
        stateCode: "51"
      },
      {
        name: "Guizhou",
        stateCode: "52"
      },
      {
        name: "Yunnan",
        stateCode: "53"
      },
      {
        name: "Xizang",
        stateCode: "54"
      },
      {
        name: "Shaanxi",
        stateCode: "61"
      },
      {
        name: "Gansu",
        stateCode: "62"
      },
      {
        name: "Qinghai",
        stateCode: "63"
      },
      {
        name: "Ningxia",
        stateCode: "64"
      },
      {
        name: "Xinjiang",
        stateCode: "65"
      },
      {
        name: "Chinese Taipei",
        stateCode: "71"
      },
      {
        name: "Hong Kong",
        stateCode: "91"
      },
      {
        name: "Macao",
        stateCode: "92"
      }
    ]
  },
  {
    name: "Christmas Island",
    alpha2: "CX",
    alpha3: "CXR",
    states: []
  },
  {
    name: "Cocos (Keeling) Islands",
    alpha2: "CC",
    alpha3: "CCK",
    states: []
  },
  {
    name: "Colombia",
    alpha2: "CO",
    alpha3: "COL",
    states: []
  },
  {
    name: "Comoros",
    alpha2: "KM",
    alpha3: "COM",
    states: []
  },
  {
    name: "Congo",
    alpha2: "CG",
    alpha3: "COG",
    states: []
  },
  {
    name: "Congo, the Democratic Republic of the",
    alpha2: "CD",
    alpha3: "COD",
    states: []
  },
  {
    name: "Cook Islands",
    alpha2: "CK",
    alpha3: "COK",
    states: []
  },
  {
    name: "Costa Rica",
    alpha2: "CR",
    alpha3: "CRI",
    states: []
  },
  {
    name: "Cote d'Ivoire",
    alpha2: "CI",
    alpha3: "CIV",
    states: []
  },
  {
    name: "Croatia",
    alpha2: "HR",
    alpha3: "HRV",
    states: []
  },
  {
    name: "Cuba",
    alpha2: "CU",
    alpha3: "CUB",
    states: []
  },
  {
    name: "Curaçao",
    alpha2: "CW",
    alpha3: "CUW",
    states: []
  },
  {
    name: "Cyprus",
    alpha2: "CY",
    alpha3: "CYP",
    states: []
  },
  {
    name: "Czech Republic",
    alpha2: "CZ",
    alpha3: "CZE",
    states: []
  },
  {
    name: "Denmark",
    alpha2: "DK",
    alpha3: "DNK",
    states: []
  },
  {
    name: "Djibouti",
    alpha2: "DJ",
    alpha3: "DJI",
    states: []
  },
  {
    name: "Dominica",
    alpha2: "DM",
    alpha3: "DMA",
    states: []
  },
  {
    name: "Dominican Republic",
    alpha2: "DO",
    alpha3: "DOM",
    states: []
  },
  {
    name: "Ecuador",
    alpha2: "EC",
    alpha3: "ECU",
    states: []
  },
  {
    name: "Egypt",
    alpha2: "EG",
    alpha3: "EGY",
    states: []
  },
  {
    name: "El Salvador",
    alpha2: "SV",
    alpha3: "SLV",
    states: []
  },
  {
    name: "Equatorial Guinea",
    alpha2: "GQ",
    alpha3: "GNQ",
    states: []
  },
  {
    name: "Eritrea",
    alpha2: "ER",
    alpha3: "ERI",
    states: []
  },
  {
    name: "Estonia",
    alpha2: "EE",
    alpha3: "EST",
    states: []
  },
  {
    name: "Ethiopia",
    alpha2: "ET",
    alpha3: "ETH",
    states: []
  },
  {
    name: "Falkland Islands (Malvinas)",
    alpha2: "FK",
    alpha3: "FLK",
    states: []
  },
  {
    name: "Faroe Islands",
    alpha2: "FO",
    alpha3: "FRO",
    states: []
  },
  {
    name: "Fiji",
    alpha2: "FJ",
    alpha3: "FJI",
    states: []
  },
  {
    name: "Finland",
    alpha2: "FI",
    alpha3: "FIN",
    states: []
  },
  {
    name: "France",
    alpha2: "FR",
    alpha3: "FRA",
    states: []
  },
  {
    name: "French Guiana",
    alpha2: "GF",
    alpha3: "GUF",
    states: []
  },
  {
    name: "French Polynesia",
    alpha2: "PF",
    alpha3: "PYF",
    states: []
  },
  {
    name: "Gabon",
    alpha2: "GA",
    alpha3: "GAB",
    states: []
  },
  {
    name: "Gambia",
    alpha2: "GM",
    alpha3: "GMB",
    states: []
  },
  {
    name: "Georgia",
    alpha2: "GE",
    alpha3: "GEO",
    states: []
  },
  {
    name: "Germany",
    alpha2: "DE",
    alpha3: "DEU",
    states: []
  },
  {
    name: "Ghana",
    alpha2: "GH",
    alpha3: "GHA",
    states: []
  },
  {
    name: "Gibraltar",
    alpha2: "GI",
    alpha3: "GIB",
    states: []
  },
  {
    name: "Greece",
    alpha2: "GR",
    alpha3: "GRC",
    states: []
  },
  {
    name: "Greenland",
    alpha2: "GL",
    alpha3: "GRL",
    states: []
  },
  {
    name: "Grenada",
    alpha2: "GD",
    alpha3: "GRD",
    states: []
  },
  {
    name: "Guadeloupe",
    alpha2: "GP",
    alpha3: "GLP",
    states: []
  },
  {
    name: "Guatemala",
    alpha2: "GT",
    alpha3: "GTM",
    states: []
  },
  {
    name: "Guernsey",
    alpha2: "GG",
    alpha3: "GGY",
    states: []
  },
  {
    name: "Guinea",
    alpha2: "GN",
    alpha3: "GIN",
    states: []
  },
  {
    name: "Guinea-Bissau",
    alpha2: "GW",
    alpha3: "GNB",
    states: []
  },
  {
    name: "Guyana",
    alpha2: "GY",
    alpha3: "GUY",
    states: []
  },
  {
    name: "Haiti",
    alpha2: "HT",
    alpha3: "HTI",
    states: []
  },
  {
    name: "Holy See (Vatican City State)",
    alpha2: "VA",
    alpha3: "VAT",
    states: []
  },
  {
    name: "Honduras",
    alpha2: "HN",
    alpha3: "HND",
    states: []
  },
  {
    name: "Hungary",
    alpha2: "HU",
    alpha3: "HUN",
    states: []
  },
  {
    name: "Iceland",
    alpha2: "IS",
    alpha3: "ISL",
    states: []
  },
  {
    name: "India",
    alpha2: "IN",
    alpha3: "IND",
    states: [
      {
        name: "Andaman and Nicobar Islands",
        stateCode: "AN"
      },
      {
        name: "Andhra Pradesh",
        stateCode: "AP"
      },
      {
        name: "Arunachal Pradesh",
        stateCode: "AR"
      },
      {
        name: "Assam",
        stateCode: "AS"
      },
      {
        name: "Bihar",
        stateCode: "BR"
      },
      {
        name: "Chandigarh",
        stateCode: "CH"
      },
      {
        name: "Chhattisgarh",
        stateCode: "CT"
      },
      {
        name: "Daman and Diu",
        stateCode: "DD"
      },
      {
        name: "Delhi",
        stateCode: "DL"
      },
      {
        name: "Dadra and Nagar Haveli",
        stateCode: "DN"
      },
      {
        name: "Goa",
        stateCode: "GA"
      },
      {
        name: "Gujarat",
        stateCode: "GJ"
      },
      {
        name: "Himachal Pradesh",
        stateCode: "HP"
      },
      {
        name: "Haryana",
        stateCode: "HR"
      },
      {
        name: "Jharkhand",
        stateCode: "JH"
      },
      {
        name: "Jammu and Kashmir",
        stateCode: "JK"
      },
      {
        name: "Karnataka",
        stateCode: "KA"
      },
      {
        name: "Kerala",
        stateCode: "KL"
      },
      {
        name: "Lakshadweep",
        stateCode: "LD"
      },
      {
        name: "Maharashtra",
        stateCode: "MH"
      },
      {
        name: "Meghalaya",
        stateCode: "ML"
      },
      {
        name: "Manipur",
        stateCode: "MN"
      },
      {
        name: "Madhya Pradesh",
        stateCode: "MP"
      },
      {
        name: "Mizoram",
        stateCode: "MZ"
      },
      {
        name: "Nagaland",
        stateCode: "NL"
      },
      {
        name: "Odisha",
        stateCode: "OR"
      },
      {
        name: "Punjab",
        stateCode: "PB"
      },
      {
        name: "Puducherry",
        stateCode: "PY"
      },
      {
        name: "Rajasthan",
        stateCode: "RJ"
      },
      {
        name: "Sikkim",
        stateCode: "SK"
      },
      {
        name: "Tamil Nadu",
        stateCode: "TN"
      },
      {
        name: "Telangana",
        stateCode: "TL"
      },
      {
        name: "Tripura",
        stateCode: "TR"
      },
      {
        name: "Uttar Pradesh",
        stateCode: "UP"
      },
      {
        name: "Uttarakhand",
        stateCode: "UT"
      },
      {
        name: "West Bengal",
        stateCode: "WB"
      }
    ]
  },
  {
    name: "Indonesia",
    alpha2: "ID",
    alpha3: "IDN",
    states: []
  },
  {
    name: "Iran, Islamic Republic of",
    alpha2: "IR",
    alpha3: "IRN",
    states: []
  },
  {
    name: "Iraq",
    alpha2: "IQ",
    alpha3: "IRQ",
    states: []
  },
  {
    name: "Ireland",
    alpha2: "IE",
    alpha3: "IRL",
    states: [
      {
        name: "Clare",
        stateCode: "CE"
      },
      {
        name: "Cavan",
        stateCode: "CN"
      },
      {
        name: "Cork",
        stateCode: "CO"
      },
      {
        name: "Carlow",
        stateCode: "CW"
      },
      {
        name: "Dublin",
        stateCode: "D"
      },
      {
        name: "Donegal",
        stateCode: "DL"
      },
      {
        name: "Galway",
        stateCode: "G"
      },
      {
        name: "Kildare",
        stateCode: "KE"
      },
      {
        name: "Kilkenny",
        stateCode: "KK"
      },
      {
        name: "Kerry",
        stateCode: "KY"
      },
      {
        name: "Longford",
        stateCode: "LD"
      },
      {
        name: "Louth",
        stateCode: "LH"
      },
      {
        name: "Limerick",
        stateCode: "LK"
      },
      {
        name: "Leitrim",
        stateCode: "LM"
      },
      {
        name: "Laois",
        stateCode: "LS"
      },
      {
        name: "Meath",
        stateCode: "MH"
      },
      {
        name: "Monaghan",
        stateCode: "MN"
      },
      {
        name: "Mayo",
        stateCode: "MO"
      },
      {
        name: "Offaly",
        stateCode: "OY"
      },
      {
        name: "Roscommon",
        stateCode: "RN"
      },
      {
        name: "Sligo",
        stateCode: "SO"
      },
      {
        name: "Tipperary",
        stateCode: "TA"
      },
      {
        name: "Waterford",
        stateCode: "WD"
      },
      {
        name: "Westmeath",
        stateCode: "WH"
      },
      {
        name: "Wicklow",
        stateCode: "WW"
      },
      {
        name: "Wexford",
        stateCode: "WX"
      }
    ]
  },
  {
    name: "Isle of Man",
    alpha2: "IM",
    alpha3: "IMN",
    states: []
  },
  {
    name: "Israel",
    alpha2: "IL",
    alpha3: "ISR",
    states: []
  },
  {
    name: "Italy",
    alpha2: "IT",
    alpha3: "ITA",
    states: [
      {
        name: "Agrigento",
        stateCode: "AG"
      },
      {
        name: "Alessandria",
        stateCode: "AL"
      },
      {
        name: "Ancona",
        stateCode: "AN"
      },
      {
        name: "Aosta",
        stateCode: "AO"
      },
      {
        name: "Ascoli Piceno",
        stateCode: "AP"
      },
      {
        name: "L'Aquila",
        stateCode: "AQ"
      },
      {
        name: "Arezzo",
        stateCode: "AR"
      },
      {
        name: "Asti",
        stateCode: "AT"
      },
      {
        name: "Avellino",
        stateCode: "AV"
      },
      {
        name: "Bari",
        stateCode: "BA"
      },
      {
        name: "Bergamo",
        stateCode: "BG"
      },
      {
        name: "Biella",
        stateCode: "BI"
      },
      {
        name: "Belluno",
        stateCode: "BL"
      },
      {
        name: "Benevento",
        stateCode: "BN"
      },
      {
        name: "Bologna",
        stateCode: "BO"
      },
      {
        name: "Brindisi",
        stateCode: "BR"
      },
      {
        name: "Brescia",
        stateCode: "BS"
      },
      {
        name: "Barletta-Andria-Trani",
        stateCode: "BT"
      },
      {
        name: "Bolzano",
        stateCode: "BZ"
      },
      {
        name: "Cagliari",
        stateCode: "CA"
      },
      {
        name: "Campobasso",
        stateCode: "CB"
      },
      {
        name: "Caserta",
        stateCode: "CE"
      },
      {
        name: "Chieti",
        stateCode: "CH"
      },
      {
        name: "Carbonia-Iglesias",
        stateCode: "CI"
      },
      {
        name: "Caltanissetta",
        stateCode: "CL"
      },
      {
        name: "Cuneo",
        stateCode: "CN"
      },
      {
        name: "Como",
        stateCode: "CO"
      },
      {
        name: "Cremona",
        stateCode: "CR"
      },
      {
        name: "Cosenza",
        stateCode: "CS"
      },
      {
        name: "Catania",
        stateCode: "CT"
      },
      {
        name: "Catanzaro",
        stateCode: "CZ"
      },
      {
        name: "Enna",
        stateCode: "EN"
      },
      {
        name: "Forlì-Cesena",
        stateCode: "FC"
      },
      {
        name: "Ferrara",
        stateCode: "FE"
      },
      {
        name: "Foggia",
        stateCode: "FG"
      },
      {
        name: "Florence",
        stateCode: "FI"
      },
      {
        name: "Fermo",
        stateCode: "FM"
      },
      {
        name: "Frosinone",
        stateCode: "FR"
      },
      {
        name: "Genoa",
        stateCode: "GE"
      },
      {
        name: "Gorizia",
        stateCode: "GO"
      },
      {
        name: "Grosseto",
        stateCode: "GR"
      },
      {
        name: "Imperia",
        stateCode: "IM"
      },
      {
        name: "Isernia",
        stateCode: "IS"
      },
      {
        name: "Crotone",
        stateCode: "KR"
      },
      {
        name: "Lecco",
        stateCode: "LC"
      },
      {
        name: "Lecce",
        stateCode: "LE"
      },
      {
        name: "Livorno",
        stateCode: "LI"
      },
      {
        name: "Lodi",
        stateCode: "LO"
      },
      {
        name: "Latina",
        stateCode: "LT"
      },
      {
        name: "Lucca",
        stateCode: "LU"
      },
      {
        name: "Monza and Brianza",
        stateCode: "MB"
      },
      {
        name: "Macerata",
        stateCode: "MC"
      },
      {
        name: "Messina",
        stateCode: "ME"
      },
      {
        name: "Milan",
        stateCode: "MI"
      },
      {
        name: "Mantua",
        stateCode: "MN"
      },
      {
        name: "Modena",
        stateCode: "MO"
      },
      {
        name: "Massa and Carrara",
        stateCode: "MS"
      },
      {
        name: "Matera",
        stateCode: "MT"
      },
      {
        name: "Naples",
        stateCode: "NA"
      },
      {
        name: "Novara",
        stateCode: "NO"
      },
      {
        name: "Nuoro",
        stateCode: "NU"
      },
      {
        name: "Ogliastra",
        stateCode: "OG"
      },
      {
        name: "Oristano",
        stateCode: "OR"
      },
      {
        name: "Olbia-Tempio",
        stateCode: "OT"
      },
      {
        name: "Palermo",
        stateCode: "PA"
      },
      {
        name: "Piacenza",
        stateCode: "PC"
      },
      {
        name: "Padua",
        stateCode: "PD"
      },
      {
        name: "Pescara",
        stateCode: "PE"
      },
      {
        name: "Perugia",
        stateCode: "PG"
      },
      {
        name: "Pisa",
        stateCode: "PI"
      },
      {
        name: "Pordenone",
        stateCode: "PN"
      },
      {
        name: "Prato",
        stateCode: "PO"
      },
      {
        name: "Parma",
        stateCode: "PR"
      },
      {
        name: "Pistoia",
        stateCode: "PT"
      },
      {
        name: "Pesaro and Urbino",
        stateCode: "PU"
      },
      {
        name: "Pavia",
        stateCode: "PV"
      },
      {
        name: "Potenza",
        stateCode: "PZ"
      },
      {
        name: "Ravenna",
        stateCode: "RA"
      },
      {
        name: "Reggio Calabria",
        stateCode: "RC"
      },
      {
        name: "Reggio Emilia",
        stateCode: "RE"
      },
      {
        name: "Ragusa",
        stateCode: "RG"
      },
      {
        name: "Rieti",
        stateCode: "RI"
      },
      {
        name: "Rome",
        stateCode: "RM"
      },
      {
        name: "Rimini",
        stateCode: "RN"
      },
      {
        name: "Rovigo",
        stateCode: "RO"
      },
      {
        name: "Salerno",
        stateCode: "SA"
      },
      {
        name: "Siena",
        stateCode: "SI"
      },
      {
        name: "Sondrio",
        stateCode: "SO"
      },
      {
        name: "La Spezia",
        stateCode: "SP"
      },
      {
        name: "Syracuse",
        stateCode: "SR"
      },
      {
        name: "Sassari",
        stateCode: "SS"
      },
      {
        name: "Savona",
        stateCode: "SV"
      },
      {
        name: "Taranto",
        stateCode: "TA"
      },
      {
        name: "Teramo",
        stateCode: "TE"
      },
      {
        name: "Trento",
        stateCode: "TN"
      },
      {
        name: "Turin",
        stateCode: "TO"
      },
      {
        name: "Trapani",
        stateCode: "TP"
      },
      {
        name: "Terni",
        stateCode: "TR"
      },
      {
        name: "Trieste",
        stateCode: "TS"
      },
      {
        name: "Treviso",
        stateCode: "TV"
      },
      {
        name: "Udine",
        stateCode: "UD"
      },
      {
        name: "Varese",
        stateCode: "VA"
      },
      {
        name: "Verbano-Cusio-Ossola",
        stateCode: "VB"
      },
      {
        name: "Vercelli",
        stateCode: "VC"
      },
      {
        name: "Venice",
        stateCode: "VE"
      },
      {
        name: "Vicenza",
        stateCode: "VI"
      },
      {
        name: "Verona",
        stateCode: "VR"
      },
      {
        name: "Medio Campidano",
        stateCode: "VS"
      },
      {
        name: "Viterbo",
        stateCode: "VT"
      },
      {
        name: "Vibo Valentia",
        stateCode: "VV"
      }
    ]
  },
  {
    name: "Jamaica",
    alpha2: "JM",
    alpha3: "JAM",
    states: []
  },
  {
    name: "Japan",
    alpha2: "JP",
    alpha3: "JPN",
    states: []
  },
  {
    name: "Jersey",
    alpha2: "JE",
    alpha3: "JEY",
    states: []
  },
  {
    name: "Jordan",
    alpha2: "JO",
    alpha3: "JOR",
    states: []
  },
  {
    name: "Kazakhstan",
    alpha2: "KZ",
    alpha3: "KAZ",
    states: []
  },
  {
    name: "Kenya",
    alpha2: "KE",
    alpha3: "KEN",
    states: []
  },
  {
    name: "Kiribati",
    alpha2: "KI",
    alpha3: "KIR",
    states: []
  },
  {
    name: "Korea, Democratic People's Republic of",
    alpha2: "KP",
    alpha3: "PRK",
    states: []
  },
  {
    name: "Korea, Republic of",
    alpha2: "KR",
    alpha3: "KOR",
    states: []
  },
  {
    name: "Kuwait",
    alpha2: "KW",
    alpha3: "KWT",
    states: []
  },
  {
    name: "Kyrgyzstan",
    alpha2: "KG",
    alpha3: "KGZ",
    states: []
  },
  {
    name: "Lao People's Democratic Republic",
    alpha2: "LA",
    alpha3: "LAO",
    states: []
  },
  {
    name: "Latvia",
    alpha2: "LV",
    alpha3: "LVA",
    states: []
  },
  {
    name: "Lebanon",
    alpha2: "LB",
    alpha3: "LBN",
    states: []
  },
  {
    name: "Lesotho",
    alpha2: "LS",
    alpha3: "LSO",
    states: []
  },
  {
    name: "Liberia",
    alpha2: "LR",
    alpha3: "LBR",
    states: []
  },
  {
    name: "Libyan Arab Jamahiriya",
    alpha2: "LY",
    alpha3: "LBY",
    states: []
  },
  {
    name: "Liechtenstein",
    alpha2: "LI",
    alpha3: "LIE",
    states: []
  },
  {
    name: "Lithuania",
    alpha2: "LT",
    alpha3: "LTU",
    states: []
  },
  {
    name: "Luxembourg",
    alpha2: "LU",
    alpha3: "LUX",
    states: []
  },
  {
    name: "Macao",
    alpha2: "MO",
    alpha3: "MAC",
    states: []
  },
  {
    name: "Madagascar",
    alpha2: "MG",
    alpha3: "MDG",
    states: []
  },
  {
    name: "Malawi",
    alpha2: "MW",
    alpha3: "MWI",
    states: []
  },
  {
    name: "Malaysia",
    alpha2: "MY",
    alpha3: "MYS",
    states: []
  },
  {
    name: "Maldives",
    alpha2: "MV",
    alpha3: "MDV",
    states: []
  },
  {
    name: "Mali",
    alpha2: "ML",
    alpha3: "MLI",
    states: []
  },
  {
    name: "Malta",
    alpha2: "MT",
    alpha3: "MLT",
    states: []
  },
  {
    name: "Martinique",
    alpha2: "MQ",
    alpha3: "MTQ",
    states: []
  },
  {
    name: "Mauritania",
    alpha2: "MR",
    alpha3: "MRT",
    states: []
  },
  {
    name: "Mauritius",
    alpha2: "MU",
    alpha3: "MUS",
    states: []
  },
  {
    name: "Mayotte",
    alpha2: "YT",
    alpha3: "MYT",
    states: []
  },
  {
    name: "Mexico",
    alpha2: "MX",
    alpha3: "MEX",
    states: [
      {
        name: "Aguascalientes",
        stateCode: "AG"
      },
      {
        name: "Baja California",
        stateCode: "BC"
      },
      {
        name: "Baja California Sur",
        stateCode: "BS"
      },
      {
        name: "Chihuahua",
        stateCode: "CH"
      },
      {
        name: "Colima",
        stateCode: "CL"
      },
      {
        name: "Campeche",
        stateCode: "CM"
      },
      {
        name: "Coahuila",
        stateCode: "CO"
      },
      {
        name: "Chiapas",
        stateCode: "CS"
      },
      {
        name: "Federal District",
        stateCode: "DF"
      },
      {
        name: "Durango",
        stateCode: "DG"
      },
      {
        name: "Guerrero",
        stateCode: "GR"
      },
      {
        name: "Guanajuato",
        stateCode: "GT"
      },
      {
        name: "Hidalgo",
        stateCode: "HG"
      },
      {
        name: "Jalisco",
        stateCode: "JA"
      },
      {
        name: "Mexico State",
        stateCode: "ME"
      },
      {
        name: "Michoacán",
        stateCode: "MI"
      },
      {
        name: "Morelos",
        stateCode: "MO"
      },
      {
        name: "Nayarit",
        stateCode: "NA"
      },
      {
        name: "Nuevo León",
        stateCode: "NL"
      },
      {
        name: "Oaxaca",
        stateCode: "OA"
      },
      {
        name: "Puebla",
        stateCode: "PB"
      },
      {
        name: "Querétaro",
        stateCode: "QE"
      },
      {
        name: "Quintana Roo",
        stateCode: "QR"
      },
      {
        name: "Sinaloa",
        stateCode: "SI"
      },
      {
        name: "San Luis Potosí",
        stateCode: "SL"
      },
      {
        name: "Sonora",
        stateCode: "SO"
      },
      {
        name: "Tabasco",
        stateCode: "TB"
      },
      {
        name: "Tlaxcala",
        stateCode: "TL"
      },
      {
        name: "Tamaulipas",
        stateCode: "TM"
      },
      {
        name: "Veracruz",
        stateCode: "VE"
      },
      {
        name: "Yucatán",
        stateCode: "YU"
      },
      {
        name: "Zacatecas",
        stateCode: "ZA"
      }
    ]
  },
  {
    name: "Moldova, Republic of",
    alpha2: "MD",
    alpha3: "MDA",
    states: []
  },
  {
    name: "Monaco",
    alpha2: "MC",
    alpha3: "MCO",
    states: []
  },
  {
    name: "Mongolia",
    alpha2: "MN",
    alpha3: "MNG",
    states: []
  },
  {
    name: "Montenegro",
    alpha2: "ME",
    alpha3: "MNE",
    states: []
  },
  {
    name: "Montserrat",
    alpha2: "MS",
    alpha3: "MSR",
    states: []
  },
  {
    name: "Morocco",
    alpha2: "MA",
    alpha3: "MAR",
    states: []
  },
  {
    name: "Mozambique",
    alpha2: "MZ",
    alpha3: "MOZ",
    states: []
  },
  {
    name: "Myanmar",
    alpha2: "MM",
    alpha3: "MMR",
    states: []
  },
  {
    name: "Namibia",
    alpha2: "NA",
    alpha3: "NAM",
    states: []
  },
  {
    name: "Nauru",
    alpha2: "NR",
    alpha3: "NRU",
    states: []
  },
  {
    name: "Nepal",
    alpha2: "NP",
    alpha3: "NPL",
    states: []
  },
  {
    name: "Netherlands",
    alpha2: "NL",
    alpha3: "NLD",
    states: []
  },
  {
    name: "New Caledonia",
    alpha2: "NC",
    alpha3: "NCL",
    states: []
  },
  {
    name: "New Zealand",
    alpha2: "NZ",
    alpha3: "NZL",
    states: []
  },
  {
    name: "Nicaragua",
    alpha2: "NI",
    alpha3: "NIC",
    states: []
  },
  {
    name: "Niger",
    alpha2: "NE",
    alpha3: "NER",
    states: []
  },
  {
    name: "Nigeria",
    alpha2: "NG",
    alpha3: "NGA",
    states: []
  },
  {
    name: "Niue",
    alpha2: "NU",
    alpha3: "NIU",
    states: []
  },
  {
    name: "Norfolk Island",
    alpha2: "NF",
    alpha3: "NFK",
    states: []
  },
  {
    name: "Norway",
    alpha2: "NO",
    alpha3: "NOR",
    states: []
  },
  {
    name: "Oman",
    alpha2: "OM",
    alpha3: "OMN",
    states: []
  },
  {
    name: "Pakistan",
    alpha2: "PK",
    alpha3: "PAK",
    states: []
  },
  {
    name: "Palestinian Territory, Occupied",
    alpha2: "PS",
    alpha3: "PSE",
    states: []
  },
  {
    name: "Panama",
    alpha2: "PA",
    alpha3: "PAN",
    states: []
  },
  {
    name: "Papua New Guinea",
    alpha2: "PG",
    alpha3: "PNG",
    states: []
  },
  {
    name: "Paraguay",
    alpha2: "PY",
    alpha3: "PRY",
    states: []
  },
  {
    name: "Peru",
    alpha2: "PE",
    alpha3: "PER",
    states: []
  },
  {
    name: "Philippines",
    alpha2: "PH",
    alpha3: "PHL",
    states: []
  },
  {
    name: "Pitcairn",
    alpha2: "PN",
    alpha3: "PCN",
    states: []
  },
  {
    name: "Poland",
    alpha2: "PL",
    alpha3: "POL",
    states: []
  },
  {
    name: "Portugal",
    alpha2: "PT",
    alpha3: "PRT",
    states: []
  },
  {
    name: "Qatar",
    alpha2: "QA",
    alpha3: "QAT",
    states: []
  },
  {
    name: "Republic of North Macedonia",
    alpha2: "MK",
    alpha3: "MKD",
    states: []
  },
  {
    name: "Reunion",
    alpha2: "RE",
    alpha3: "REU",
    states: []
  },
  {
    name: "Romania",
    alpha2: "RO",
    alpha3: "ROU",
    states: []
  },
  {
    name: "Russian Federation",
    alpha2: "RU",
    alpha3: "RUS",
    states: []
  },
  {
    name: "Rwanda",
    alpha2: "RW",
    alpha3: "RWA",
    states: []
  },
  {
    name: "Saint Barthélemy",
    alpha2: "BL",
    alpha3: "BLM",
    states: []
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    alpha2: "SH",
    alpha3: "SHN",
    states: []
  },
  {
    name: "Saint Kitts and Nevis",
    alpha2: "KN",
    alpha3: "KNA",
    states: []
  },
  {
    name: "Saint Lucia",
    alpha2: "LC",
    alpha3: "LCA",
    states: []
  },
  {
    name: "Saint Martin (French part)",
    alpha2: "MF",
    alpha3: "MAF",
    states: []
  },
  {
    name: "Saint Pierre and Miquelon",
    alpha2: "PM",
    alpha3: "SPM",
    states: []
  },
  {
    name: "Saint Vincent and the Grenadines",
    alpha2: "VC",
    alpha3: "VCT",
    states: []
  },
  {
    name: "Samoa",
    alpha2: "WS",
    alpha3: "WSM",
    states: []
  },
  {
    name: "San Marino",
    alpha2: "SM",
    alpha3: "SMR",
    states: []
  },
  {
    name: "Sao Tome and Principe",
    alpha2: "ST",
    alpha3: "STP",
    states: []
  },
  {
    name: "Saudi Arabia",
    alpha2: "SA",
    alpha3: "SAU",
    states: []
  },
  {
    name: "Senegal",
    alpha2: "SN",
    alpha3: "SEN",
    states: []
  },
  {
    name: "Serbia",
    alpha2: "RS",
    alpha3: "SRB",
    states: []
  },
  {
    name: "Seychelles",
    alpha2: "SC",
    alpha3: "SYC",
    states: []
  },
  {
    name: "Sierra Leone",
    alpha2: "SL",
    alpha3: "SLE",
    states: []
  },
  {
    name: "Singapore",
    alpha2: "SG",
    alpha3: "SGP",
    states: []
  },
  {
    name: "Sint Maarten (Dutch part)",
    alpha2: "SX",
    alpha3: "SXM",
    states: []
  },
  {
    name: "Slovakia",
    alpha2: "SK",
    alpha3: "SVK",
    states: []
  },
  {
    name: "Slovenia",
    alpha2: "SI",
    alpha3: "SVN",
    states: []
  },
  {
    name: "Solomon Islands",
    alpha2: "SB",
    alpha3: "SLB",
    states: []
  },
  {
    name: "Somalia",
    alpha2: "SO",
    alpha3: "SOM",
    states: []
  },
  {
    name: "South Africa",
    alpha2: "ZA",
    alpha3: "ZAF",
    states: []
  },
  {
    name: "Spain",
    alpha2: "ES",
    alpha3: "ESP",
    states: [
      {
        name: "Asturias",
        stateCode: "AS"
      },
      {
        name: "Cantabria",
        stateCode: "CB"
      },
      {
        name: "La Rioja",
        stateCode: "RI"
      },
      {
        name: "Madrid",
        stateCode: "MD"
      },
      {
        name: "Murcia",
        stateCode: "MC"
      },
      {
        name: "Navarra",
        stateCode: "NC"
      },
      {
        name: "Valencia",
        stateCode: "VC"
      },
      {
        name: "Ceuta",
        stateCode: "CE"
      },
      {
        name: "Melilla",
        stateCode: "ME"
      },
      {
        name: "Albacete",
        stateCode: "AB"
      },
      {
        name: "Alicante/Alacant",
        stateCode: "ALC"
      },
      {
        name: "Almería",
        stateCode: "AL"
      },
      {
        name: "Araba/Álava",
        stateCode: "VI"
      },
      {
        name: "Ávila",
        stateCode: "AV"
      },
      {
        name: "Badajoz",
        stateCode: "BA"
      },
      {
        name: "Barcelona",
        stateCode: "B"
      },
      {
        name: "Bizkaia",
        stateCode: "BI"
      },
      {
        name: "Burgos",
        stateCode: "BU"
      },
      {
        name: "Cáceres",
        stateCode: "CC"
      },
      {
        name: "Cádiz",
        stateCode: "CA"
      },
      {
        name: "Castellón/Castelló",
        stateCode: "CS"
      },
      {
        name: "Ciudad Real",
        stateCode: "CR"
      },
      {
        name: "Córdoba",
        stateCode: "CO"
      },
      {
        name: "A Coruña",
        stateCode: "C"
      },
      {
        name: "Cuenca",
        stateCode: "CU"
      },
      {
        name: "Gipuzkoa",
        stateCode: "GI"
      },
      {
        name: "Girona",
        stateCode: "GE"
      },
      {
        name: "Granada",
        stateCode: "GR"
      },
      {
        name: "Guadalajara",
        stateCode: "GU"
      },
      {
        name: "Huelva",
        stateCode: "H"
      },
      {
        name: "Huesca",
        stateCode: "HU"
      },
      {
        name: "Jaén",
        stateCode: "J"
      },
      {
        name: "León",
        stateCode: "LE"
      },
      {
        name: "Lleida",
        stateCode: "LL"
      },
      {
        name: "Lugo",
        stateCode: "LU"
      },
      {
        name: "Málaga",
        stateCode: "MA"
      },
      {
        name: "Ourense",
        stateCode: "OU"
      },
      {
        name: "Palencia",
        stateCode: "PA"
      },
      {
        name: "Las Palmas",
        stateCode: "LP"
      },
      {
        name: "Pontevedra",
        stateCode: "PO"
      },
      {
        name: "Salamanca",
        stateCode: "SA"
      },
      {
        name: "Santa Cruz de Tenerife",
        stateCode: "SC"
      },
      {
        name: "Segovia",
        stateCode: "SG"
      },
      {
        name: "Sevilla",
        stateCode: "SE"
      },
      {
        name: "Soria",
        stateCode: "SO"
      },
      {
        name: "Tarragona",
        stateCode: "TA"
      },
      {
        name: "Teruel",
        stateCode: "TE"
      },
      {
        name: "Toledo",
        stateCode: "TO"
      },
      {
        name: "Valladolid",
        stateCode: "VA"
      },
      {
        name: "Zamora",
        stateCode: "ZA"
      },
      {
        name: "Zaragoza",
        stateCode: "Z"
      }
    ]
  },
  {
    name: "Sri Lanka",
    alpha2: "LK",
    alpha3: "LKA",
    states: []
  },
  {
    name: "Sudan",
    alpha2: "SD",
    alpha3: "SDN",
    states: []
  },
  {
    name: "Suriname",
    alpha2: "SR",
    alpha3: "SUR",
    states: []
  },
  {
    name: "Swaziland",
    alpha2: "SZ",
    alpha3: "SWZ",
    states: []
  },
  {
    name: "Sweden",
    alpha2: "SE",
    alpha3: "SWE",
    states: []
  },
  {
    name: "Switzerland",
    alpha2: "CH",
    alpha3: "CHE",
    states: []
  },
  {
    name: "Syrian Arab Republic",
    alpha2: "SY",
    alpha3: "SYR",
    states: []
  },
  {
    name: "Taiwan (Republic of China)",
    alpha2: "TW",
    alpha3: "TWN",
    states: []
  },
  {
    name: "Tajikistan",
    alpha2: "TJ",
    alpha3: "TJK",
    states: []
  },
  {
    name: "Tanzania, United Republic of",
    alpha2: "TZ",
    alpha3: "TZA",
    states: []
  },
  {
    name: "Thailand",
    alpha2: "TH",
    alpha3: "THA",
    states: []
  },
  {
    name: "Timor-Leste",
    alpha2: "TL",
    alpha3: "TLS",
    states: []
  },
  {
    name: "Togo",
    alpha2: "TG",
    alpha3: "TGO",
    states: []
  },
  {
    name: "Tokelau",
    alpha2: "TK",
    alpha3: "TKL",
    states: []
  },
  {
    name: "Tonga",
    alpha2: "TO",
    alpha3: "TON",
    states: []
  },
  {
    name: "Trinidad and Tobago",
    alpha2: "TT",
    alpha3: "TTO",
    states: []
  },
  {
    name: "Tunisia",
    alpha2: "TN",
    alpha3: "TUN",
    states: []
  },
  {
    name: "Turkey",
    alpha2: "TR",
    alpha3: "TUR",
    states: []
  },
  {
    name: "Turkmenistan",
    alpha2: "TM",
    alpha3: "TKM",
    states: []
  },
  {
    name: "Turks and Caicos Islands",
    alpha2: "TC",
    alpha3: "TCA",
    states: []
  },
  {
    name: "Tuvalu",
    alpha2: "TV",
    alpha3: "TUV",
    states: []
  },
  {
    name: "Uganda",
    alpha2: "UG",
    alpha3: "UGA",
    states: []
  },
  {
    name: "Ukraine",
    alpha2: "UA",
    alpha3: "UKR",
    states: []
  },
  {
    name: "United Arab Emirates",
    alpha2: "AE",
    alpha3: "ARE",
    states: [
      {
        name: "Abu Dhabi",
        stateCode: "ABUDHABI",
        integrationValue: "Abu Dhabi"
      },
      {
        name: "Ajman",
        stateCode: "AJMAN",
        integrationValue: "Ajman"
      },
      {
        name: "Dubai",
        stateCode: "DUBAI",
        integrationValue: "Dubai"
      },
      {
        name: "Ras Al Khaimah",
        stateCode: "RASALKHAIM",
        integrationValue: "Ras Al Khaimah"
      },
      {
        name: "Sharjah",
        stateCode: "SHARJAH",
        integrationValue: "Sharjah"
      },
      {
        name: "Umm Al Quwain",
        stateCode: "UMMAIQUWAI",
        integrationValue: "Umm Al Quwain"
      },
      {
        name: "Fujairah",
        stateCode: "FUJAIRAH",
        integrationValue: "Fujairah"
      }
    ]
  },
  {
    name: "United Kingdom",
    alpha2: "GB",
    alpha3: "GBR",
    states: [
      {
        name: "London",
        stateCode: "LONDON"
      }
    ]
  },
  {
    name: "United States of America",
    alpha2: "US",
    alpha3: "USA",
    states: [
      {
        name: "Armed Forces Americas",
        stateCode: "AA"
      },
      {
        name: "Armed Forces Europe",
        stateCode: "AE"
      },
      {
        name: "Alaska",
        stateCode: "AK"
      },
      {
        name: "Alabama",
        stateCode: "AL"
      },
      {
        name: "Armed Forces Pacific",
        stateCode: "AP"
      },
      {
        name: "Arkansas",
        stateCode: "AR"
      },
      {
        name: "American Samoa",
        stateCode: "AS"
      },
      {
        name: "Arizona",
        stateCode: "AZ"
      },
      {
        name: "California",
        stateCode: "CA"
      },
      {
        name: "Colorado",
        stateCode: "CO"
      },
      {
        name: "Connecticut",
        stateCode: "CT"
      },
      {
        name: "District of Columbia",
        stateCode: "DC"
      },
      {
        name: "Delaware",
        stateCode: "DE"
      },
      {
        name: "Florida",
        stateCode: "FL"
      },
      {
        name: "Federated Micronesia",
        stateCode: "FM"
      },
      {
        name: "Georgia",
        stateCode: "GA"
      },
      {
        name: "Guam",
        stateCode: "GU"
      },
      {
        name: "Hawaii",
        stateCode: "HI"
      },
      {
        name: "Iowa",
        stateCode: "IA"
      },
      {
        name: "Idaho",
        stateCode: "ID"
      },
      {
        name: "Illinois",
        stateCode: "IL"
      },
      {
        name: "Indiana",
        stateCode: "IN"
      },
      {
        name: "Kansas",
        stateCode: "KS"
      },
      {
        name: "Kentucky",
        stateCode: "KY"
      },
      {
        name: "Louisiana",
        stateCode: "LA"
      },
      {
        name: "Massachusetts",
        stateCode: "MA"
      },
      {
        name: "Maryland",
        stateCode: "MD"
      },
      {
        name: "Maine",
        stateCode: "ME"
      },
      {
        name: "Marshall Islands",
        stateCode: "MH"
      },
      {
        name: "Michigan",
        stateCode: "MI"
      },
      {
        name: "Minnesota",
        stateCode: "MN"
      },
      {
        name: "Missouri",
        stateCode: "MO"
      },
      {
        name: "Northern Mariana Islands",
        stateCode: "MP"
      },
      {
        name: "Mississippi",
        stateCode: "MS"
      },
      {
        name: "Montana",
        stateCode: "MT"
      },
      {
        name: "North Carolina",
        stateCode: "NC"
      },
      {
        name: "North Dakota",
        stateCode: "ND"
      },
      {
        name: "Nebraska",
        stateCode: "NE"
      },
      {
        name: "New Hampshire",
        stateCode: "NH"
      },
      {
        name: "New Jersey",
        stateCode: "NJ"
      },
      {
        name: "New Mexico",
        stateCode: "NM"
      },
      {
        name: "Nevada",
        stateCode: "NV"
      },
      {
        name: "New York",
        stateCode: "NY"
      },
      {
        name: "Ohio",
        stateCode: "OH"
      },
      {
        name: "Oklahoma",
        stateCode: "OK"
      },
      {
        name: "Oregon",
        stateCode: "OR"
      },
      {
        name: "Pennsylvania",
        stateCode: "PA"
      },
      {
        name: "Puerto Rico",
        stateCode: "PR"
      },
      {
        name: "Palau",
        stateCode: "PW"
      },
      {
        name: "Rhode Island",
        stateCode: "RI"
      },
      {
        name: "South Carolina",
        stateCode: "SC"
      },
      {
        name: "South Dakota",
        stateCode: "SD"
      },
      {
        name: "Tennessee",
        stateCode: "TN"
      },
      {
        name: "Texas",
        stateCode: "TX"
      },
      {
        name: "United States Minor Outlying Islands",
        stateCode: "UM"
      },
      {
        name: "Utah",
        stateCode: "UT"
      },
      {
        name: "Virginia",
        stateCode: "VA"
      },
      {
        name: "US Virgin Islands",
        stateCode: "VI"
      },
      {
        name: "Vermont",
        stateCode: "VT"
      },
      {
        name: "Washington",
        stateCode: "WA"
      },
      {
        name: "Wisconsin",
        stateCode: "WI"
      },
      {
        name: "West Virginia",
        stateCode: "WV"
      },
      {
        name: "Wyoming",
        stateCode: "WY"
      }
    ]
  },
  {
    name: "Uruguay",
    alpha2: "UY",
    alpha3: "URY",
    states: []
  },
  {
    name: "Uzbekistan",
    alpha2: "UZ",
    alpha3: "UZB",
    states: []
  },
  {
    name: "Vanuatu",
    alpha2: "VU",
    alpha3: "VUT",
    states: []
  },
  {
    name: "Venezuela, Bolivarian Republic of",
    alpha2: "VE",
    alpha3: "VEN",
    states: []
  },
  {
    name: "Viet Nam",
    alpha2: "VN",
    alpha3: "VNM",
    states: []
  },
  {
    name: "Virgin Islands, British",
    alpha2: "VG",
    alpha3: "VGB",
    states: []
  },
  {
    name: "Wallis and Futuna",
    alpha2: "WF",
    alpha3: "WLF",
    states: []
  },
  {
    name: "Yemen",
    alpha2: "YE",
    alpha3: "YEM",
    states: []
  },
  {
    name: "Zambia",
    alpha2: "ZM",
    alpha3: "ZMB",
    states: []
  },
  {
    name: "Zimbabwe",
    alpha2: "ZW",
    alpha3: "ZWE",
    states: []
  }
], e = [
  {
    label: "Agriculture",
    value: "Agriculture"
  },
  {
    label: "Apparel",
    value: "Apparel"
  },
  {
    label: "Banking",
    value: "Banking"
  },
  {
    label: "Chemicals",
    value: "Chemicals"
  },
  {
    label: "Construction",
    value: "Construction"
  },
  {
    label: "Consulting",
    value: "Consulting"
  },
  {
    label: "Education",
    value: "Education"
  },
  {
    label: "Electronics",
    value: "Electronics"
  },
  {
    label: "Energy",
    value: "Energy"
  },
  {
    label: "Engineering",
    value: "Engineering"
  },
  {
    label: "Finance",
    value: "Finance"
  },
  {
    label: "Government",
    value: "Government"
  },
  {
    label: "Healthcare",
    value: "Healthcare"
  },
  {
    label: "Hospitality",
    value: "Hospitality"
  },
  {
    label: "Insurance",
    value: "Insurance"
  },
  {
    label: "Machinery",
    value: "Machinery"
  },
  {
    label: "Media",
    value: "Media"
  },
  {
    label: "Not For Profit",
    value: "Not For Profit"
  },
  {
    label: "Other",
    value: "Other"
  },
  {
    label: "Recreation",
    value: "Recreation"
  },
  {
    label: "Retail",
    value: "Retail"
  },
  {
    label: "Shipping",
    value: "Shipping"
  },
  {
    label: "Technology",
    value: "Technology"
  },
  {
    label: "Utilities",
    value: "Utilities"
  },
  {
    label: "Real Estate",
    value: "Real Estate"
  },
  {
    label: "Food",
    value: "Food"
  },
  {
    label: "Biotechnology",
    value: "Biotechnology"
  },
  {
    label: "Communications",
    value: "Communications"
  },
  {
    label: "Entertainment",
    value: "Entertainment"
  },
  {
    label: "Environmental",
    value: "Environmental"
  },
  {
    label: "Manufacturing",
    value: "Manufacturing"
  },
  {
    label: "Telecommunications",
    value: "Telecommunications"
  },
  {
    label: "Transportation",
    value: "Transportation"
  },
  {
    label: "Hospitality & Tourism",
    value: "Hospitality & Tourism"
  },
  {
    label: "Banking & Financial Services",
    value: "Banking & Financial Services"
  },
  {
    label: "Real Estate & Property",
    value: "Real Estate & Property"
  },
  {
    label: "Sales",
    value: "Sales"
  },
  {
    label: "Accounting",
    value: "Accounting"
  },
  {
    label: "Transport",
    value: "Transport"
  },
  {
    label: "Retail & Consumer Products",
    value: "Retail & Consumer Products"
  },
  {
    label: "Information & Communication Technology",
    value: "Information & Communication Technology"
  },
  {
    label: "Healthcare & Medical",
    value: "Healthcare & Medical"
  },
  {
    label: "Manufacturing, Transport & Logistics",
    value: "Manufacturing, Transport & Logistics"
  },
  {
    label: "Insurance & Superannuation",
    value: "Insurance & Superannuation"
  },
  {
    label: "Advertising, Arts & Media",
    value: "Advertising, Arts & Media"
  },
  {
    label: "Farming, Animals & Conservation",
    value: "Farming, Animals & Conservation"
  },
  {
    label: "Trades & Services",
    value: "Trades & Services"
  },
  {
    label: "Education & Training",
    value: "Education & Training"
  },
  {
    label: "Community Services & Development",
    value: "Community Services & Development"
  },
  {
    label: "Mining, Resources & Energy",
    value: "Mining, Resources & Energy"
  },
  {
    label: "Call Centre & Customer Service",
    value: "Call Centre & Customer Service"
  },
  {
    label: "Marketing & Communications",
    value: "Marketing & Communications"
  },
  {
    label: "Human Resources & Recruitment",
    value: "Human Resources & Recruitment"
  },
  {
    label: "Administration & Office Support",
    value: "Administration & Office Support"
  }
], t = [
  {
    label: "Afghanistan",
    value: "Afghanistan"
  },
  {
    label: "Åland Islands",
    value: "Åland Islands"
  },
  {
    label: "Albania",
    value: "Albania"
  },
  {
    label: "Algeria",
    value: "Algeria"
  },
  {
    label: "American Samoa",
    value: "American Samoa"
  },
  {
    label: "Andorra",
    value: "Andorra"
  },
  {
    label: "Angola",
    value: "Angola"
  },
  {
    label: "Anguilla",
    value: "Anguilla"
  },
  {
    label: "Antarctica",
    value: "Antarctica"
  },
  {
    label: "Antigua and Barbuda",
    value: "Antigua and Barbuda"
  },
  {
    label: "Argentina",
    value: "Argentina"
  },
  {
    label: "Armenia",
    value: "Armenia"
  },
  {
    label: "Aruba",
    value: "Aruba"
  },
  {
    label: "Australia",
    value: "Australia"
  },
  {
    label: "Austria",
    value: "Austria"
  },
  {
    label: "Azerbaijan",
    value: "Azerbaijan"
  },
  {
    label: "Bahamas",
    value: "Bahamas"
  },
  {
    label: "Bahrain",
    value: "Bahrain"
  },
  {
    label: "Bangladesh",
    value: "Bangladesh"
  },
  {
    label: "Barbados",
    value: "Barbados"
  },
  {
    label: "Belarus",
    value: "Belarus"
  },
  {
    label: "Belgium",
    value: "Belgium"
  },
  {
    label: "Belize",
    value: "Belize"
  },
  {
    label: "Benin",
    value: "Benin"
  },
  {
    label: "Bermuda",
    value: "Bermuda"
  },
  {
    label: "Bhutan",
    value: "Bhutan"
  },
  {
    label: "Bolivia",
    value: "Bolivia"
  },
  {
    label: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina"
  },
  {
    label: "Botswana",
    value: "Botswana"
  },
  {
    label: "Bouvet Island",
    value: "Bouvet Island"
  },
  {
    label: "Brazil",
    value: "Brazil"
  },
  {
    label: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory"
  },
  {
    label: "British Virgin Islands",
    value: "British Virgin Islands"
  },
  {
    label: "Brunei",
    value: "Brunei"
  },
  {
    label: "Bulgaria",
    value: "Bulgaria"
  },
  {
    label: "Burkina Faso",
    value: "Burkina Faso"
  },
  {
    label: "Burundi",
    value: "Burundi"
  },
  {
    label: "Cambodia",
    value: "Cambodia"
  },
  {
    label: "Cameroon",
    value: "Cameroon"
  },
  {
    label: "Canada",
    value: "Canada"
  },
  {
    label: "Cape Verde",
    value: "Cape Verde"
  },
  {
    label: "Caribbean Netherlands",
    value: "Caribbean Netherlands"
  },
  {
    label: "Cayman Islands",
    value: "Cayman Islands"
  },
  {
    label: "Central African Republic",
    value: "Central African Republic"
  },
  {
    label: "Chad",
    value: "Chad"
  },
  {
    label: "Chile",
    value: "Chile"
  },
  {
    label: "China",
    value: "China"
  },
  {
    label: "Christmas Island",
    value: "Christmas Island"
  },
  {
    label: "Cocos (Keeling) Islands",
    value: "Cocos (Keeling) Islands"
  },
  {
    label: "Colombia",
    value: "Colombia"
  },
  {
    label: "Comoros",
    value: "Comoros"
  },
  {
    label: "Cook Islands",
    value: "Cook Islands"
  },
  {
    label: "Costa Rica",
    value: "Costa Rica"
  },
  {
    label: "Cote d'Ivoire",
    value: "Cote d'Ivoire"
  },
  {
    label: "Croatia",
    value: "Croatia"
  },
  {
    label: "Cuba",
    value: "Cuba"
  },
  {
    label: "Curaçao",
    value: "Curaçao"
  },
  {
    label: "Cyprus",
    value: "Cyprus"
  },
  {
    label: "Czechia",
    value: "Czechia"
  },
  {
    label: "Democratic Republic of the Congo",
    value: "Democratic Republic of the Congo"
  },
  {
    label: "Denmark",
    value: "Denmark"
  },
  {
    label: "Djibouti",
    value: "Djibouti"
  },
  {
    label: "Dominica",
    value: "Dominica"
  },
  {
    label: "Dominican Republic",
    value: "Dominican Republic"
  },
  {
    label: "Ecuador",
    value: "Ecuador"
  },
  {
    label: "Egypt",
    value: "Egypt"
  },
  {
    label: "El Salvador",
    value: "El Salvador"
  },
  {
    label: "Equatorial Guinea",
    value: "Equatorial Guinea"
  },
  {
    label: "Eritrea",
    value: "Eritrea"
  },
  {
    label: "Estonia",
    value: "Estonia"
  },
  {
    label: "Ethiopia",
    value: "Ethiopia"
  },
  {
    label: "Falkland Islands",
    value: "Falkland Islands"
  },
  {
    label: "Faroe Islands",
    value: "Faroe Islands"
  },
  {
    label: "Fiji",
    value: "Fiji"
  },
  {
    label: "Finland",
    value: "Finland"
  },
  {
    label: "France",
    value: "France"
  },
  {
    label: "French Guiana",
    value: "French Guiana"
  },
  {
    label: "French Polynesia",
    value: "French Polynesia"
  },
  {
    label: "French Southern and Antarctic Lands",
    value: "French Southern and Antarctic Lands"
  },
  {
    label: "Gabon",
    value: "Gabon"
  },
  {
    label: "Gambia",
    value: "Gambia"
  },
  {
    label: "Georgia",
    value: "Georgia"
  },
  {
    label: "Germany",
    value: "Germany"
  },
  {
    label: "Ghana",
    value: "Ghana"
  },
  {
    label: "Gibraltar",
    value: "Gibraltar"
  },
  {
    label: "Greece",
    value: "Greece"
  },
  {
    label: "Greenland",
    value: "Greenland"
  },
  {
    label: "Grenada",
    value: "Grenada"
  },
  {
    label: "Guadeloupe",
    value: "Guadeloupe"
  },
  {
    label: "Guam",
    value: "Guam"
  },
  {
    label: "Guatemala",
    value: "Guatemala"
  },
  {
    label: "Guernsey",
    value: "Guernsey"
  },
  {
    label: "Guinea",
    value: "Guinea"
  },
  {
    label: "Guinea-Bissau",
    value: "Guinea-Bissau"
  },
  {
    label: "Guyana",
    value: "Guyana"
  },
  {
    label: "Haiti",
    value: "Haiti"
  },
  {
    label: "Heard Island and McDonald Islands",
    value: "Heard Island and McDonald Islands"
  },
  {
    label: "Honduras",
    value: "Honduras"
  },
  {
    label: "Hong Kong",
    value: "Hong Kong"
  },
  {
    label: "Hungary",
    value: "Hungary"
  },
  {
    label: "Iceland",
    value: "Iceland"
  },
  {
    label: "India",
    value: "India"
  },
  {
    label: "Indonesia",
    value: "Indonesia"
  },
  {
    label: "Iran",
    value: "Iran"
  },
  {
    label: "Iraq",
    value: "Iraq"
  },
  {
    label: "Ireland",
    value: "Ireland"
  },
  {
    label: "Isle of Man",
    value: "Isle of Man"
  },
  {
    label: "Israel",
    value: "Israel"
  },
  {
    label: "Italy",
    value: "Italy"
  },
  {
    label: "Jamaica",
    value: "Jamaica"
  },
  {
    label: "Japan",
    value: "Japan"
  },
  {
    label: "Jersey",
    value: "Jersey"
  },
  {
    label: "Jordan",
    value: "Jordan"
  },
  {
    label: "Kazakhstan",
    value: "Kazakhstan"
  },
  {
    label: "Kenya",
    value: "Kenya"
  },
  {
    label: "Kiribati",
    value: "Kiribati"
  },
  {
    label: "Kuwait",
    value: "Kuwait"
  },
  {
    label: "Kyrgyzstan",
    value: "Kyrgyzstan"
  },
  {
    label: "Laos",
    value: "Laos"
  },
  {
    label: "Latvia",
    value: "Latvia"
  },
  {
    label: "Lebanon",
    value: "Lebanon"
  },
  {
    label: "Lesotho",
    value: "Lesotho"
  },
  {
    label: "Liberia",
    value: "Liberia"
  },
  {
    label: "Libya",
    value: "Libya"
  },
  {
    label: "Liechtenstein",
    value: "Liechtenstein"
  },
  {
    label: "Lithuania",
    value: "Lithuania"
  },
  {
    label: "Luxembourg",
    value: "Luxembourg"
  },
  {
    label: "Macau",
    value: "Macau"
  },
  {
    label: "Madagascar",
    value: "Madagascar"
  },
  {
    label: "Malawi",
    value: "Malawi"
  },
  {
    label: "Malaysia",
    value: "Malaysia"
  },
  {
    label: "Maldives",
    value: "Maldives"
  },
  {
    label: "Mali",
    value: "Mali"
  },
  {
    label: "Malta",
    value: "Malta"
  },
  {
    label: "Marshall Islands",
    value: "Marshall Islands"
  },
  {
    label: "Martinique",
    value: "Martinique"
  },
  {
    label: "Mauritania",
    value: "Mauritania"
  },
  {
    label: "Mauritius",
    value: "Mauritius"
  },
  {
    label: "Mayotte",
    value: "Mayotte"
  },
  {
    label: "Mexico",
    value: "Mexico"
  },
  {
    label: "Micronesia",
    value: "Micronesia"
  },
  {
    label: "Moldova",
    value: "Moldova"
  },
  {
    label: "Monaco",
    value: "Monaco"
  },
  {
    label: "Mongolia",
    value: "Mongolia"
  },
  {
    label: "Montenegro",
    value: "Montenegro"
  },
  {
    label: "Montserrat",
    value: "Montserrat"
  },
  {
    label: "Morocco",
    value: "Morocco"
  },
  {
    label: "Mozambique",
    value: "Mozambique"
  },
  {
    label: "Myanmar (Burma)",
    value: "Myanmar (Burma)"
  },
  {
    label: "Namibia",
    value: "Namibia"
  },
  {
    label: "Nauru",
    value: "Nauru"
  },
  {
    label: "Nepal",
    value: "Nepal"
  },
  {
    label: "Netherlands",
    value: "Netherlands"
  },
  {
    label: "New Caledonia",
    value: "New Caledonia"
  },
  {
    label: "New Zealand",
    value: "New Zealand"
  },
  {
    label: "Nicaragua",
    value: "Nicaragua"
  },
  {
    label: "Niger",
    value: "Niger"
  },
  {
    label: "Nigeria",
    value: "Nigeria"
  },
  {
    label: "Niue",
    value: "Niue"
  },
  {
    label: "Norfolk Island",
    value: "Norfolk Island"
  },
  {
    label: "Northern Mariana Islands",
    value: "Northern Mariana Islands"
  },
  {
    label: "North Korea",
    value: "North Korea"
  },
  {
    label: "North Macedonia",
    value: "North Macedonia"
  },
  {
    label: "Norway",
    value: "Norway"
  },
  {
    label: "Oman",
    value: "Oman"
  },
  {
    label: "Pakistan",
    value: "Pakistan"
  },
  {
    label: "Palau",
    value: "Palau"
  },
  {
    label: "Palestine",
    value: "Palestine"
  },
  {
    label: "Panama",
    value: "Panama"
  },
  {
    label: "Papua New Guinea",
    value: "Papua New Guinea"
  },
  {
    label: "Paraguay",
    value: "Paraguay"
  },
  {
    label: "Peru",
    value: "Peru"
  },
  {
    label: "Philippines",
    value: "Philippines"
  },
  {
    label: "Pitcairn Islands",
    value: "Pitcairn Islands"
  },
  {
    label: "Poland",
    value: "Poland"
  },
  {
    label: "Portugal",
    value: "Portugal"
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico"
  },
  {
    label: "Qatar",
    value: "Qatar"
  },
  {
    label: "Republic of the Congo",
    value: "Republic of the Congo"
  },
  {
    label: "Réunion",
    value: "Réunion"
  },
  {
    label: "Romania",
    value: "Romania"
  },
  {
    label: "Russia",
    value: "Russia"
  },
  {
    label: "Rwanda",
    value: "Rwanda"
  },
  {
    label: "Saint Barthélemy",
    value: "Saint Barthélemy"
  },
  {
    label: "Saint Helena",
    value: "Saint Helena"
  },
  {
    label: "Saint Kitts and Nevis",
    value: "Saint Kitts and Nevis"
  },
  {
    label: "Saint Lucia",
    value: "Saint Lucia"
  },
  {
    label: "Saint Martin",
    value: "Saint Martin"
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon"
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines"
  },
  {
    label: "Samoa",
    value: "Samoa"
  },
  {
    label: "San Marino",
    value: "San Marino"
  },
  {
    label: "Sao Tome and Principe",
    value: "Sao Tome and Principe"
  },
  {
    label: "Saudi Arabia",
    value: "Saudi Arabia"
  },
  {
    label: "Senegal",
    value: "Senegal"
  },
  {
    label: "Serbia",
    value: "Serbia"
  },
  {
    label: "Seychelles",
    value: "Seychelles"
  },
  {
    label: "Sierra Leone",
    value: "Sierra Leone"
  },
  {
    label: "Singapore",
    value: "Singapore"
  },
  {
    label: "Sint Maarten",
    value: "Sint Maarten"
  },
  {
    label: "Slovakia",
    value: "Slovakia"
  },
  {
    label: "Slovenia",
    value: "Slovenia"
  },
  {
    label: "Solomon Islands",
    value: "Solomon Islands"
  },
  {
    label: "Somalia",
    value: "Somalia"
  },
  {
    label: "South Africa",
    value: "South Africa"
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "South Georgia and the South Sandwich Islands"
  },
  {
    label: "South Korea",
    value: "South Korea"
  },
  {
    label: "South Sudan",
    value: "South Sudan"
  },
  {
    label: "Spain",
    value: "Spain"
  },
  {
    label: "Sri Lanka",
    value: "Sri Lanka"
  },
  {
    label: "Sudan",
    value: "Sudan"
  },
  {
    label: "Suriname",
    value: "Suriname"
  },
  {
    label: "Svalbard and Jan Mayen",
    value: "Svalbard and Jan Mayen"
  },
  {
    label: "Sweden",
    value: "Sweden"
  },
  {
    label: "Switzerland",
    value: "Switzerland"
  },
  {
    label: "Syria",
    value: "Syria"
  },
  {
    label: "Taiwan",
    value: "Taiwan"
  },
  {
    label: "Tajikistan",
    value: "Tajikistan"
  },
  {
    label: "Tanzania",
    value: "Tanzania"
  },
  {
    label: "Thailand",
    value: "Thailand"
  },
  {
    label: "Timor-Leste",
    value: "Timor-Leste"
  },
  {
    label: "Togo",
    value: "Togo"
  },
  {
    label: "Tokelau",
    value: "Tokelau"
  },
  {
    label: "Tonga",
    value: "Tonga"
  },
  {
    label: "Trinidad and Tobago",
    value: "Trinidad and Tobago"
  },
  {
    label: "Tunisia",
    value: "Tunisia"
  },
  {
    label: "Turkey",
    value: "Turkey"
  },
  {
    label: "Turkmenistan",
    value: "Turkmenistan"
  },
  {
    label: "Turks and Caicos Islands",
    value: "Turks and Caicos Islands"
  },
  {
    label: "Tuvalu",
    value: "Tuvalu"
  },
  {
    label: "Uganda",
    value: "Uganda"
  },
  {
    label: "Ukraine",
    value: "Ukraine"
  },
  {
    label: "United Arab Emirates",
    value: "United Arab Emirates"
  },
  {
    label: "United Kingdom",
    value: "United Kingdom"
  },
  {
    label: "United States",
    value: "United States"
  },
  {
    label: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands"
  },
  {
    label: "United States Virgin Islands",
    value: "United States Virgin Islands"
  },
  {
    label: "Uruguay",
    value: "Uruguay"
  },
  {
    label: "Uzbekistan",
    value: "Uzbekistan"
  },
  {
    label: "Vanuatu",
    value: "Vanuatu"
  },
  {
    label: "Vatican City",
    value: "Vatican City"
  },
  {
    label: "Venezuela",
    value: "Venezuela"
  },
  {
    label: "Vietnam",
    value: "Vietnam"
  },
  {
    label: "Wallis and Futuna",
    value: "Wallis and Futuna"
  },
  {
    label: "Western Sahara",
    value: "Western Sahara"
  },
  {
    label: "Yemen",
    value: "Yemen"
  },
  {
    label: "Zambia",
    value: "Zambia"
  },
  {
    label: "Zimbabwe",
    value: "Zimbabwe"
  },
  {
    label: "Eswatini",
    value: "Eswatini"
  }
];
export {
  a as countriesJSON,
  e as industriesJSON,
  t as nationalitiesJSON
};
