import { MARITAL_STATUS } from '@modules/kyc/api/const';
import { nationalitiesJSON as nationalities } from '@huspy/forge/lib';
import { z } from 'zod';
import {
  emailSchema,
  enumSchema,
  numberSchema,
  phoneNumberSchema,
  stringSchema,
} from '@shared/schema';

const MARTIAL_STATUS_OPTIONS = [
  { value: MARITAL_STATUS.married },
  { value: MARITAL_STATUS.marriedWithPrenuptial },
  { value: MARITAL_STATUS.single },
  { value: MARITAL_STATUS.divorced },
  { value: MARITAL_STATUS.widowed }
];

const nationalityEnum = enumSchema(
  nationalities.map((nationality: { value: string }) => nationality.value) as [
    string,
    ...string[]
  ]
);

const civilStatusEnum = enumSchema(
  MARTIAL_STATUS_OPTIONS.map(({ value }) => value) as [
    'single' | 'married' | 'divorced' | 'widowed' | 'married_with_prenuptial'
  ]
);

const schema = z.object({
  first_name: stringSchema,
  last_name: stringSchema,
  email: emailSchema,
  mobile: phoneNumberSchema,
  mother_maiden_name: stringSchema,
  nationality: nationalityEnum,
  civil_status: civilStatusEnum,
  no_of_dependents: numberSchema,
});

export default schema;
