import { INCOME_TYPE } from '@modules/kyc/api/const';
import { EmploymentKYCData, FinancialKYCData } from '@modules/kyc/api/types';

const extractDate = (timestamp: string | Date): string => {
  const date = new Date(timestamp);
  return date.toISOString().split('T')[0]!;
};

export const getEmploymentESInitialValues = (
  employmentData: EmploymentKYCData<'ES'>
) => ({
  employment_type: employmentData?.employment_type ?? null,
  start_date: employmentData?.start_date
    ? extractDate(employmentData.start_date)
    : null,
  no_of_annual_payments: employmentData?.no_of_annual_payments ?? null,
  job_title: employmentData?.job_title ?? null,
});

export const getIncomesESInitialValues = (
  incomeData: FinancialKYCData['incomes']
): FinancialKYCData['incomes'] => {
  if (!incomeData?.length) {
    return [
      {
        external_id: null,
        type: INCOME_TYPE.salary,
        amount: null,
        frequency: 'monthly',
      },
      {
        external_id: null,
        type: INCOME_TYPE.bonus,
        amount: null,
        frequency: '',
      }
    ];
  }

  const hasSalary = incomeData.some(
    (income) => income.type === INCOME_TYPE.salary
  );
  const hasBonus = incomeData.some(
    (income) => income.type === INCOME_TYPE.bonus
  );

  if (hasSalary && hasBonus) {
    return incomeData;
  }

  if (!hasSalary) {
    return [
      ...incomeData,
      {
        external_id: null,
        type: INCOME_TYPE.salary,
        amount: null,
        frequency: 'monthly',
      }
    ];
  }

  if (!hasBonus) {
    return [
      ...incomeData,
      {
        external_id: null,
        type: INCOME_TYPE.bonus,
        amount: null,
        frequency: 'yearly',
      }
    ];
  }

  return incomeData;
};
