import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import useGetFeatureFlagsDirectly from '@modules/core/hooks/queries/useGetFeatureFlagsDirectly';
import { useAuthStore } from '@modules/core/store';
import usePersistAuth from '@modules/core/hooks/usePersistAuth';
import LayoutV1 from './v1/index';
import LayoutV2 from './v2/index';

type Props = {
  headerTitlePath: string;
  subTitlePath: string;
  headerClassName?: string;
};

const Layout = (props: Props) => {
  usePersistAuth();
  const { oppId } = useAuthStore();
  const {
    data: isSimplifiedVault,
    isLoading,
    isFetched,
  } = useGetFeatureFlagsDirectly(
    oppId!,
    AMPLITUDE_FEATURE_FLAGS.CLIENT_VAULT_SIMPLIFIED
  );

  if (isLoading || !isFetched) {
    return <></>;
  }

  if (isSimplifiedVault) {
    return <LayoutV2 />;
  }

  return <LayoutV1 { ...props } />;
};

export default Layout;
