import { create } from 'zustand';

type KYCStore = {
  requireKyc: boolean;
  setRequireKyc: (condition: boolean) => void;
};

export const useKYCStore = create<KYCStore>((set) => ({
  requireKyc: true,
  setRequireKyc: (requireKyc) => set(() => ({ requireKyc })),
}));
