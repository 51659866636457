import {
  Input, Title, Button, theme
} from '@huspy/forge';
import {
  Box, Flex, Image, rem
} from '@mantine/core';
import { useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { iconsPath } from '@shared/css.const';
import useAccessTheVault from '@modules/core/hooks/mutations/useAccessTheVault';
import Loader from '@shared/loader';
import { loginWidgetStyles } from './styles.css';

const {
  label,
  root,
  wrapper,
} = loginWidgetStyles;

type Props = {
  isDesktopView?: boolean;
};

const Login = ({ isDesktopView }: Props) => {
  const { t } = useTranslation();
  const { mutate, isPending } = useAccessTheVault();
  const [pwd, setPwd] = useState<string>('');
  const match = useMatch('/login/:oppId?');

  const handleLogin = () => mutate({ password: pwd!, opportunityId: match?.params.oppId! });

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleLogin();
    }
  };

  if (isDesktopView) {
    return (
      <Flex
        w='100%'
        direction='column'
        gap={ rem(theme.spacing.xl) }
        p={ `${theme.spacing.xl} ${rem(theme.spacing['2xl'])}` }
        styles={ { root: { border: `1px solid ${theme.colors.neutral[2]}`, borderRadius: '8px' } } }
      >
        <Flex align='center'>
          <Box>
            <Image
              miw={ rem(21) }
              src={ `${iconsPath}/black-lock.svg` }
              styles={ {
                root: {
                  position: 'relative',
                  bottom: rem(-2.5),
                },
              } }
            />
          </Box>
          <Title size='h4' ml={ rem(theme.spacing.sm) }>{t('login.loginForm.label')}</Title>
        </Flex>
        <Box>
          <Input
            label={ t('login.loginForm.inputLabel') }
            placeholder='E.g. 123456'
            size='lg'
            type='password'
            value={ pwd }
            onKeyDown={ handleKeyDown }
            onChange={ (e) => setPwd(e.target.value) }
            data-test='input-password-desktop'
          />
        </Box>
        <Box>
          <Button
            size='lg'
            w='100%'
            disabled={ !pwd || isPending }
            styles={ { label: { fontSize: theme.fontSizes.md } } }
            onClick={ handleLogin }
            data-test='btn-login-desktop'
          >
            { !isPending ? t('login.loginForm.buttonText') : (
              <>
                {t('login.loginForm.authenticating')}
                <Flex ml={ theme.spacing.sm }>
                  <Loader />
                </Flex>
              </>
            ) }
          </Button>
        </Box>
      </Flex>
    );
  }

  return (
    <Box className={ wrapper }>
      <Flex direction='column' gap={ rem(theme.spacing.lg) } className={ root }>
        <Flex align='center'>
          <Box>
            <Image
              miw={ rem(16) }
              src={ `${iconsPath}/lock.svg` }
              styles={ {
                root: {
                  position: 'relative',
                  bottom: rem(-2.5),
                },
              } }
            />
          </Box>
          <Title size='h5' c='neutral.0' ml={ rem(theme.spacing.sm) }>{ t('login.loginForm.label') }</Title>
        </Flex>
        <Box>
          <Input
            label={ t('login.loginForm.inputLabel') }
            placeholder='E.g. 123456'
            size='lg'
            type='password'
            value={ pwd }
            classNames={ { label } }
            onChange={ (e) => setPwd(e.target.value) }
            data-test='input-password-mobile'
          />
        </Box>
        <Box>
          <Button
            size='lg'
            w='100%'
            disabled={ !pwd || isPending }
            onClick={ handleLogin }
            data-test='btn-login-mobile'
          >
            { !isPending ? t('login.loginForm.buttonText') : (
              <>
                {t('login.loginForm.authenticating')}
                <Flex ml={ theme.spacing.sm }>
                  <Loader />
                </Flex>
              </>
            ) }
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default Login;
