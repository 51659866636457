import {
  Box, Flex, SimpleGrid, Skeleton, Stack, rem
} from '@mantine/core';
import { theme } from '@huspy/forge';
import { applicantProgressStyles } from '@modules/documents/pages/Overview/components/ApplicantProgress/styles/index.css';
import NavigatorCard from '../NavigatorCard';
import ApplicantInfo from '../ApplicantInfo';

const { categoriesWrapper, wrapper } = applicantProgressStyles;

const AppSkeleton = () => <></>;

// eslint-disable-next-line react/display-name
AppSkeleton.ApplicantDocuments = () => (
  <>
    <Box hiddenFrom='md'>
      <ApplicantInfo.Skeleton />
      <NavigatorCard.Skeleton />
      <NavigatorCard.Skeleton />
      <NavigatorCard.Skeleton />
      <NavigatorCard.Skeleton />
    </Box>
    <Flex visibleFrom='md' className={ wrapper }>
      <ApplicantInfo.Skeleton />
      <Stack w='100%' className={ categoriesWrapper } gap={ 2 }>
        <NavigatorCard.Skeleton noMarginTop />
        <NavigatorCard.Skeleton />
        <NavigatorCard.Skeleton />
        <NavigatorCard.Skeleton />
      </Stack>
    </Flex>
  </>
);

const InputSkeleton = () => (
  <Box>
    <Skeleton h={ rem(21) } maw={ rem(95) } />
    <Skeleton h={ rem(40) } miw={ rem(284) } mt={ theme.spacing.xs } />
  </Box>
);

// eslint-disable-next-line react/display-name
AppSkeleton.AplicantKycForm = () => (
  <SimpleGrid
    cols={ { base: 1, md: 2 } }
    spacing={ theme.spacing.lg }
    verticalSpacing={ theme.spacing.xl }
  >
    {/* eslint-disable-next-line unicorn/no-useless-spread, react/no-array-index-key */}
    {[...new Array(6)].map((_, i) => <InputSkeleton key={ i } />)}
  </SimpleGrid>
);

export default AppSkeleton;
