import { Title as S, Button as E, Box as C, Progress as k, useCombobox as x, Combobox as u, Group as N, Checkbox as B, rem as T, ScrollArea as j, Switch as b, MantineProvider as z } from "@mantine/core";
import r, { forwardRef as O, useState as q } from "react";
import { t as P, c as f, T as w } from "./Text-D75zcpkn.mjs";
import { d as R, a as D, I as V } from "./index-BwlI3kbE.mjs";
import { S as ue, T as pe } from "./index-BwlI3kbE.mjs";
import p from "clsx";
import { t as I, _ as F } from "./vanilla-BVQNXG0s.mjs";
import "@tabler/icons-react";
import "i18next";
import "@mantine/vanilla-extract";
function se(e) {
  const { size: t = "h3", ...a } = e;
  return /* @__PURE__ */ r.createElement(S, { className: P({ size: t }), ...a });
}
var G = f({ defaultClassName: "_1nuwdpf0", variantClassNames: { size: { lg: "_1nuwdpf1", md: "_1nuwdpf2", sm: "_1nuwdpf3" }, color: { primary: "_1nuwdpf4", purple: "_1nuwdpf5" }, disabled: { true: "_1nuwdpf6", false: "_1nuwdpf7" }, variant: { primary: "_1nuwdpf8", secondary: "_1nuwdpf9", tertiary: "_1nuwdpfa" }, inverted: { true: "_1nuwdpfb", false: "_1nuwdpfc" } }, defaultVariants: {}, compoundVariants: [[{ color: "primary", variant: "primary", inverted: !1, disabled: !1 }, "_1nuwdpfd"], [{ color: "primary", variant: "secondary", inverted: !1, disabled: !1 }, "_1nuwdpfe"], [{ color: "primary", variant: "secondary", inverted: !0, disabled: !1 }, "_1nuwdpff"], [{ color: "primary", variant: "tertiary", inverted: !1, disabled: !1 }, "_1nuwdpfg"], [{ color: "primary", disabled: !0, inverted: !1 }, "_1nuwdpfh"], [{ color: "primary", variant: "secondary", disabled: !0, inverted: !1 }, "_1nuwdpfi"], [{ color: "primary", variant: "tertiary", disabled: !0, inverted: !1 }, "_1nuwdpfj"], [{ color: "purple", variant: "primary", disabled: !1, inverted: !1 }, "_1nuwdpfk"], [{ color: "purple", variant: "primary", disabled: !0, inverted: !1 }, "_1nuwdpfl"], [{ color: "primary", variant: "secondary", disabled: !0, inverted: !1 }, "_1nuwdpfm"], [{ color: "primary", variant: "tertiary", disabled: !0, inverted: !1 }, "_1nuwdpfn"]] }), L = f({ defaultClassName: "n90u60", variantClassNames: { size: { lg: "n90u61", md: "n90u62", sm: "n90u63" }, variant: { primary: "n90u64", secondary: "n90u65", tertiary: "n90u66" }, color: { primary: "n90u67", purple: "n90u68" }, disabled: { true: "n90u69", false: "n90u6a" }, inverted: { true: "n90u6b", false: "n90u6c" } }, defaultVariants: {}, compoundVariants: [[{ color: "primary", variant: "secondary", inverted: !1, disabled: !1 }, "n90u6d"], [{ color: "primary", variant: "tertiary", inverted: !1, disabled: !1 }, "n90u6e"], [{ color: "primary", variant: "secondary", inverted: !0, disabled: !1 }, "n90u6f"]] });
const $ = ({
  size: e,
  color: t,
  classNames: a,
  inverted: n,
  disabled: s = !1,
  variant: d
}) => {
  const {
    // @ts-expect-error
    root: i,
    label: o
  } = { ...a };
  return {
    buttonClassNames: {
      ...a,
      root: p(G({
        size: e,
        color: t,
        disabled: s,
        inverted: n,
        variant: d
      }), i),
      label: p(L({
        size: e,
        color: t,
        variant: d,
        disabled: s,
        inverted: n
      }), o)
    }
  };
}, ne = O(({
  classNames: e,
  children: t,
  size: a = "md",
  color: n = "primary",
  variant: s = "primary",
  inverted: d = !1,
  disabled: i = !1,
  ...o
}, m) => {
  const { buttonClassNames: v } = $({
    ...o,
    size: a,
    color: n,
    disabled: i,
    inverted: d,
    variant: s,
    classNames: e
  });
  return /* @__PURE__ */ r.createElement(
    E,
    {
      classNames: v,
      disabled: i,
      ref: m,
      ...o
    },
    t
  );
});
var A = f({ defaultClassName: "_1j3vsst5", variantClassNames: { madeProgress: { true: "_1j3vsst6", false: "_1j3vsst7" } }, defaultVariants: {}, compoundVariants: [] }), M = f({ defaultClassName: "_1j3vsst0", variantClassNames: { madeProgress: { true: "_1j3vsst1", false: "_1j3vsst2" }, completed: { true: "_1j3vsst3", false: "_1j3vsst4" } }, defaultVariants: {}, compoundVariants: [] });
const oe = ({
  value: e,
  label: t,
  classNames: a,
  ...n
}) => {
  const { root: s, section: d, label: i } = { ...a }, o = !!(e && e > 0);
  return /* @__PURE__ */ r.createElement(C, null, t && /* @__PURE__ */ r.createElement(w, { size: "xs", fw: 400, mb: 0 }, t), /* @__PURE__ */ r.createElement(
    k,
    {
      size: "md",
      value: e,
      classNames: {
        section: p(A({ madeProgress: o }), d),
        root: p(M({ madeProgress: o, completed: e === 100 }), s),
        label: i
      },
      ...n
    }
  ));
};
function le({
  classNames: e,
  data: t,
  inputProps: a,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  maxOptionsSelectable: n,
  value: s,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleValueRemove: d,
  handleValueSelect: i,
  ...o
}) {
  const [m, v] = q(""), c = x({
    onDropdownClose: () => c.resetSelectedOption(),
    onDropdownOpen: () => c.updateSelectedOptionIndex("active")
  }), { dropdown: _, option: h } = { ...e }, g = t.filter((l) => l.label.toLowerCase().includes(m.trim().toLowerCase())).map((l) => /* @__PURE__ */ r.createElement(u.Option, { value: l.value, key: l.value, active: s.includes(l.value) }, /* @__PURE__ */ r.createElement(N, { gap: "sm" }, /* @__PURE__ */ r.createElement(
    B,
    {
      "data-test": "checkbox-bank",
      size: T(16),
      checked: s.includes(l.value),
      onChange: () => {
      },
      "aria-hidden": !0,
      tabIndex: -1,
      style: { pointerEvents: "none" },
      color: I.colors.purple[4]
    }
  ), /* @__PURE__ */ r.createElement(w, { size: "sm", m: 0 }, l.label))));
  return /* @__PURE__ */ r.createElement(
    u,
    {
      store: c,
      offset: 4,
      onOptionSubmit: i,
      withinPortal: !1,
      classNames: {
        dropdown: p(R, _),
        option: p(D({ size: "md" }), h),
        ...e
      },
      ...o
    },
    /* @__PURE__ */ r.createElement(u.DropdownTarget, null, /* @__PURE__ */ r.createElement(u.EventsTarget, null, /* @__PURE__ */ r.createElement(
      V,
      {
        value: m,
        placeholder: a.placeholder ?? "Search",
        onChange: (l) => {
          c.updateSelectedOptionIndex(), v(l.currentTarget.value);
        },
        onFocus: () => c.openDropdown(),
        onBlur: () => c.closeDropdown(),
        ...a
      }
    ))),
    /* @__PURE__ */ r.createElement(u.Dropdown, null, /* @__PURE__ */ r.createElement(u.Options, null, /* @__PURE__ */ r.createElement(j.Autosize, { scrollbars: "y", mah: 235, type: "always", scrollbarSize: 10 }, g)))
  );
}
var H = { track: "_17fdqic0", label: "_17fdqic1", thumb: "_17fdqic2", input: "_17fdqic3" }, J = { track: "_17fdqic4", label: "_17fdqic5", thumb: "_17fdqic6", input: "_17fdqic7" };
const y = (e) => /* @__PURE__ */ r.createElement(b, { ...e, classNames: H }), K = (e) => /* @__PURE__ */ r.createElement(b, { ...e, classNames: J });
y.Briks = K;
y.Group = b.Group;
const Q = (e) => ({
  variables: {
    "--_switch-thumb-bg": e.colors.neutral[6],
    "--_switch-bg": e.colors.neutral[8]
  },
  dark: { "--_switch-bg": e.colors.neutral[8] },
  light: { "--_switch-bg": e.colors.neutral[8] }
}), ie = ({ cssVariablesResolver: e, ...t }) => /* @__PURE__ */ r.createElement(
  z,
  {
    ...t,
    theme: F,
    cssVariablesResolver: (a) => {
      const n = Q(a), s = (e == null ? void 0 : e(a)) ?? {};
      return {
        variables: { ...n.variables, ...s.variables },
        dark: { ...n.dark, ...s.dark },
        light: { ...n.light, ...s.light }
      };
    }
  }
);
export {
  ne as Button,
  ie as ForgeProvider,
  V as Input,
  le as MultiSelectCheckbox,
  oe as ProgressBar,
  ue as Select,
  y as Switch,
  w as Text,
  se as Title,
  pe as Tooltip,
  I as theme
};
