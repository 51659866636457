import { useAuthStore } from '@modules/core/store';
import {
  Navigate, Outlet, useLocation
} from 'react-router-dom';
import usePersistAuth from '@modules/core/hooks/usePersistAuth';
import { ErrorBoundary } from '@shared/errorBoundary';
import { useEffect } from 'react';
import { useHandleLanguage } from '@modules/core/hooks/useHandleLanguage';
import { Box } from '@huspy/briks-web';

const Layout = () => {
  const { tokenFromSessionStorage } = usePersistAuth();
  const { token, oppId } = useAuthStore();
  const location = useLocation();
  useHandleLanguage();

  useEffect(() => {
    const html = document.querySelector('html')!;
    html.dataset.pandaTheme = 'real-estate';

    return () => {
      html.dataset.pandaTheme = 'mortgage';
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (!tokenFromSessionStorage && !token) {
    return <Navigate to={ `/login/${oppId ?? ''}` } />;
  }

  if (!oppId) {
    return <></>;
  }

  if (location.pathname !== '/') {
    return <Navigate to='/' />;
  }

  return (
    <Box>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </Box>
  );
};

export default Layout;
