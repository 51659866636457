import { useAuthStore } from '@modules/core/store';
import useVaultProgress from '@modules/core/hooks/queries/useVaultProgress';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import { Suspense, lazy } from 'react';

const OverviewES = lazy(() => import('./feautures/es'));
const OverviewUAE = lazy(() => import('./feautures/ae'));

const KYCPersonal = () => {
  const { oppId } = useAuthStore();
  const { data: vaultProgressData } = useVaultProgress(oppId!);

  const isSpainBusiness = vaultProgressData.businessIdentifier === BUSINESS_IDENTIFIER.ES_BAYTECA;

  return (
    <Suspense>
      {isSpainBusiness ? <OverviewES /> : <OverviewUAE />}
    </Suspense>
  );
};

export default KYCPersonal;
