import { ResidencyKYCData } from '@modules/kyc/api/types';

const initialAddress = {
  city: '',
  state_code: '',
  street: '',
  country_code: 'AE',
};

const initialHomeAddress = {
  city: '',
  country_code: '',
  postal_code: '',
  street: '',
};

export const getResidencyInitialValues = (data: ResidencyKYCData) => ({
  status_of_current_residence: data.status_of_current_residence ?? '',
  address: data.address
    ? {
      ...data.address,
      country_code: initialAddress.country_code,
    }
    : initialAddress,
  po_box: data.po_box ?? '',
  no_of_months_lived_in_current_residence:
    data.no_of_months_lived_in_current_residence
      ? data.no_of_months_lived_in_current_residence / 12
      : null,
  year_moved_to_country: data.year_moved_to_country ?? null,
  address_in_home_country: data?.address_in_home_country ?? initialHomeAddress,
  mobile_no_in_home_country: data?.mobile_no_in_home_country ?? '',
  reference: {
    mobile_no_of_first_friend_or_relative_in_home_country:
      data?.reference?.mobile_no_of_first_friend_or_relative_in_home_country
      ?? '',
    mobile_no_of_first_friend_or_relative_in_the_purchasing_country:
      data?.reference
        ?.mobile_no_of_first_friend_or_relative_in_the_purchasing_country ?? '',
    mobile_no_of_sec_friend_or_relative_in_the_purchasing_country:
      data?.reference
        ?.mobile_no_of_sec_friend_or_relative_in_the_purchasing_country ?? '',
    mobile_no_of_second_friend_or_relative_in_home_country:
      data?.reference?.mobile_no_of_second_friend_or_relative_in_home_country
      ?? '',
    name_of_first_friend_or_relative_in_the_home_country:
      data?.reference?.name_of_first_friend_or_relative_in_the_home_country
      ?? '',
    name_of_first_friend_or_relative_in_the_purchasing_country:
      data?.reference
        ?.name_of_first_friend_or_relative_in_the_purchasing_country ?? '',
    name_of_second_friend_or_relative_in_home_country:
      data?.reference?.name_of_second_friend_or_relative_in_home_country ?? '',
    name_of_second_friend_or_relative_in_the_purchasing_country:
      data?.reference
        ?.name_of_second_friend_or_relative_in_the_purchasing_country ?? '',
  },
});
