import { kycApi } from '@modules/kyc/api';
import { KYCCountry } from '@modules/kyc/api/types';
import { useKYCStore } from '@modules/kyc/store';
import { useQuery } from '@tanstack/react-query';

const useKYCData = <TCountry extends KYCCountry = 'AE'>(opportunityId?: string | null) => {
  const { requireKyc, setRequireKyc } = useKYCStore();
  const {
    data, isLoading, error, refetch,
  } = useQuery({
    queryKey: ['kyc', opportunityId],
    queryFn: () => kycApi.getKYCData<TCountry>(opportunityId!),
    enabled: !!opportunityId && requireKyc,
    staleTime: 1_800_000,
    retry(failureCount, err) {
      if (err.statusCode === 403) {
        setRequireKyc(false);
      }
      return !(err.statusCode === 403 || failureCount > 5);
    },
  });

  return {
    data: { kyc: data ?? {} },
    isLoading,
    error,
    refetch,
    isDisabled: error?.statusCode === 403,
  };
};

export default useKYCData;
