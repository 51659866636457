import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useLanguageStore } from '../store';

const allowedLanguages = new Set(['en', 'es']);

export const useHandleLanguage = () => {
  const { i18n } = useTranslation();
  const { setLanguage } = useLanguageStore();
  const [params] = useSearchParams();
  const lang = params.get('lang') ?? '';

  useEffect(() => {
    if (lang) {
      const language = allowedLanguages.has(lang) ? lang : 'en';
      i18n.changeLanguage(language);
      setLanguage(language);
    }
  }, [i18n, lang, setLanguage]);

  return {};
};
