import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getSessionStorageLanguagePreference } from '@shared/utils';
import commonEn from './locales/en/common.json';
import commonEs from './locales/es/common.json';
import homeEn from './locales/en/home.json';
import loginEn from './locales/en/login.json';
import loginEs from './locales/es/login.json';
import documentsEn from './locales/en/documents.json';
import documentsEs from './locales/es/documents.json';
import kycEn from './locales/en/kyc.json';
import kycEs from './locales/es/kyc.json';
import homeEs from './locales/es/home.json';
import proposalEn from './locales/en/proposal.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      common: commonEn,
      home: homeEn,
      login: loginEn,
      documents: documentsEn,
      kyc: kycEn,
      proposal: proposalEn,
    },
  },
  es: {
    translation: {
      common: commonEs,
      kyc: kycEs,
      home: homeEs,
      login: loginEs,
      documents: documentsEs,
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getSessionStorageLanguagePreference() ?? 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: { escapeValue: false },
  });

// eslint-disable-next-line no-restricted-exports
export { default } from 'i18next';
