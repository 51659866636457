import { Text, theme } from '@huspy/forge';
import {
  Box, Center, Divider, Flex, Image, RingProgress, Skeleton, rem
} from '@mantine/core';
import { iconsPath } from '@shared/css.const';
import { useTranslation } from 'react-i18next';
import Card from '@modules/core/components/Card';
import { useAuthStore } from '@modules/core/store';
import useVaultProgress from '@modules/core/hooks/queries/useVaultProgress';
import { progressStyles, progressCircleContainer } from './styles/index.css';

const getProgressBarColor = (value: number) => {
  if (value >= 100) {
    return {
      trace: theme.colors.purple[3],
      root: theme.colors.purple[0],
      text: theme.colors.purple[4],
    };
  }

  if (value > 0) {
    return {
      trace: theme.colors.purple[2],
      root: theme.colors.purple[0],
      text: theme.colors.purple[3],
    };
  }

  return {
    trace: theme.colors.neutral[0],
    root: theme.colors.neutral[0],
    text: theme.colors.neutral[6],
  };
};

const VaultProgressBar = () => {
  const { t } = useTranslation();
  const { oppId } = useAuthStore();
  const { data: vaultProgressData, isLoading } = useVaultProgress(oppId!);
  const documentsProgressBarColor = getProgressBarColor(vaultProgressData.documentProgress.uploaded_percentage);
  const kycBarColor = getProgressBarColor(vaultProgressData.kycProgress.percentage);

  return (
    <Card visibleFrom='md' className={ progressStyles }>
      <Text
        size='sm'
        fw={ 600 }
        c='neutral.5'
        styles={ { root: { textTransform: 'uppercase' } } }
      >
        { t('documents.progress') }
      </Text>
      <Box w='100%' h='100%' className={ progressCircleContainer } mt={ theme.spacing.xl }>
        <Flex>
          <Flex justify='center' align='center' pr={ theme.spacing.sm }>
            <Image src={ `${iconsPath}/documents-black.svg` } mah={ rem(17) } maw={ rem(17) } />
          </Flex>
          <Text c='neutral.8' fw={ 600 }>
            { t('documents.title') }
          </Text>
        </Flex>
        <Center w='100%' mt={ theme.spacing.xl }>
          <Box w='100%'>
            <Flex direction='column' align='center'>
              {isLoading ? (
                <>
                  <Skeleton circle h={ rem(110) } w={ rem(110) } mt={ theme.spacing.md } />
                  <Skeleton
                    h={ rem(25) }
                    w={ rem(120) }
                    mt={ theme.spacing.sm }
                  />
                </>
              ) : (
                <>
                  <RingProgress
                    size={ 130 }
                    // @ts-ignore
                    styles={ { curve: { '--_curve-root-color': documentsProgressBarColor.root } } }
                    label={ (
                      <Text
                        ta='center'
                        size='sm'
                        fw={ 600 }
                        c={ documentsProgressBarColor.text }
                        data-test='ring-progress-documents-percentage'
                      >
                        { vaultProgressData.documentProgress.uploaded_percentage }
                        %
                      </Text>
                    ) }
                    data-test='ring-progress-documents'
                    roundCaps
                    sections={ [
                      {
                        value: vaultProgressData.documentProgress.uploaded_percentage,
                        color: documentsProgressBarColor.trace,
                      }
                    ] }
                  />
                  <Text size='sm' data-test='ring-progress-documents-count'>
                    {
                      t('documents.documentsProgress', {
                        valid: vaultProgressData.documentProgress.uploaded,
                        total: vaultProgressData.documentProgress.total,
                      })
                    }
                  </Text>
                </>
              )}
            </Flex>
            {!vaultProgressData.kycProgress.disabled && (
              <>
                <Divider
                  my={ theme.spacing.xl }
                  w='100%'
                  styles={ { root: { backgroundColor: theme.colors.neutral[3] } } }
                />
                <Flex>
                  <Flex justify='center' align='center' pr={ theme.spacing.sm }>
                    <Image src={ `${iconsPath}/sign-document-black.svg` } mah={ rem(17) } maw={ rem(17) } />
                  </Flex>
                  <Text c='neutral.8' fw={ 600 }>{ t('kyc.title') }</Text>
                </Flex>
                <Flex direction='column' align='center'>
                  { isLoading ? (
                    <>
                      <>
                        <Skeleton circle h={ rem(110) } w={ rem(110) } mt={ theme.spacing.md } />
                        <Skeleton
                          h={ rem(25) }
                          w={ rem(120) }
                          mt={ theme.spacing.sm }
                        />
                      </>
                    </>
                  ) : (

                    <>
                      <RingProgress
                        size={ 130 }
                        // @ts-ignore
                        styles={ { curve: { '--_curve-root-color': kycBarColor.root } } }
                        data-test='ring-progress-getting-to-know-you'
                        label={ (
                          <Text
                            ta='center'
                            size='sm'
                            fw={ 600 }
                            c={ kycBarColor.text }
                            data-test='ring-progress-getting-to-know-you-percentage'
                          >
                            { vaultProgressData.kycProgress.percentage }
                            %
                          </Text>
                        ) }
                        roundCaps
                        sections={ [
                          { value: vaultProgressData.kycProgress.percentage, color: kycBarColor.trace }
                        ] }
                      />
                      <Text size='sm' data-test='ring-progress-getting-to-know-you-count'>
                        {
                          t('kyc.kycProgress', {
                            valid: vaultProgressData.kycProgress.count,
                            total: vaultProgressData.kycProgress.total,
                          })
                        }
                      </Text>
                    </>

                  )}
                </Flex>
              </>
            )}
          </Box>
        </Center>
      </Box>
    </Card>
  );
};

export default VaultProgressBar;
