import { coreApi } from '@modules/core/api';
import { useQuery } from '@tanstack/react-query';

const useGetFeatureFlagsDirectly = (userId: string, flagKey: string) => {
  const {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
    isFetched,
  } = useQuery({
    queryKey: ['directFeatureFlag', userId, flagKey],
    queryFn: () => coreApi.getFeatureFlagDirectly(userId, flagKey),
    retry: 0,
    enabled: !!userId,
    initialData: { [flagKey]: { key: 'off' } },
  });

  const isFeatureEnabled = data?.[flagKey]?.key === 'on';

  return {
    data: isFeatureEnabled,
    isLoading,
    error,
    refetch,
    isRefetching,
    isFetched,
  };
};

export default useGetFeatureFlagsDirectly;
