import { theme } from '@huspy/forge';
import { loaderStyles } from './style.css';

/* eslint-disable max-len */
const Loader = ({ color = theme.colors.neutral[5], size = 22 }: { color?: string, size?: number; }) => (
  <svg width={ size } height={ size } viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg' className={ loaderStyles }>
    <path d='M11 4.70605C14.4518 4.70605 17.25 7.50427 17.25 10.9561C17.25 14.4078 14.4518 17.2061 11 17.2061C7.54822 17.2061 4.75 14.4078 4.75 10.9561' stroke={ color } strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M20.1182 13.1025C19.9237 13.9306 19.6191 14.7288 19.2124 15.4759' stroke={ color } strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M17.5117 17.6924C16.8957 18.279 16.2084 18.7858 15.4658 19.2007' stroke={ color } strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M11.8271 1.62256C12.6746 1.69763 13.5077 1.88887 14.303 2.19089' stroke={ color } strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16.7397 3.5542C17.4144 4.07229 18.0123 4.68337 18.5156 5.3692' stroke={ color } strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M19.833 7.8335C20.1235 8.6331 20.2959 9.47081 20.3447 10.3202' stroke={ color } strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12.8748 20.1436C7.8007 21.1791 2.84785 17.9052 1.81232 12.8311C0.776782 7.75694 4.0507 2.80409 9.12482 1.76855' stroke={ color } strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>

);

export default Loader;
