import {
  Box,
  Flex, Stepper, rem
} from '@mantine/core';
import { useMemo } from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import Card from '@modules/core/components/Card';
import { t } from 'i18next';
import { theme } from '@huspy/forge';
import VaultProgressBar from '@modules/core/components/VaultProgressBar';
import { useAuthStore } from '@modules/core/store';
import AppSkeleton from '@modules/core/components/Skeleton';
import useKYCData from '@modules/kyc/hooks/queries/useKYCData';
import useVaultProgress from '@modules/core/hooks/queries/useVaultProgress';
import useKycGateProgression, { kycStepsInOrderAe, kycStepsInOrderEs } from '@modules/kyc/hooks/useKycGateProgression';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import { kycCategoryStyles } from './styles/index.css';

const KycCategory = () => {
  const { oppId } = useAuthStore();
  const { isLoading: isKycLoading } = useKYCData(oppId);
  const match = useMatch('/kyc/:category/:applicantId');
  const applicantId = match?.params.applicantId;
  const category = match?.params.category;

  const translations = new Map([
    ['personal', { title: t('kyc.personalKyc.title'), desc: t('kyc.personalKyc.subtitle') }],
    ['residency', { title: t('kyc.residencyKyc.title'), desc: t('kyc.residencyKyc.subtitle') }],
    ['employment', { title: t('kyc.employmentKyc.title'), desc: t('kyc.employmentKyc.subtitle') }],
    ['finance', { title: t('kyc.financeKyc.title'), desc: t('kyc.financeKyc.subtitle') }]
  ]);

  const { data: vaultProgress, isFetched: isVaultProgressFetched } = useVaultProgress(oppId!);

  const isSpainBusiness = vaultProgress.businessIdentifier === BUSINESS_IDENTIFIER.ES_BAYTECA;
  const kycStepsInOrder = isSpainBusiness ? kycStepsInOrderEs : kycStepsInOrderAe;

  const applicantProgress = vaultProgress.kycProgress.progress_per_applicant[applicantId!]?.progress_per_category;
  const { currentAcitveStepIdx, goToNextStep, canSkipToTheNextStep } = useKycGateProgression(isSpainBusiness ? {
    employment_information: applicantProgress?.employment_information.percentage,
    personal_information: applicantProgress?.personal_information.percentage,
    residential_information: undefined,
  } : {
    employment_information: applicantProgress?.employment_information.percentage,
    personal_information: applicantProgress?.personal_information.percentage,
    residential_information: applicantProgress?.residential_information.percentage,
  }, isVaultProgressFetched, isSpainBusiness);

  const translation = translations.get(category!);

  const steps = useMemo(() => {
    if (isSpainBusiness) {
      return [
        t('kyc.personalInformation'),
        t('kyc.employmentStatus'),
        t('kyc.financeAndMortgage')
      ];
    }

    return [
      t('kyc.personalInformation'),
      t('kyc.residencyStatus'),
      t('kyc.employmentStatus'),
      t('kyc.financeAndMortgage')
    ];
  }, [isSpainBusiness]);

  return (
    <Box w='100%' style={ { paddingBottom: 40 } }>
      <Flex w='100%' align='center' h={ rem(52) } justify='center' className={ kycCategoryStyles.stepperContainer }>
        <Stepper
          active={ isKycLoading ? -1 : currentAcitveStepIdx }
          onStepClick={ (idx) => goToNextStep(idx) }
          classNames={ kycCategoryStyles.stepper }
        >
          {steps.map((step, idx) => (
            <Stepper.Step
              key={ step }
              label={ step }
              data-test={ `step-${step}` }
              styles={ {
                step: {
                  borderBottomColor:
                  !isKycLoading && kycStepsInOrder.indexOf(category! as typeof kycStepsInOrder[number]) === idx
                    ? theme.colors.purple[4] : theme.colors.neutral[1],
                },
              } }
            />
          ))}
        </Stepper>
      </Flex>
      <Flex gap={ theme.spacing.lg } w='100%' pb={ rem(50) }>
        <Card title={ translation?.title } description={ translation?.desc } pos='relative'>
          {isKycLoading || !isVaultProgressFetched ? (<AppSkeleton.AplicantKycForm />) : (
            <Outlet context={ { currentAcitveStepIdx, canSkipToTheNextStep, isSpainBusiness } } />
          )}
        </Card>
        <VaultProgressBar />
      </Flex>
    </Box>
  );
};

export default KycCategory;
