import AppSkeleton from '@modules/core/components/Skeleton';
import { Suspense, lazy } from 'react';
import { useOutletContext } from 'react-router-dom';

const KYCPersonalES = lazy(() => import('./es'));
const KYCPersonalUAE = lazy(() => import('./ae'));

const KYCPersonal = () => {
  const { isSpainBusiness } = useOutletContext<{ isSpainBusiness: boolean }>();

  return (
    <Suspense fallback={ <AppSkeleton.AplicantKycForm /> }>
      {isSpainBusiness ? <KYCPersonalES /> : <KYCPersonalUAE />}
    </Suspense>
  );
};

export default KYCPersonal;
