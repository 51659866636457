import { useTranslation } from 'react-i18next';
import {
  Box, Button, Flex, Text
} from '@huspy/briks-web';
import { FormInput, FormSelectInput } from '@modules/core/components/FormInput';
import { useVaultFormContext } from '@modules/core/pages/Home/v2/form-context';
import useGetBanks from '@modules/kyc/hooks/queries/useGetBanks';
import { useMemo, useState } from 'react';
import { CURRENCY, LIABILITY_TYPE } from '@modules/kyc/api/const';
import { Cancel } from '@huspy/briks-icons';
import { Divider } from '@huspy/styled-system/jsx';
import { formRowStyles } from '../styles.css';
import { newLiability } from './mapper';

const OtherLiability = ({
  formKey,
  isSpainBusiness,
  loanType,
  banks,
}: {
  formKey: string;
  isSpainBusiness: boolean;
  loanType: string;
  banks: {
    label: string;
    value: string;
  }[];
}) => {
  const form = useVaultFormContext();
  const { t } = useTranslation();

  return (
    <>
      <Text size='xl' my='4'>
        {t(`kyc.financeKyc.loan.${loanType}.title`)}
      </Text>
      {!isSpainBusiness && (
        <Flex className={ formRowStyles }>
          <FormSelectInput
            form={ form }
            name={ `${formKey}.${loanType}.description` }
            data={ banks || [] }
            label={ t(`kyc.financeKyc.liability.bank.${loanType}.label`) }
            placeholder={ t(
              `kyc.financeKyc.liability.bank.${loanType}.placeholder`
            ) }
            type='text'
            data-test='select-loan-bank'
          />
          <FormInput
            form={ form }
            name={ `${formKey}.${loanType}.value` }
            label={ t('kyc.financeKyc.liability.total.label') }
            type='number'
            trail={ CURRENCY.AED }
            data-test='input-loan-installment'
          />
        </Flex>
      )}
      <Flex className={ formRowStyles }>
        <FormInput
          form={ form }
          name={ `${formKey}.${loanType}.monthly_cost` }
          label={ t('kyc.financeKyc.liability.installment.label') }
          type='number'
          trail={ isSpainBusiness ? CURRENCY.EUR : CURRENCY.AED }
          data-test='input-loan-installment'
        />
        <FormInput
          form={ form }
          name={ `${formKey}.${loanType}.outstanding_amount` }
          label={ t('kyc.financeKyc.liability.outstanding.label') }
          type='number'
          trail={ isSpainBusiness ? CURRENCY.EUR : CURRENCY.AED }
          data-test='input-loan-installment'
        />
      </Flex>
    </>
  );
};

const CreditCardLiability = ({
  formKey,
  banks,
  hasOnlyOne,
  index,
  removeCreditCard,
}: {
  formKey: string;
  banks: {
    label: string;
    value: string;
  }[];
  hasOnlyOne: boolean;
  index: number,
  removeCreditCard: () => void;
}) => {
  const form = useVaultFormContext();
  const { t } = useTranslation();

  return (
    <Flex className={ formRowStyles } w='100%'>
      <Flex w='100%' gap='6' justify='center' align='center'>
        <FormSelectInput
          form={ form }
          name={ `${formKey}.description` }
          data={ banks || [] }
          label={ t('kyc.financeKyc.liability.creditCard.bank.label') }
          placeholder={ t('kyc.financeKyc.liability.creditCard.bank.placeholder') }
          type='text'
          data-test='select-loan-bank'
        />
        <FormInput
          form={ form }
          name={ `${formKey}.value` }
          label={ t('kyc.financeKyc.liability.creditCard.limit.label') }
          type='number'
          trail={ CURRENCY.AED }
          data-test='input-loan-installment'
        />
        {!hasOnlyOne && (
          <Button
            onClick={ removeCreditCard }
            type='button'
            variant='secondary'
            size='xs'
            borderRadius='full'
            data-test={ `button-remove-credit-card-${index}` }
            position='relative'
            top='2'
            p='0'
            // to be remove
            display='none'
          >
            <Cancel height={ 15 } width={ 15 } />
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

const CreditCards = ({
  formKey,
  banks,
}: {
  formKey: string;
  banks: {
    label: string;
    value: string;
  }[];
}) => {
  const form = useVaultFormContext();
  const { t } = useTranslation();

  const applicantIndex = Number(formKey.split('.')[1]);
  const initialValue = form.getValues().applicants[applicantIndex]?.creditCards!;
  const [creditCards, setCreditCards] = useState(initialValue);

  const addAnotherCreditCard = () => {
    const newCreditCard = newLiability(LIABILITY_TYPE.creditCard);

    setCreditCards([...creditCards, newCreditCard]);

    form.insertListItem(`${formKey}.creditCards`, newCreditCard);
  };

  const removeCreditCard = (index: number) => {
    setCreditCards(creditCards.filter((_, i) => i !== index));
    form.removeListItem(`${formKey}.creditCards`, index);
  };

  return (
    <>
      <Text size='xl' my='4'>
        {t('kyc.financeKyc.loan.creditCard.title')}
      </Text>
      {creditCards.map((income, index) => (
        <Flex
          key={ income.external_id ?? crypto.randomUUID() }
          className={ formRowStyles }
        >
          <CreditCardLiability
            formKey={ `${formKey}.creditCards.${index}` }
            banks={ banks }
            removeCreditCard={ () => removeCreditCard(index) }
            hasOnlyOne={ creditCards.length === 1 }
            index={ index }
          />
        </Flex>
      ))}
      <Flex gap='3' justify='flex-end' mt='3'>
        <Button
          onClick={ addAnotherCreditCard }
          type='button'
          disabled={ creditCards.length >= 4 }
          mt='2'
          variant='secondary'
          data-test='button-add-credit-card'
        >
          Add another credit card
        </Button>
      </Flex>
      <Divider my='10' />
    </>
  );
};

const Liabilities = ({
  formKey,
  isSpainBusiness,
}: {
  formKey: string;
  isSpainBusiness: boolean;
}) => {
  const { data: banksData } = useGetBanks();

  const banks = useMemo(
    () =>
      banksData?.map((bank) => ({
        label: bank.title,
        value: bank.title,
      })),
    [banksData]
  );

  if (!banks) {
    return <></>;
  }

  return (
    <Box bg='base.white' py='2' px='6' borderRadius='4'>
      {!isSpainBusiness && (
        <CreditCards
          formKey={ formKey }
          banks={ banks }
        />
      )}
      <OtherLiability
        loanType='personalLoan'
        isSpainBusiness={ isSpainBusiness }
        formKey={ formKey }
        banks={ banks }
      />
      <Divider my='10' />
      <OtherLiability
        loanType='autoLoan'
        isSpainBusiness={ isSpainBusiness }
        formKey={ formKey }
        banks={ banks }
      />
      <Divider my='10' />
      <OtherLiability
        loanType='mortgage'
        isSpainBusiness={ isSpainBusiness }
        formKey={ formKey }
        banks={ banks }
      />
    </Box>
  );
};

export default Liabilities;
