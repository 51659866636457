import React, { Dispatch, SetStateAction } from 'react';
import { AppShell, Image } from '@mantine/core';
import '@mantine/core/styles/AppShell.css';
import {
  Box, Button, Flex, Text
} from '@huspy/briks-web';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@modules/core/store';
import { resetAmplitudeIdentity } from '@shared/analytics/amplitude';
import { Files, FileText } from '@huspy/briks-icons';
import { useTranslation } from 'react-i18next';
import { VAULT_TAB, VaultTab } from './types';
import {
  contentWrapper,
  actionButtonLarge,
  actionButtonCompact,
} from './styles.css';

interface TabButtonProps {
  isActive: boolean;
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
  dataTest: string;
  description?: string;
}

const NavbarTabButton: React.FC<TabButtonProps> = ({
  isActive,
  onClick,
  icon,
  label,
  dataTest,
  description,
}) => (
  <Button
    variant='outline'
    size='2xl'
    bg={ isActive ? 'indigo.50' : 'unset' }
    borderColor={ isActive ? 'indigo.300' : 'neutral.300' }
    onClick={ onClick }
    data-testid={ dataTest }
    type='submit'
  >
    <Flex
      w='100%'
      align='center'
      justify='start'
      gap='2'
    >
      {icon}
      <Flex direction='column' align='start'>
        <Text
          fontWeight={ isActive ? 'semibold' : 'normal' }
          color={ isActive ? 'indigo.800' : 'unset' }
          letterSpacing='xl'
        >
          {label}
        </Text>
        <Text
          fontWeight='normal'
          size='sm'
          color={ isActive ? 'indigo.800' : 'unset' }
          letterSpacing='xl'
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  </Button>
);

interface MobileTabProps {
  isActive: boolean;
  onClick: () => void;
  label: string;
  dataTest: string;
}

const MobileTab: React.FC<MobileTabProps> = ({
  isActive,
  onClick,
  label,
  dataTest,
}) => (
  <Box
    p='4'
    onClick={ onClick }
    bg={ isActive ? 'white' : 'unset' }
    w='50%'
    borderRadius='2'
    textAlign='center'
    data-testid={ dataTest }
  >
    <Text fontWeight='normal'>{label}</Text>
  </Box>
);

interface HeaderProps {
  onLogout: () => void;
  saveButtonLabel: string;
  logoutLabel: string;
  logo: string;
  oppId?: string;
  isLoading: boolean;
  showSavedProgressToast?: () => void
}

const Header: React.FC<HeaderProps> = ({
  onLogout,
  saveButtonLabel,
  logoutLabel,
  logo,
  oppId,
  isLoading,
  showSavedProgressToast,
}) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    const navigateLink = `/login/${oppId ?? ''}`;
    resetAmplitudeIdentity();
    onLogout();
    navigate(navigateLink);
  };

  return (
    <Flex py='4' px='8' h='100%' justify='space-between' align='center'>
      <Box w='160px' h='50px' overflow='hidden' position='relative'>
        <Image src={ logo } maw='100%' mah='100%' fit='cover' />
      </Box>
      <Flex gap='2'>
        <Button
          variant={ isLoading ? 'secondary' : 'primary' }
          size='md'
          type='submit'
          className={ actionButtonLarge }
          data-testid='saveBtn-desktop'
          loading={ isLoading }
          onClick={ showSavedProgressToast }
        >
          {saveButtonLabel}
        </Button>
        <Button
          variant='secondary'
          size='md'
          type='button'
          onClick={ handleLogout }
          data-testid='logout-btn'
        >
          {logoutLabel}
        </Button>
      </Flex>
    </Flex>
  );
};

interface MobileTabsProps {
  activeTab: VaultTab;
  onTabChange: (tab: VaultTab) => void;
  formLabel: string;
  documentLabel: string;
}

const MobileTabs: React.FC<MobileTabsProps> = ({
  activeTab,
  onTabChange,
  formLabel,
  documentLabel,
}) => (
  <Box bg='blue.50' p='4' className={ actionButtonCompact }>
    <Flex
      bg='neutral.100'
      borderWidth='2'
      width='100%'
      borderRadius='2'
      justify='space-between'
    >
      <MobileTab
        isActive={ activeTab === VAULT_TAB.FORMS }
        onClick={ () => onTabChange(VAULT_TAB.FORMS) }
        label={ formLabel }
        dataTest='formBtn-mobile'
      />
      <MobileTab
        isActive={ activeTab === VAULT_TAB.DOCUMENTS }
        onClick={ () => onTabChange(VAULT_TAB.DOCUMENTS) }
        label={ documentLabel }
        dataTest='documentBtn-mobile'
      />
    </Flex>
  </Box>
);

interface VaultAppShellProps {
  activeVaultTab: VaultTab;
  setActiveVaultTab: Dispatch<SetStateAction<VaultTab>>;
  isLoading: boolean;
  logo: string;
  children: React.ReactNode;
  formDescription: string;
  showSavedProgressToast?: () => void
}

export const VaultAppShell: React.FC<VaultAppShellProps> = ({
  activeVaultTab,
  setActiveVaultTab,
  logo,
  isLoading,
  children,
  formDescription,
  showSavedProgressToast,
}) => {
  const { logOut, oppId } = useAuthStore();
  const { t } = useTranslation();

  return (
    <AppShell
      header={ { height: 84 } }
      navbar={ {
        width: { base: 300, md: 300, lg: 367 },
        breakpoint: '993',
        collapsed: { mobile: true },
      } }
    >
      <AppShell.Header>
        <Header
          onLogout={ logOut }
          saveButtonLabel={ t('home.header.saveBtn') }
          logoutLabel={ t('home.header.logout') }
          logo={ logo }
          oppId={ oppId! }
          isLoading={ isLoading }
          showSavedProgressToast={ showSavedProgressToast }
        />
        <MobileTabs
          activeTab={ activeVaultTab }
          onTabChange={ setActiveVaultTab }
          formLabel={ t('home.navbar.formBtn') }
          documentLabel={ t('home.navbar.documentBtn') }
        />
      </AppShell.Header>
      <AppShell.Navbar>
        <Flex p='32px 24px 0 48px' direction='column' gap='10'>
          <Flex direction='column' gap='1'>
            <Text letterSpacing='xl'>{t('home.navbar.title')}</Text>
            <Text size='md' color='neutral.500' letterSpacing='xl'>
              {t('home.navbar.subtitle')}
            </Text>
          </Flex>
          <Flex direction='column' gap='4'>
            <NavbarTabButton
              isActive={ activeVaultTab === VAULT_TAB.FORMS }
              onClick={ () => setActiveVaultTab(VAULT_TAB.FORMS) }
              icon={ (
                <FileText
                  color={ activeVaultTab === VAULT_TAB.FORMS ? '#3730A3' : 'unset' }
                />
              ) }
              label={ t('home.navbar.formBtn') }
              dataTest='formBtn-desktop'
              description={ formDescription }
            />
            <NavbarTabButton
              isActive={ activeVaultTab === VAULT_TAB.DOCUMENTS }
              onClick={ () => setActiveVaultTab(VAULT_TAB.DOCUMENTS) }
              icon={ (
                <Files
                  color={ activeVaultTab === VAULT_TAB.DOCUMENTS ? '#3730A3' : 'unset' }
                />
              ) }
              label={ t('home.navbar.documentBtn') }
              dataTest='documentBtn-desktop'
            />
          </Flex>
        </Flex>
      </AppShell.Navbar>
      <AppShell.Main bg='#F9FAFB'>
        <Box className={ contentWrapper }>{children}</Box>
      </AppShell.Main>
      <AppShell.Footer>
        <Box p='4' className={ actionButtonCompact }>
          <Button
            variant={ isLoading ? 'secondary' : 'primary' }
            size='md'
            type='submit'
            w='100%'
            data-testid='saveBtn-mobile'
            loading={ isLoading }
          >
            {t('home.header.saveBtn')}
          </Button>
        </Box>
      </AppShell.Footer>
    </AppShell>
  );
};
