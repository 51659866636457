import { Select } from '@huspy/forge';
import i18n, { t, changeLanguage } from 'i18next';
import { useLanguageStore } from '@modules/core/store';

const LANGUAGES = [
  { label: t('common.languages.english'), value: 'en' },
  { label: t('common.languages.spanish'), value: 'es' }
];

const LanguageSwitcher = () => {
  const { setLanguage, lng } = useLanguageStore();

  const handleOnLanguageChange = (language: string) => {
    changeLanguage(language);
    setLanguage(language);
  };

  return (
    <Select
      w={ 180 }
      value={ lng ?? i18n.language }
      onChange={ handleOnLanguageChange }
      data={ LANGUAGES }
    />
  );
};

export default LanguageSwitcher;
