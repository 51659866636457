import { useEffect } from 'react';
import { useAuthStore } from '../store';

const usePersistAuth = () => {
  const {
    setToken, setOpportunityId, getOppIdFromLocalStorage, getTokenFromSessionStorage,
  } = useAuthStore();
  const tokenFromSessionStorage = getTokenFromSessionStorage();
  const oppIdFromSessionStorage = getOppIdFromLocalStorage();

  useEffect(() => {
    if (tokenFromSessionStorage) {
      setToken(tokenFromSessionStorage);
    }
    if (oppIdFromSessionStorage) {
      setOpportunityId(oppIdFromSessionStorage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { tokenFromSessionStorage };
};

export default usePersistAuth;
