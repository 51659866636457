import { ApplicantType } from '@modules/documents/api/types';
import ApplicantInfo from '@modules/core/components/ApplicantInfo';
import { Flex } from '@huspy/briks-web';

type Props = {
  applicantName: string;
  applicantType: ApplicantType;
  isSelected: boolean;
  onClick: () => void;
};

const ApplicantProgress = ({
  applicantName = '',
  applicantType,
  isSelected,
  onClick,
}: Props) => (
  <Flex
    justify='space-between'
    background='base.white'
    w='100%'
    borderRadius='3'
    shadow='shadow-3'
    p='4'
    cursor='pointer'
    border={ isSelected ? '1px solid #7150EA' : 'unset' }
    onClick={ onClick }
  >
    <ApplicantInfo name={ applicantName } applicantType={ applicantType } />
  </Flex>
);

export default ApplicantProgress;
