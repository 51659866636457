import { Text, theme } from '@huspy/forge';
import {
  Box, Flex, ActionIcon, Image
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { iconsPath } from '@shared/css.const';
import { securityDisclaimerStyles } from './styles/index.css';

const SecurityDisclaimer = () => {
  const { t } = useTranslation();
  return (
    <Box mt={ theme.spacing['3xl'] }>
      <Flex
        className={ securityDisclaimerStyles.root }
      >
        <ActionIcon
          disabled
          variant='white'
          styles={ {
            root: {
              boxShadow: theme.shadows[3], borderRadius: '8px', cursor: 'inherit', backgroundColor: '#fff',
            },
          } }
        >
          <Image src={ `${iconsPath}/black-lock.svg` } w={ 16 } h={ 16 } />
        </ActionIcon>
        <Text
          ml={ theme.spacing.lg }
          size='xs'
          styles={ { root: { lineHeight: '120%' } } }
        >
          { t('home.documentsOverview.securityDisclaimer') }
        </Text>
      </Flex>
    </Box>
  );
};

export default SecurityDisclaimer;
