import { Flex, Text } from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';
import { ApplicantType } from '@modules/documents/api/types';
import { headerStyles, headerTextStyles } from './styles.css';

const mapApplicantType = (applicantType: ApplicantType) => {
  if (applicantType === 'co-applicant') {
    return 'coApplicant';
  }
  if (applicantType === 'co-borrower') {
    return 'coBorrower';
  }
  return applicantType;
};

export const Header = ({
  firstName,
  lastName,
  type,
}: {
  firstName: string;
  lastName: string;
  type: ApplicantType;
}) => {
  const { t } = useTranslation();
  const fullName = `${firstName} ${lastName}`;
  const applicantType = t(`common.applicantType.${mapApplicantType(type)}`);

  return (
    <Flex
      className={ headerStyles }
      gap='2'
      align='center'
      bg='blue.50'
      my='3'
    >
      <Text
        className={ headerTextStyles }
        letterSpacing='xl'
      >
        {fullName}
      </Text>
      <Text size='6xl' color='neutral.300'>
        &middot;
      </Text>
      <Text
        className={ headerTextStyles }
        color='neutral.600'
        letterSpacing='xl'
      >
        {applicantType}
      </Text>
    </Flex>
  );
};
