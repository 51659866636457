import { notifications as d } from "@mantine/notifications";
import { IconAlertCircleFilled as p, IconCircleCheckFilled as y, IconCircleXFilled as g, IconInfoCircleFilled as w } from "@tabler/icons-react";
import i from "react";
import { t as c } from "./vanilla-BVQNXG0s.mjs";
import { T as C } from "./Text-D75zcpkn.mjs";
var k = { root: "_1u5mhob0" };
const b = {
  warning: c.colors.warning[1],
  info: c.colors.positive[1],
  error: c.colors.negative[1],
  neutral: c.colors.neutral[2]
}, E = {
  warning: /* @__PURE__ */ i.createElement(p, null),
  info: /* @__PURE__ */ i.createElement(y, null),
  error: /* @__PURE__ */ i.createElement(g, null),
  neutral: /* @__PURE__ */ i.createElement(
    w,
    {
      color: "black",
      stroke: "black",
      style: { color: "black", stroke: "black", fill: "black" }
    }
  )
}, M = (e, { message: o, duration: t }) => {
  d.show({
    withCloseButton: !1,
    h: "46px",
    autoClose: t ?? 3e3,
    message: /* @__PURE__ */ i.createElement(C, { c: e === "neutral" ? "black" : "neutral.0", size: "md" }, o),
    color: "transparent",
    icon: E[e],
    loading: !1,
    classNames: { root: k.root },
    styles: {
      root: { backgroundColor: b[e], width: "fit-content" },
      description: { color: c.colors.neutral[0] },
      icon: {
        marginLeft: 0,
        marginRight: c.spacing.sm,
        width: "20px",
        height: "20px"
      }
    }
  });
}, $ = (e) => {
  const o = new Date(e), t = (/* @__PURE__ */ new Date()).toISOString().slice(0, -1), n = new Date(t), l = Math.abs(n.getTime() - o.getTime()), r = 5 * 60 * 1e3;
  return l > r;
};
async function f(e, o = 3) {
  try {
    const t = await fetch(e);
    if (!t.ok)
      throw new Error(`Failed to fetch stylesheet: ${t.statusText}`);
    return await t.text();
  } catch (t) {
    return o > 0 ? (console.warn(`Retry fetching stylesheet (${o} attempts left):`, e), f(e, o - 1)) : (console.warn("Could not fetch stylesheet:", e, t), "");
  }
}
function h(e) {
  const o = window.getComputedStyle(e);
  let t = "";
  for (let n = 0; n < o.length; n++) {
    const l = o[n], r = o.getPropertyValue(l);
    r && (t += `${l}: ${r}; `);
  }
  t && e.setAttribute("style", t), e.children.length > 0 && [...e.children].forEach((n) => h(n));
}
async function D(e, o) {
  const t = document.querySelector(`#${e}`);
  if (!t)
    return console.error("No element found with id:", e), null;
  const n = t.cloneNode(!0), l = [], r = [];
  document.querySelectorAll("style").forEach((s) => {
    l.push(s.textContent);
  }), document.querySelectorAll('link[rel="stylesheet"]').forEach(async (s) => {
    const { href: u } = s;
    u && r.push(f(u));
  });
  const m = await Promise.all(r);
  l.push(...m), h(n);
  const a = [];
  return document.querySelectorAll('link[rel="stylesheet"][href*="fonts"]').forEach((s) => {
    a.push(s.outerHTML);
  }), `
      <!DOCTYPE html><html><head><meta charset="UTF-8">${a.join("")}
      <title>${o ?? "Document.pdf"}</title>
      <style>${l.join(" ")}</style></head><body>${n.outerHTML}</body></html>
  `.replaceAll(`
`, "").replaceAll(/\s+/g, " ").trim();
}
export {
  D as c,
  $ as i,
  M as t
};
