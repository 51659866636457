import { Text, theme } from '@huspy/forge';
import { Box, Code } from '@mantine/core';
import Card from '@modules/core/components/Card';
import FloatingContainer from '@modules/core/components/FloatingContainer';
import React from 'react';
import '@mantine/core/styles/Code.css';

export class ErrorBoundary extends React.Component<{
  children: React.ReactNode;
  fallback?: React.ReactNode;
}, {
  hasError: boolean;
  err?: Error;
}> {
  constructor(props: { fallback: React.ReactNode; children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(err: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, err };
  }

  render() {
    const { hasError, err } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <FloatingContainer>
          <Card>
            <Text fw={ 600 } size='lg'>
              Error occured while loading the Vault,
              {' '}
              <br />
              {' '}
              sorry for the inconvenience
            </Text>
            {ENV !== 'production' && (
              <Box mt={ theme.spacing.lg }>
                <Code>
                  {err?.name}
                  {' '}
                  <br />
                  {' '}
                  {err?.message}
                  {' '}
                  <br />
                  {' '}
                  {err?.stack}
                </Code>
              </Box>
            )}
          </Card>
        </FloatingContainer>
      );
    }

    return children;
  }
}
