import { ProgressBar } from '@huspy/forge';
import { cardProgressBarStyles } from './styles/index.css';

const { root } = cardProgressBarStyles;

type Props = {
  progressBarText?: string;
  placeholderValue?: number;
} & React.ComponentProps<typeof ProgressBar>;

const CardProgressBar = ({
  progressBarText, value, placeholderValue, ...rest
}: Props) => (
  <ProgressBar
    classNames={ { root } }
    value={ value || placeholderValue || 2.5 }
    label={ progressBarText }
    { ...rest }
  />
);

export default CardProgressBar;
