import {
  enumSchema,
  numberSchema,
  phoneNumberSchema,
  stringSchema,
  stringSchemaMax,
} from '@shared/schema';
import { z } from 'zod';

const schema = z.object({
  status_of_current_residence: enumSchema(['rented', 'owned']),
  no_of_months_lived_in_current_residence: numberSchema,
  year_moved_to_country: numberSchema,
  address: z.object({
    city: stringSchemaMax(40),
    street: stringSchema,
    state_code: stringSchema,
    country_code: stringSchema,
  }),
  po_box: stringSchema,
  mobile_no_in_home_country: phoneNumberSchema,
  reference: z.object({
    name_of_first_friend_or_relative_in_the_purchasing_country: stringSchema,
    mobile_no_of_first_friend_or_relative_in_the_purchasing_country:
      phoneNumberSchema,
    name_of_second_friend_or_relative_in_the_purchasing_country: stringSchema,
    mobile_no_of_sec_friend_or_relative_in_the_purchasing_country:
      phoneNumberSchema,
    name_of_first_friend_or_relative_in_the_home_country: stringSchema,
    mobile_no_of_first_friend_or_relative_in_home_country: phoneNumberSchema,
    name_of_second_friend_or_relative_in_home_country: stringSchema,
    mobile_no_of_second_friend_or_relative_in_home_country: phoneNumberSchema,
  }),
  address_in_home_country: z.object({
    street: stringSchema,
    city: stringSchemaMax(40),
    postal_code: stringSchemaMax(20),
    country_code: stringSchema,
  }),
});

export default schema;
