import { nationalitiesJSON as nationalities } from '@huspy/forge/lib';

import { useTranslation } from 'react-i18next';
import { MARITAL_STATUS } from '@modules/kyc/api/const';
import {
  Box, Flex, Text
} from '@huspy/briks-web';
import { FormInput, FormSelectInput } from '@modules/core/components/FormInput';
import { useVaultFormContext } from '@modules/core/pages/Home/v2/form-context';
import { formRowStyles } from '../styles.css';

const MARTIAL_STATUS_OPTIONS_AE = [
  { value: MARITAL_STATUS.married },
  { value: MARITAL_STATUS.single },
  { value: MARITAL_STATUS.divorced },
  { value: MARITAL_STATUS.widowed }
];

const MARTIAL_STATUS_OPTIONS_ES = [
  { value: MARITAL_STATUS.married },
  { value: MARITAL_STATUS.marriedWithPrenuptial },
  { value: MARITAL_STATUS.single },
  { value: MARITAL_STATUS.divorced },
  { value: MARITAL_STATUS.widowed }
];
const KYCPersonal = ({
  formKey,
  isSpainBusiness,
}: {
  formKey: string;
  isSpainBusiness: boolean;
}) => {
  const { t } = useTranslation();
  const form = useVaultFormContext();

  const martialWithLabel = (
    isSpainBusiness ? MARTIAL_STATUS_OPTIONS_ES : MARTIAL_STATUS_OPTIONS_AE
  ).map((martialStatus) => ({
    ...martialStatus,
    label: t(`kyc.personalKyc.martial_status.${martialStatus.value}`),
  }));

  return (
    <Box bg='base.white' py='2' px='6' borderRadius='4'>
      <Text size='xl' my='4'>
        {t('kyc.personalKyc.title')}
      </Text>
      <Flex className={ formRowStyles }>
        <FormInput
          form={ form }
          name={ `${formKey}.personal_information.first_name` }
          label={ t('common.forms.firstName.label') }
          placeholder={ t('common.forms.firstName.placeholder') }
          type='text'
          data-test='input-firstname'
        />
        <FormInput
          form={ form }
          name={ `${formKey}.personal_information.last_name` }
          label={ t('common.forms.lastName.label') }
          placeholder={ t('common.forms.lastName.placeholder') }
          type='text'
          data-test='input-lastname'
        />
      </Flex>
      <Flex className={ formRowStyles }>
        <FormInput
          form={ form }
          name={ `${formKey}.personal_information.email` }
          label={ t('common.forms.email.label') }
          placeholder={ t('common.forms.email.placeholder') }
          data-test='input-email'
        />
        <FormInput
          form={ form }
          name={ `${formKey}.personal_information.mobile` }
          label={ t('common.forms.mobile.label') }
          placeholder={ t('common.forms.mobile.placeholder') }
          type='phone'
          data-test='input-mobile'
        />
      </Flex>
      <Flex className={ formRowStyles }>
        <FormSelectInput
          form={ form }
          name={ `${formKey}.personal_information.nationality` }
          label={ t('common.forms.whereAreYouFrom.label') }
          data={ nationalities.map((c: { label: string; value: string }) => ({
            label: c.label,
            value: c.label,
          })) }
          placeholder={ t('common.forms.whereAreYouFrom.placeholder') }
          data-test='select-nationality'
        />
        {isSpainBusiness ? (
          <FormInput
            form={ form }
            name={ `${formKey}.personal_information.dob` }
            label={ t('common.forms.dob.label') }
            placeholder={ t('common.forms.dob.placeholder') }
            type='date'
            data-test='datapicker-dob'
            { ...form.getInputProps('') }
          />
        ) : (
          <FormInput
            form={ form }
            name={ `${formKey}.personal_information.mother_maiden_name` }
            label={ t('common.forms.motherMaidenName.label') }
            placeholder={ t('common.forms.motherMaidenName.placeholder') }
            type='text'
            data-test='input-mother-maiden-name'
          />
        )}
      </Flex>
      <Flex className={ formRowStyles }>
        <FormSelectInput
          form={ form }
          name={ `${formKey}.personal_information.civil_status` }
          label={ t('common.forms.maritalStatus.label') }
          data={ martialWithLabel }
          placeholder={ t('common.forms.maritalStatus.placeholder') }
          data-test='select-marital-status'
        />
        <FormInput
          form={ form }
          name={ `${formKey}.personal_information.no_of_dependents` }
          label={ t('common.forms.noOfDependents.label') }
          placeholder={ t('common.forms.noOfDependents.label') }
          type='number'
          data-test='input-no-of-dependents'
        />
      </Flex>
    </Box>
  );
};

export default KYCPersonal;
