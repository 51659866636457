import { Container, ContainerProps } from '@mantine/core';
import clsx from 'clsx';
import { Text, theme } from '@huspy/forge';
import { useMediaQuery } from '@mantine/hooks';
import { minMd } from '@shared/css.const';
import { cardStyles } from './styles/index.css';

type Props = ContainerProps & {
  children: React.ReactNode;
  className?: string;
  title?: string;
  description?: string;
};

const Card = ({
  children, className, title, description, ...rest
}: Props) => {
  const isDesktop = useMediaQuery(minMd);
  return (
    <Container className={ clsx(cardStyles.root, className) } w='100%' { ...rest }>
      {title && (
        <Container
          w='100%'
          className={ cardStyles.header }
        >
          <Container w='100%' className={ cardStyles.containerPadding }>
            <Text size={ isDesktop ? 'xl' : 'lg' } fw={ 600 }>{ title }</Text>
            { description && <Text size='sm' c='neutral.7' mt={ theme.spacing.xs }>{ description }</Text> }
          </Container>
        </Container>
      )}
      <Container w='100%' className={ cardStyles.containerPadding }>
        { children }
      </Container>
    </Container>
  );
};

export default Card;
