export const APPLICANT_TYPE = {
  coBorrower: 'co-borrower',
  coApplicant: 'co-applicant',
  mainApplicant: 'main',
} as const;

export const DOCUMENT_STATUS = {
  requested: 'requested',
  valid: 'valid',
  invalid: 'invalid',
  checking_quality: 'checking_quality',
  categorizing: 'categorizing',
  extracting_data: 'extracting_data',
} as const;

export const DOCUMENT_CATEGORY = {
  financial: 'financial',
  personal: 'personal',
  work: 'work',
  additional: 'additional',
  uncategorized: 'uncategorized',
} as const;

export const DOCUMENT_TYPES = {
  emiratesId: 'Emirates ID',
  dni: 'DNI por ambos lados',
  tasacion: 'Tasación',
  prestamos: '3 últimos recibos de préstamos',
} as const;

export const TWO_SIDES_DOCUMENT = ['Emirates ID', 'Labour card', 'Labour contract'] as const;
