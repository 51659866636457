import { Text, theme } from '@huspy/forge';
import {
  Box, Flex, Image, Skeleton, Stack, rem
} from '@mantine/core';
import { ApplicantType } from '@modules/documents/api/types';
import { iconsPath } from '@shared/css.const';
import { APPLICANT_TYPE } from '@modules/documents/api/const';
import clsx from 'clsx';
import { t } from 'i18next';
import { applicantInfoStyles } from './styles/index.css';

type Props = {
  applicantType: ApplicantType;
  name: string;
  applyResponsiveStyles?: boolean;
};

const {
  wrapper, nameBox, responsiveNameBox, nameText,
} = applicantInfoStyles;

const getApplicantTypeName = (type: ApplicantType) => {
  switch (type) {
    case APPLICANT_TYPE.coBorrower: { return t('common.applicantType.coBorrower');
    }
    case APPLICANT_TYPE.coApplicant: { return t('common.applicantType.coApplicant');
    }
    default: { return t('common.applicantType.main');
    }
  }
};

const ApplicantInfo = ({ applicantType, name, applyResponsiveStyles = true }: Props) => (
  <Flex className={ applyResponsiveStyles ? wrapper : '' }>
    <Flex align='center' justify='center'>
      <Image
        h={ 24 }
        w={ 24 }
        src={ `${iconsPath}/${applicantType === APPLICANT_TYPE.mainApplicant
          ? 'with-main-applicant.svg' : 'with-co-applicant.svg'}` }
      />
    </Flex>
    <Box className={ applyResponsiveStyles ? clsx(nameBox, responsiveNameBox) : nameBox }>
      <Text
        size='sm'
        fw={ 600 }
        styles={ {
          root: {
            textTransform: 'uppercase',
            color: theme.colors.purple[4],
            fontFeatureSettings: "'clig' off, 'liga' off",
            lineHeight: '130%',
          },
        } }
      >
        {
          getApplicantTypeName(applicantType)
        }
      </Text>
      <Text
        size='sm'
        classNames={ { root: nameText } }
      >
        { name }
      </Text>
    </Box>
  </Flex>
);

// eslint-disable-next-line react/display-name
ApplicantInfo.Skeleton = ({ showGenericVersion }: { showGenericVersion?: boolean }) => (
  <>
    <Flex { ...(showGenericVersion ? {} : { hiddenFrom: 'md' }) } align='center'>
      <Skeleton height={ rem(24) } w={ rem(24) } circle />
      <Stack gap={ rem(6) } ml={ theme.spacing.xs }>
        <Skeleton height={ rem(18) } w={ rem(118) } radius='12px' />
        <Skeleton height={ rem(18) } w={ rem(118) } radius='12px' />
      </Stack>
    </Flex>
    <Stack gap={ rem(6) } { ...(showGenericVersion ? { display: 'none' } : { visibleFrom: 'md' }) }>
      <Skeleton height={ rem(24) } w={ rem(24) } circle />
      <Skeleton height={ rem(18) } w={ rem(118) } radius='12px' />
      <Skeleton height={ rem(18) } w={ rem(118) } radius='12px' />
    </Stack>
  </>
);

export default ApplicantInfo;
