import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { documentsApi } from '@modules/documents/api';
import {
  DocumentCategory,
  isRequiredDocument,
  RequiredDocuments,
  Vault,
} from '@modules/documents/api/types';

const removeDeletedFile = (
  requiredDocument: RequiredDocuments,
  opportunityApplicantDocumentId: string
) => {
  const documentCategories = Object.keys(
    requiredDocument
  ) as DocumentCategory[];
  return documentCategories.reduce(
    (acc: RequiredDocuments, next) => {
      if (next === 'uncategorized') {
        return {
          ...acc,
          [next]: requiredDocument[next].filter(
            (item) =>
              item.opportunity_applicant_document_id
              !== opportunityApplicantDocumentId
          ),
        };
      }
      return {
        ...acc,
        [next]: requiredDocument[next].map((category) => {
          if (isRequiredDocument(category)) {
            return {
              ...category,
              documents: category.documents.filter(
                (item) =>
                  item.opportunity_applicant_document_id
                  !== opportunityApplicantDocumentId
              ),
            };
          }
          return category;
        }),
      };
    },
    {
      financial: [],
      personal: [],
      work: [],
      additional: [],
      uncategorized: [],
    }
  );
};
const useDeleteDocument = (opportunityId: string, applicantId?: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['deleteDocument', opportunityId, applicantId],
    mutationFn: (opportunityApplicantDocumentId: string) =>
      documentsApi.deleteDocument(
        opportunityId,
        opportunityApplicantDocumentId
      ),
    onSuccess: (_, opportunityApplicantDocumentId) => {
      queryClient.refetchQueries({ queryKey: ['vault', opportunityId] });
      queryClient.refetchQueries({ queryKey: ['vaultProgress', opportunityId] });
      queryClient.setQueryData<Vault>(['vault', opportunityId], (old) => {
        if (!old) return old;
        const newApplicants = old.applicants!.map((applicant) => {
          if (applicant.applicant_id === applicantId) {
            const newRequiredDocuments = removeDeletedFile(
              applicant.required_documents,
              opportunityApplicantDocumentId
            );
            return {
              ...applicant,
              required_documents: newRequiredDocuments,
            };
          }
          return applicant;
        });
        return {
          ...old,
          applicants: newApplicants,
        };
      });
      toast('info', { message: t('documents.notifications.documentDeleted') });
    },
    onError: () => {
      toast('error', { message: t('documents.notifications.documentDeleteFailed') });
    },
  });

  return {
    mutate,
    error,
    isPending,
    mutateAsync,
  };
};

export default useDeleteDocument;
