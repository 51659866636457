import { countriesJSON } from '@huspy/forge/lib';
import { EMPLOYMENT_STATUS } from '@modules/kyc/api/const';
import {
  emailSchema,
  enumSchema,
  numberSchema,
  phoneNumberSchema,
  stringSchema,
  stringSchemaMax,
  urlSchema,
} from '@shared/schema';
import { z } from 'zod';

const COUNTRIES = countriesJSON.map(
  ({ name, alpha2 }: { name: string; alpha2: string }) => ({
    label: name,
    value: alpha2,
  })
);
const countryCodeSchema = enumSchema(
  COUNTRIES.map((item: { value: string }) => item.value)
);

const employmentTypeSchema = enumSchema([
  EMPLOYMENT_STATUS.salaried,
  EMPLOYMENT_STATUS.selfEmployed,
  EMPLOYMENT_STATUS.unemployed,
  EMPLOYMENT_STATUS.soleProprietorship,
  EMPLOYMENT_STATUS.government,
  EMPLOYMENT_STATUS.temporary,
  EMPLOYMENT_STATUS.other
]);

export const employmentSchemaAe = z.object({
  employment_type: employmentTypeSchema,
  company_name: stringSchema,
  industry: stringSchema,
  job_title: stringSchema,
  company_personal_email: emailSchema,
  company_address: z.object({
    city: stringSchemaMax(40),
    street: stringSchema,
    country_code: countryCodeSchema,
    postal_code: stringSchemaMax(20),
  }),
  company_contact_no: phoneNumberSchema,
  company_hr_email: emailSchema,
  company_website: urlSchema,
  no_of_employees: numberSchema,
  start_date: stringSchema,
  no_of_annual_payments: numberSchema,
});
