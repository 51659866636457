import { INCOME_TYPE } from '@modules/kyc/api/const';
import {
  enumSchema, numberSchema, stringSchema
} from '@shared/schema';
import { z } from 'zod';
import { employmentSchemaAe } from './ae/schema';
import { employmentSchemaEs } from './es/schema';

const IncomeTypeSchema = enumSchema([
  INCOME_TYPE.allowance,
  INCOME_TYPE.salary,
  INCOME_TYPE.wage,
  INCOME_TYPE.bonus,
  INCOME_TYPE.commision,
  INCOME_TYPE.dividends,
  INCOME_TYPE.rental,
  INCOME_TYPE.other
]);

const incomeSchema = z.object({
  external_id: stringSchema,
  type: IncomeTypeSchema.optional().nullable(),
  amount: numberSchema,
  frequency: stringSchema,
});

export const incomesSchema = z.array(incomeSchema);

export const getEmploymentSchema = (isSpainBusiness: boolean) =>
  (isSpainBusiness ? employmentSchemaEs : employmentSchemaAe);
