import useVaultData from '@modules/documents/hooks/queries/useVaultData';
import { theme } from '@huspy/forge';
import { useAuthStore } from '@modules/core/store';
import FloatingContainer from '@modules/core/components/FloatingContainer';
import VaultProgressBar from '@modules/core/components/VaultProgressBar';
import AppSkeleton from '@modules/core/components/Skeleton';
import { Box, Flex } from '@huspy/briks-web';
import { useState } from 'react';
import { ApplicantType } from '@modules/documents/api/types';
import { APPLICANT_TYPE } from '@modules/documents/api/const';
import ApplicantProgress from './components/ApplicantProgress';
import { BulkUploadWrapper } from './components/ApplicantProgress/BulkUploadWrapper';

const Documents = () => {
  const { oppId } = useAuthStore();
  const { data, isLoading } = useVaultData(oppId);
  const { applicants } = data;
  const [activeApplicantType, setActiveApplicantType] = useState<ApplicantType>(
    APPLICANT_TYPE.mainApplicant
  );

  const activeApplicant = applicants.find((item) => item.applicant_type === activeApplicantType);

  if (!activeApplicant || !oppId) {
    return <></>;
  }

  if (isLoading || applicants.length === 0) {
    return (
      <Box mt='2'>
        <AppSkeleton.ApplicantDocuments />
      </Box>
    );
  }

  return (
    <FloatingContainer display='flex' style={ { gap: theme.spacing.lg } }>
      <Flex direction='column' w='100%'>
        <Flex justify='space-between' gap='4'>
          {applicants?.map((applicant) => (
            <ApplicantProgress
              key={ applicant.applicant_id }
              applicantName={ `${applicant.first_name} ${applicant.last_name}` }
              applicantType={ applicant.applicant_type }
              isSelected={ activeApplicantType === applicant.applicant_type }
              onClick={ () => setActiveApplicantType(applicant.applicant_type) }
            />
          ))}
        </Flex>
        <BulkUploadWrapper
          key={ activeApplicant.applicant_id }
          applicantId={ activeApplicant.applicant_id }
          documents={ activeApplicant.required_documents }
          oppId={ oppId }
        />
      </Flex>
      <VaultProgressBar />
    </FloatingContainer>
  );
};

export default Documents;
