import { Flex } from '@huspy/briks-web';
import { Applicant, KYCCountry } from '../api/types';
import { Employment } from './Employment';
import KYCResidency from './Residency';
import { CITIZEN_STATUS } from '../api/const';
import KYCPersonal from './Personal';
import Liabilities from './Liabilities';

export const KnowYourCustomer = ({
  formKey,
  applicant,
  isSpainBusiness,
}: {
  formKey: string;
  applicant: Applicant<KYCCountry>;
  isSpainBusiness: boolean;
}) => {
  const isResident = applicant.personal_information.citizen_status
    !== CITIZEN_STATUS.nonResident;

  return (
    <Flex direction='column' gap='8' mt='24px' position='relative'>
      <KYCPersonal formKey={ formKey } isSpainBusiness={ isSpainBusiness } />
      {!isSpainBusiness && (
        <KYCResidency formKey={ formKey } isResident={ isResident } />
      )}
      <Employment formKey={ formKey } isSpainBusiness={ isSpainBusiness } />
      <Liabilities formKey={ formKey } isSpainBusiness={ isSpainBusiness } />
    </Flex>
  );
};
