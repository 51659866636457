import KYCEmploymentAe from './ae';
import KYCEmploymentEs from './es';

export const Employment = ({
  formKey,
  isSpainBusiness,
}: {
  isSpainBusiness: boolean;
  formKey: string;
}) => {
  if (isSpainBusiness) {
    return <KYCEmploymentEs formKey={ formKey } />;
  }
  return <KYCEmploymentAe formKey={ formKey } />;
};
