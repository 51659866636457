import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { kycApi } from '@modules/kyc/api';
import {
  KYCCountry, KYCData, UpdateKycData
} from '@modules/kyc/api/types';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { VaultOverview } from '@modules/core/api/types';

const useUpdateKYCData = <TCountry extends KYCCountry = 'AE'>(opportunityId: string, applicantId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['updateKyc', opportunityId, applicantId],
    mutationFn: ({ body }: { body: UpdateKycData<TCountry>, metadata: { sectionName: string } }) =>
      kycApi.updateKYCData<TCountry>(opportunityId, applicantId, body),
    onMutate: ({ body: mutateData }) => {
      const previousKycValues = queryClient.getQueryData(['kyc', opportunityId]);
      const previousVaultProgress = queryClient.getQueryData(['vaultProgress', opportunityId]);
      queryClient.setQueryData<VaultOverview>(['vaultProgress', opportunityId], (old) => {
        const optimisticData = { ...old! } as VaultOverview;
        const applicantKycProgress = optimisticData.progress.kyc_progress
          .progress_per_applicant[applicantId]!.progress_per_category;
        if (mutateData.employment_information) {
          applicantKycProgress.employment_information.percentage = 100;
        }
        if (mutateData.personal_information) {
          applicantKycProgress.personal_information.percentage = 100;
        }
        if (mutateData.residential_information) {
          applicantKycProgress.residential_information.percentage = 100;
        }

        return optimisticData;
      });
      queryClient.setQueryData<KYCData<TCountry>>(['kyc', opportunityId], (old) => {
        if (old?.[applicantId]) {
          const optimisticData = { ...old };
          if (mutateData.employment_information) {
            optimisticData[applicantId]!.employment_information = {
              ...optimisticData[applicantId]!.employment_information,
              ...mutateData.employment_information,
            };
          }
          if (mutateData.financial_information) {
            optimisticData[applicantId]!.financial_information = {
              ...optimisticData[applicantId]!.financial_information,
              ...mutateData.financial_information,
            };
          }
          if (mutateData.personal_information) {
            optimisticData[applicantId]!.personal_information = {
              ...optimisticData[applicantId]!.personal_information,
              ...mutateData.personal_information,
            };
          }
          if (mutateData.residential_information) {
            optimisticData[applicantId]!.residential_information = {
              ...optimisticData[applicantId]!.residential_information,
              ...mutateData.residential_information,
            };
          }

          return optimisticData;
        }
      });

      return { previousKycValues, previousVaultProgress };
    },
    onSuccess: (_, { metadata: { sectionName } }) => {
      trackAmplitudeEvent(`KYC ${sectionName} Submitted`);
      queryClient.invalidateQueries({ queryKey: ['kyc', opportunityId] });
      toast('info', { message: t('kyc.notifications.kycUpdated') });
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(['kyc', opportunityId], context?.previousKycValues);
      queryClient.setQueryData(['vaultProgress', opportunityId], context?.previousVaultProgress);
      toast('error', { message: t('kyc.notifications.kycUpdateFailed') });
    },
  });

  return {
    mutate, error, isPending, mutateAsync,
  };
};

export default useUpdateKYCData;
