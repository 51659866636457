import { useQuery } from '@tanstack/react-query';
import { APPLICANT_TYPE } from '@modules/documents/api/const';
import { Brokerage, Vault } from '@modules/documents/api/types';
import { documentsApi } from '@modules/documents/api';
import { useEffect } from 'react';
import { identifyAmplitudeSession } from '@shared/analytics/amplitude';

const recalibrateVaultData = (data: Vault) => {
  const mainApplicant = data?.applicants.find((app) => app.applicant_type === APPLICANT_TYPE.mainApplicant)!;
  return {
    mainApplicant: {
      firstName: mainApplicant.first_name,
      lastName: mainApplicant.last_name,
      email: mainApplicant.email,
    },
    applicants: data.applicants,
    brokerage: data.brokerage,
  };
};

const useVaultData = (opportunityId?: string | null, retry: boolean = true) => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['vault', opportunityId],
    queryFn: ({ queryKey }) => documentsApi.getVault(queryKey[1]!),
    select: (queryData) => recalibrateVaultData(queryData),
    retry,
    enabled: !!opportunityId,
    refetchInterval: !retry ? false : 10000,
    staleTime: !retry ? Number.POSITIVE_INFINITY : 1_800_000,
  });

  useEffect(() => {
    if (data?.mainApplicant && !!opportunityId) {
      identifyAmplitudeSession({
        name: `${data.mainApplicant.firstName} ${data.mainApplicant.lastName}`,
        email: data.mainApplicant.email,
        oppId: opportunityId,
      });
    }
  }, [data?.mainApplicant, opportunityId]);

  return {
    data: data ?? {
      applicants: [],
      mainApplicant: {
        firstName: '',
        lastName: '',
      },
      brokerage: {} as Brokerage,
    },
    isLoading: isLoading || !data,
    error,
    refetch,
    isRefetching,
  };
};

export default useVaultData;
