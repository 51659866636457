import { documentsApi } from '@modules/documents/api';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';

export type RequestGetUploadPresignedUrlsType = {
  opportunityExternalID: string;
  opportunityApplicantExternalID: string;
  filesNames: string[]
};

const useGetUploadPreSignedUrls = (opportunityExternalID: string, opportunityApplicantExternalID: string) => {
  const { t } = useTranslation();
  const {
    error, mutate, mutateAsync, isPending,
  } = useMutation({
    mutationKey: ['RequestGetUploadPresignedUrlsType', opportunityExternalID, opportunityApplicantExternalID],
    mutationFn: (request: RequestGetUploadPresignedUrlsType) =>
      documentsApi.getBulkUploadPresignedUrls(
        request.opportunityExternalID,
        request.opportunityApplicantExternalID,
        request.filesNames
      ),
    onError: (data) => {
      toast('error', { message: data.message || t('documents.notifications.documentUploadFailed') });
    },
  });

  return {
    mutate, mutateAsync, error, isPending,
  };
};

export default useGetUploadPreSignedUrls;
