import { Text, theme } from '@huspy/forge';
import {
  Badge, Flex, rem
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { minMd } from '@shared/css.const';

type Props = {
  text: string;
  actionsLeft?: number;
};

const StatusBadge = ({ text, actionsLeft }: Props) => {
  const isDesktop = useMediaQuery(minMd);
  return (
    <Flex align='center'>
      <Text size={ !isDesktop ? 'md' : 'lg' } fw={ 600 }>{ text }</Text>
      {!!actionsLeft && (
        <Badge
          ml={ rem(7.5) }
          p={ 0 }
          size='xs'
          w={ 16 }
          h={ 16 }
          bg='purple.4'
          styles={ { label: { fontSize: theme.fontSizes.xs } } }
        >
          { actionsLeft }
        </Badge>
      )}
    </Flex>
  );
};

export default StatusBadge;
