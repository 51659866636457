export const EMPLOYMENT_STATUS = {
  selfEmployed: 'self_employed',
  salaried: 'salaried',
  unemployed: 'unemployed',
  soleProprietorship: 'sole_proprietorship',
  government: 'government',
  temporary: 'temporary',
  other: 'other',
} as const;

export const CITIZEN_STATUS = {
  resident: 'resident',
  national: 'national',
  nonResident: 'non_resident',
} as const;

export const LIABILITY_TYPE = {
  creditCard: 'credit_card',
  autoLoan: 'auto_loan',
  mortgage: 'mortgage',
  personalLoan: 'personal_loan',
  childSupport: 'child_support',
  rental: 'rental',
  other: 'other',
} as const;

export const INCOME_TYPE = {
  rental: 'rental',
  bonus: 'bonus',
  commision: 'commission',
  allowance: 'allowance',
  other: 'other',
  salary: 'salary',
  dividends: 'dividends',
  wage: 'wage',
} as const;

export const INCOME_ENUM = [
  INCOME_TYPE.allowance,
  INCOME_TYPE.bonus,
  INCOME_TYPE.commision,
  INCOME_TYPE.other,
  INCOME_TYPE.rental,
  INCOME_TYPE.salary
] as const;

export const MARITAL_STATUS = {
  married: 'married',
  single: 'single',
  divorced: 'divorced',
  widowed: 'widowed',
  marriedWithPrenuptial: 'married_with_prenuptial',
} as const;

export const INCOME_FREQUENCY = {
  yearly: 'yearly',
  monthly: 'monthly',
} as const;

export const CURRENCY = {
  EUR: '€',
  AED: 'AED',
};
