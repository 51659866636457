import { ChipProps, Chip as MantineChip } from '@mantine/core';
import { chipStyles } from './styles/index.css';

const { checkIcon, label, root } = chipStyles;
const Chip = ({ children, ...props }: ChipProps) => (
  <MantineChip
    classNames={ {
      checkIcon,
      iconWrapper: checkIcon,
      label,
      root,

    } }
    { ...props }
  >
    {children}
  </MantineChip>
);

Chip.Group = MantineChip.Group;

export default Chip;
