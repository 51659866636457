import { KYCCountry, PersonalKYCData } from '@modules/kyc/api/types';

export const getPersonalInitialValues = <TCountry extends KYCCountry>(
  data: PersonalKYCData<TCountry>
): PersonalKYCData<TCountry> => {
  const baseFields = {
    first_name: data.first_name ?? '',
    last_name: data.last_name ?? '',
    email: data.email ?? '',
    nationality: data.nationality ?? '',
    mobile: data.mobile ?? '',
    mother_maiden_name: data.mother_maiden_name ?? null,
    civil_status: data.civil_status ?? null,
    no_of_dependents: data.no_of_dependents ?? null,
    citizen_status: data.citizen_status ?? null,
  };

  // Handle country-specific additional fields
  const additionalFields = (data as PersonalKYCData<'ES'>).dob !== undefined
    ? { dob: (data as PersonalKYCData<'ES'>).dob ?? null }
    : {};

  return {
    ...baseFields,
    ...additionalFields,
  } as PersonalKYCData<TCountry>;
};
