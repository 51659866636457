import b from "react";
import { polymorphicFactory as g, useProps as O, Text as y } from "@mantine/core";
import P from "clsx";
function _(r, t) {
  if (typeof r != "object" || r === null)
    return r;
  var a = r[Symbol.toPrimitive];
  if (a !== void 0) {
    var e = a.call(r, t || "default");
    if (typeof e != "object")
      return e;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (t === "string" ? String : Number)(r);
}
function j(r) {
  var t = _(r, "string");
  return typeof t == "symbol" ? t : String(t);
}
function h(r, t, a) {
  return t = j(t), t in r ? Object.defineProperty(r, t, {
    value: a,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : r[t] = a, r;
}
function i(r, t) {
  var a = Object.keys(r);
  if (Object.getOwnPropertySymbols) {
    var e = Object.getOwnPropertySymbols(r);
    t && (e = e.filter(function(n) {
      return Object.getOwnPropertyDescriptor(r, n).enumerable;
    })), a.push.apply(a, e);
  }
  return a;
}
function o(r) {
  for (var t = 1; t < arguments.length; t++) {
    var a = arguments[t] != null ? arguments[t] : {};
    t % 2 ? i(Object(a), !0).forEach(function(e) {
      h(r, e, a[e]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(r, Object.getOwnPropertyDescriptors(a)) : i(Object(a)).forEach(function(e) {
      Object.defineProperty(r, e, Object.getOwnPropertyDescriptor(a, e));
    });
  }
  return r;
}
function f(r, t) {
  var a = {};
  for (var e in r)
    a[e] = t(r[e], e);
  return a;
}
var w = (r, t, a) => {
  for (var e of Object.keys(r)) {
    var n;
    if (r[e] !== ((n = t[e]) !== null && n !== void 0 ? n : a[e]))
      return !1;
  }
  return !0;
}, v = (r) => {
  var t = (a) => {
    var e = r.defaultClassName, n = o(o({}, r.defaultVariants), a);
    for (var s in n) {
      var m, l = (m = n[s]) !== null && m !== void 0 ? m : r.defaultVariants[s];
      if (l != null) {
        var u = l;
        typeof u == "boolean" && (u = u === !0 ? "true" : "false");
        var p = (
          // @ts-expect-error
          r.variantClassNames[s][u]
        );
        p && (e += " " + p);
      }
    }
    for (var [c, d] of r.compoundVariants)
      w(c, n, r.defaultVariants) && (e += " " + d);
    return e;
  };
  return t.variants = () => Object.keys(r.variantClassNames), t.classNames = {
    get base() {
      return r.defaultClassName.split(" ")[0];
    },
    get variants() {
      return f(r.variantClassNames, (a) => f(a, (e) => e.split(" ")[0]));
    }
  }, t;
}, x = v({ defaultClassName: "_1nmprgt8", variantClassNames: { size: { xl: "_1nmprgt9", lg: "_1nmprgta", md: "_1nmprgtb", sm: "_1nmprgtc", xs: "_1nmprgtd" } }, defaultVariants: {}, compoundVariants: [] }), T = v({ defaultClassName: "_1nmprgt0", variantClassNames: { size: { h1: "_1nmprgt1", h2: "_1nmprgt2", h3: "_1nmprgt3", h4: "_1nmprgt4", h5: "_1nmprgt5", xl: "_1nmprgt6", md: "_1nmprgt7" } }, defaultVariants: {}, compoundVariants: [] });
const S = g((r, t) => {
  const a = O("Text", {}, r), { size: e = "md", className: n, ...s } = a;
  return /* @__PURE__ */ b.createElement(y, { className: P(x({ size: e }), n), ...s });
});
export {
  S as T,
  v as c,
  T as t
};
