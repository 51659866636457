import { c as O, t as $ } from "./utils-BFJVqB2O.mjs";
import { z as s } from "zod";
import { t as u } from "i18next";
import "@mantine/notifications";
import "@tabler/icons-react";
import "react";
import "./vanilla-BVQNXG0s.mjs";
import "@mantine/vanilla-extract";
import "@mantine/core";
import "./Text-D75zcpkn.mjs";
import "clsx";
class D {
  constructor(e) {
    this.fetcher = e;
  }
  async get(e, r, a) {
    let t = e;
    if (r) {
      const o = new URLSearchParams();
      Object.keys(r).forEach((d) => {
        const l = r[d];
        Array.isArray(l) ? l.forEach((m) => {
          m != null && o.append(d, m);
        }) : l != null && o.append(d, l);
      }), t = `${e}?${o.toString()}`;
    }
    return this.fetcher.request(t, "GET", null, a);
  }
  async post(e, r, a, t, o) {
    return this.fetcher.request(e, "POST", r, a, t, o);
  }
  async update(e, r, a, t) {
    return this.fetcher.request(e, "PUT", r, a, t);
  }
  async delete(e, r, a, t) {
    return this.fetcher.request(e, "DELETE", null, a, t);
  }
  async patch(e, r, a, t) {
    return this.fetcher.request(e, "PATCH", r, a, t);
  }
}
class H {
}
const i = u("common.validation.requiredField"), c = u("common.validation.invalidValue"), p = u("common.validation.invalidEmail"), f = s.string({
  errorMap: (n, e) => e.data === null ? { message: i } : { message: e.defaultError }
}).min(1, { message: i }).max(100), g = s.string({ required_error: i }).email({ message: p }).min(1, { message: i }).max(100), h = s.number({
  errorMap: (n, e) => !e.data || e.data === "" ? { message: i } : { message: e.defaultError }
}).nonnegative(), E = s.coerce.number({
  errorMap: (n, e) => !e.data || e.data === "" ? { message: i } : { message: e.defaultError }
}).nonnegative(), v = s.date({ required_error: i }).min(new Date(1850, 1, 1)).max(/* @__PURE__ */ new Date()), F = (n) => s.string().refine((e) => n.includes(e), { message: c }), L = {
  textFieldValidator: f,
  emailFieldValidator: g,
  numberFieldValidator: h,
  numberCoerceFieldValidator: E,
  dateFieldValidator: v,
  enumFieldValidator: F
};
export {
  H as Fetcher,
  D as HttpClient,
  O as capturePdfFrameIntoHTML,
  $ as toast,
  L as validators
};
