import usePersistAuth from '@modules/core/hooks/usePersistAuth';
import {
  Box, Flex, rem
} from '@mantine/core';
import {
  Text, Title, theme
} from '@huspy/forge';
import { useTranslation } from 'react-i18next';
import { Navigate, useMatch } from 'react-router-dom';
import { useAuthStore } from '@modules/core/store';
import { useEffect } from 'react';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { useHandleLanguage } from '@modules/core/hooks/useHandleLanguage';
import { authPageStyles } from './styles/index.css';
import Login from './widgets/Login';

const {
  desktopAuthenticationLeftStyles,
  desktopAuthenticationRightStyles,
  desktopWrapperAuthStyles,
} = authPageStyles;

const Authentication = () => {
  const { token, oppId } = useAuthStore();
  const { tokenFromSessionStorage } = usePersistAuth();
  const { t } = useTranslation();
  const match = useMatch('/login/:oppId?');
  useHandleLanguage();

  useEffect(() => {
    if (match?.params.oppId && !token) {
      trackAmplitudeEvent('ClientVaultAccess', { opportunity_id: match.params.oppId });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.params.oppId]);

  if (tokenFromSessionStorage && token) {
    return <Navigate to='/' />;
  }

  if (!match?.params.oppId && oppId) {
    return <Navigate to={ `/login/${oppId}` } />;
  }

  return (
    <>
      {/* Desktop View */}
      <Flex
        w='100%'
        mih='100%'
        classNames={ { root: desktopWrapperAuthStyles } }
      >
        <Box className={ desktopAuthenticationLeftStyles }>
          <Box p={ `${rem(65)} ${rem(73)}` }>
            <Title size='h1' c='neutral.0'>
              { t('login.title') }
            </Title>
            <Text
              size='lg'
              c='neutral.0'
              mt={ theme.spacing.sm }
              dangerouslySetInnerHTML={ { __html: t('login.subtitle') } }
            />
          </Box>
        </Box>
        <Flex w='100%' className={ desktopAuthenticationRightStyles } align='center' px={ rem(32) }>
          <Login isDesktopView />
        </Flex>
      </Flex>
      {/* Mobile View */}
      <Flex
        className={ authPageStyles.mobileAuthenticationStyles }
        direction='column'
        justify='space-between'
        py={ rem(60) }
      >
        <Box pt={ rem(106) }>
          <Title size='h1' c='neutral.0'>{ t('login.title') }</Title>
          <Text
            size='md'
            c='neutral.0'
            styles={ { root: { maxWidth: '288px', marginTop: rem(7.5) } } }
            dangerouslySetInnerHTML={ { __html: t('login.subtitle') } }
          />
        </Box>
        <Login />
      </Flex>
    </>
  );
};

export default Authentication;
