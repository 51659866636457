import { EmploymentKYCData, FinancialKYCData } from '@modules/kyc/api/types';

const initialCompanyAddress = {
  city: '',
  country_code: '',
  postal_code: '',
  street: '',
};

export const getEmploymentAEInitialValues = (
  employmentData: EmploymentKYCData<'AE'>
) => ({
  company_address: employmentData?.company_address ?? initialCompanyAddress,
  company_contact_no: employmentData?.company_contact_no ?? '',
  company_hr_email: employmentData?.company_hr_email ?? '',
  company_name: employmentData?.company_name ?? '',
  company_personal_email: employmentData?.company_personal_email ?? '',
  company_website: employmentData?.company_website ?? '',
  employment_type: employmentData?.employment_type ?? 'salaried',
  industry: employmentData?.industry ?? '',
  job_title: employmentData?.job_title ?? '',
  no_of_employees: employmentData?.no_of_employees ?? null,
});

export const getIncomesAEInitialValues = (
  incomeData: FinancialKYCData['incomes']
) => (incomeData?.length
  ? incomeData
  : [{
    external_id: null,
    type: null,
    amount: null,
    frequency: 'monthly',
  }]);
