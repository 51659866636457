import { LIABILITY_TYPE } from '@modules/kyc/api/const';
import { Liability, LiabilityType } from '@modules/kyc/api/types';

export const newLiability = (liabilityType: LiabilityType): Liability => ({
  type: liabilityType,
  value: 0,
  description: null,
  outstanding_amount: 0,
  monthly_cost: 0,
  external_id: null,
});

const getLiability = (liabilities: Liability[], liabilityType: LiabilityType) =>
  liabilities.find((liability) => liability.type === liabilityType)
  ?? newLiability(liabilityType);

const getCreditCard = (liabilities: Liability[]) => {
  const creditCards = liabilities.filter(
    (liability) => liability.type === LIABILITY_TYPE.creditCard
  );
  return creditCards?.length
    ? creditCards
    : [newLiability(LIABILITY_TYPE.creditCard)];
};

export const getLiabilitiesInitialValues = (liabilities: Liability[]) => ({
  creditCards: getCreditCard(liabilities),
  autoLoan: getLiability(liabilities, LIABILITY_TYPE.autoLoan),
  mortgage: getLiability(liabilities, LIABILITY_TYPE.mortgage),
  personalLoan: getLiability(liabilities, LIABILITY_TYPE.personalLoan),
});
