import {
  Button, Text, Title, theme
} from '@huspy/forge';
import {
  Box, Flex, Group
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { minMd } from '@shared/css.const';
import { IconChevronLeft, IconHome } from '@tabler/icons-react';
import clsx from 'clsx';
import { useAuthStore } from '@modules/core/store';
import { resetAmplitudeIdentity } from '@shared/analytics/amplitude';
import LanguageSwitcher from '@modules/core/components/LanguageSwitcher';
import { headerStyles } from './styles/index.css';

const {
  container,
  header,
  titles,
  wrapper,
  subtitle: subtitleStyle,
  titleChevron,
} = headerStyles;

type Props = {
  title: string | React.ReactNode;
  subtitle: string;
  isCompact?: boolean;
  wrapperClassName?: string;
  backArrowLink?: string;
};

const Header = ({
  subtitle, title, isCompact, wrapperClassName, backArrowLink,
}: Props) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(minMd);
  const navigate = useNavigate();
  const { logOut, oppId } = useAuthStore();

  return (
    <Flex className={ container({ isCompact }) } w='100%'>
      <Flex
        className={ clsx(wrapper({ isCompact }), wrapperClassName) }
        w='100%'
        h='100%'
        direction='column'
      >
        <Flex
          component='header'
          className={ header }
          w='100%'
          { ...(isCompact ? { visibleFrom: 'md' } : {}) }
        >
          <Text
            component={ Link }
            to='/'
            size={ !isDesktop ? 'lg' : 'md' }
            c='neutral.0'
            fw={ 600 }
            data-test='vault-home-header'
          >
            { t('home.header.appName') }
          </Text>
          <Group>
            <LanguageSwitcher />

            <Button
              variant='secondary'
              inverted
              visibleFrom='md'
              data-test='vault-logout-btn'
              onClick={ () => {
                const navigateLink = `/login/${oppId ?? ''}`;
                resetAmplitudeIdentity();
                logOut();
                navigate(navigateLink);
              } }
            >
              { t('home.header.logout') }
            </Button>
          </Group>
        </Flex>
        <Box className={ titles({ isCompact }) } pos='relative'>
          <Flex { ...(!isCompact ? { display: 'none' } : { hiddenFrom: 'md' }) }>
            <IconChevronLeft
              color={ theme.colors.neutral[0] }
              size={ 30 }
              cursor='pointer'
              stroke={ 1.5 }
              onClick={ () => navigate(backArrowLink!) }
            />
          </Flex>
          { !!backArrowLink?.length && (
            <Box visibleFrom='md' className={ titleChevron } onClick={ () => navigate(backArrowLink) }>
              <IconChevronLeft height={ 25 } width={ 25 } color={ theme.colors.neutral[0] } stroke={ 1.5 } />
            </Box>
          ) }
          <Title
            size='h4'
            c='neutral.0'
            { ...(isCompact ? { visibleFrom: 'md' } : {}) }
          >
            { title }

          </Title>
          <Text
            size='md'
            c='neutral.0'
            fw={ 600 }
            { ...(!isCompact ? { display: 'none' } : { hiddenFrom: 'md' }) }
          >
            { title }

          </Text>
          <Text
            size={ isDesktop ? 'md' : 'sm' }
            c='neutral.0'
            { ...(isCompact ? { visibleFrom: 'md' } : {}) }
            className={ subtitleStyle }
          >
            { subtitle }
          </Text>
          <Flex { ...(!isCompact ? { display: 'none' } : { hiddenFrom: 'md' }) }>
            <IconHome
              onClick={ () => navigate('/') }
              stroke={ 1.5 }
              color={ theme.colors.neutral[0] }
              size={ 30 }
              cursor='pointer'
            />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Header;
