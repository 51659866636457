import {
  Box, Flex, Skeleton, rem
} from '@mantine/core';
import { theme } from '@huspy/forge';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '@modules/core/store';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';

// Components
import NavigatorCard from '@modules/core/components/NavigatorCard';
import FloatingContainer from '@modules/core/components/FloatingContainer';
import StatusBadge from '@modules/core/components/StatusBadge';
import Card from '@modules/core/components/Card';
import SecurityDisclaimer from '@modules/documents/components/SecurityDisclaimer';

// Hooks
import useVaultData from '@modules/documents/hooks/queries/useVaultData';
import useKYCData from '@modules/kyc/hooks/queries/useKYCData';
import useVaultProgress from '@modules/core/hooks/queries/useVaultProgress';

// Types
interface NavigatorCardProps {
  isLoading: boolean;
  progress?: number;
  to: string;
  title: string;
  description: string;
  buttonText: string;
  iconName?: string;
  note?: string;
  disabled?: boolean;
  trackEvent?: () => void;
  progressBarFullWidth?: boolean;
  'data-test': string;
}

// Helper functions
const countPendingActions = (needsDocuments: boolean, needsKYC: boolean): number =>
  [needsDocuments, needsKYC].filter(Boolean).length;

const LoadingSkeleton: React.FC<{ height: number; radius?: string }> = ({
  height,
  radius = '0',
}) => (
  <Skeleton height={ rem(height) } radius={ radius } />
);

const NavigatorCardWrapper: React.FC<NavigatorCardProps> = (props) => (
  <NavigatorCard
    { ...props }
    styles={ { root: { width: '100%' } } }
  />
);

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { oppId } = useAuthStore();

  const { isLoading: isVaultLoading } = useVaultData(oppId);
  const { isLoading: isKycLoading, isDisabled: isKycDisabled } = useKYCData(oppId);
  const {
    data: vaultProgressData,
    isLoading: isOverviewLoading,
  } = useVaultProgress(oppId!);

  const { documentProgress, kycProgress } = vaultProgressData;
  const { uploaded: validDocuments, total: totalDocuments } = documentProgress;

  const pendingActions = countPendingActions(
    totalDocuments > validDocuments,
    !isKycDisabled && kycProgress.total > kycProgress.count
  );

  const getStatusText = () => {
    if (validDocuments === 0) return t('home.documentsOverview.pending');
    if (validDocuments < totalDocuments) return t('home.documentsOverview.inProgress');
    return t('home.documentsOverview.complete');
  };

  const renderStatus = () => {
    if (isVaultLoading && isKycLoading) {
      return <LoadingSkeleton height={ 25 } radius='12px' />;
    }
    return (
      <StatusBadge
        text={ getStatusText() }
        actionsLeft={ pendingActions }
      />
    );
  };

  const renderDocumentsCard = () => {
    if (isOverviewLoading) {
      return <LoadingSkeleton height={ 122 } radius='12px' />;
    }

    return (
      <NavigatorCardWrapper
        isLoading={ isOverviewLoading }
        progress={ documentProgress.uploaded_percentage || undefined }
        to='/documents'
        title={ t('home.documentsOverview.documentsCard.title') }
        description={ t('home.documentsOverview.documentsCard.description') }
        buttonText={ t('home.documentsOverview.documentsCard.buttonText') }
        progressBarFullWidth={ false }
        data-test='documents-title'
      />
    );
  };

  const renderKYCCard = () => {
    if (isOverviewLoading) {
      return <LoadingSkeleton height={ 122 } radius='12px' />;
    }

    return (
      <NavigatorCardWrapper
        isLoading={ isOverviewLoading }
        progress={ kycProgress.percentage || undefined }
        to='/kyc'
        title={ t('home.kycCard.title') }
        description={ t('home.kycCard.description') }
        note={ t('home.kycCard.note') }
        buttonText={ isKycDisabled ? 'Not requested' : t('home.kycCard.buttonText') }
        iconName={ isKycDisabled ? 'sign-document-grey.svg' : 'sign-document.svg' }
        disabled={ isKycDisabled }
        trackEvent={ () => trackAmplitudeEvent('KYC Clicked') }
        progressBarFullWidth={ false }
        data-test='getting-to-know-you-title'
      />
    );
  };

  return (
    <FloatingContainer>
      <Card>
        <Flex direction='column' justify='space-between'>
          <Box>
            {renderStatus()}
            <Flex
              direction='column'
              gap={ theme.spacing.xl }
              mt={ theme.spacing.lg }
            >
              {renderDocumentsCard()}
              {renderKYCCard()}
            </Flex>
          </Box>
          <SecurityDisclaimer />
        </Flex>
      </Card>
    </FloatingContainer>
  );
};

export default Home;
