import AppSkeleton from '@modules/core/components/Skeleton';
import { Suspense, lazy } from 'react';
import { useOutletContext } from 'react-router-dom';

const KYCFinanceES = lazy(() => import('./features/es'));
const KYCFinanceUAE = lazy(() => import('./features/ae'));

const KYCFinance = () => {
  const { isSpainBusiness } = useOutletContext<{ isSpainBusiness: boolean }>();

  return (
    <Suspense fallback={ <AppSkeleton.AplicantKycForm /> }>
      {isSpainBusiness ? <KYCFinanceES /> : <KYCFinanceUAE />}
    </Suspense>
  );
};

export default KYCFinance;
