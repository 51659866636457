import { FormInput, FormSelectInput } from '@modules/core/components/FormInput';
import {
  countriesJSON as jsonCountries,
  industriesJSON as industries,
} from '@huspy/forge/lib';

import {
  CURRENCY, EMPLOYMENT_STATUS, INCOME_TYPE
} from '@modules/kyc/api/const';
import { useVaultFormContext } from '@modules/core/pages/Home/v2/form-context';
import {
  Box, Button, Flex, Text
} from '@huspy/briks-web';
import { useState } from 'react';
import { Cancel } from '@huspy/briks-icons';
import { Divider } from '@huspy/styled-system/jsx';
import { formRowStyles } from '../../styles.css';

const incomeOptions = Object.values(INCOME_TYPE).map((type) => ({
  value: type,
  label: type.charAt(0).toUpperCase() + type.slice(1),
}));

const COUNTRIES = jsonCountries.map(
  ({ name, alpha2 }: { name: string; alpha2: string }) => ({
    label: name,
    value: alpha2,
  })
);

const KYCEmployment = ({ formKey }: { formKey: string }) => {
  const form = useVaultFormContext();

  const applicantIndex = Number(formKey.split('.')[1]);

  const initialValue = form.getValues().applicants[applicantIndex]?.incomes!;
  const [incomes, setIncomes] = useState(initialValue);

  const addAnotherIncome = () => {
    const newIncome = {
      external_id: null,
      amount: null,
      type: null,
      frequency: 'monthly',
    };

    setIncomes([...incomes, newIncome]);

    form.insertListItem(`${formKey}.incomes`, newIncome);
  };

  const removeIncome = (index: number) => {
    setIncomes(incomes.filter((_, i) => i !== index));

    form.removeListItem(`${formKey}.incomes`, index);
  };

  return (
    <Box bg='base.white' py='2' px='6' borderRadius='4'>
      <Box>
        <Text size='xl' my='4'>
          Income
        </Text>
        {incomes.map((income, i) => (
          <Flex
            key={ income.external_id ?? crypto.randomUUID() }
            className={ formRowStyles }
          >
            <Flex w='100%' gap='6' justify='center' align='center'>
              <FormSelectInput
                form={ form }
                name={ `${formKey}.incomes.${i}.type` }
                label='Income type'
                data={ incomeOptions }
                data-test={ `select-income-type-${i}` }
              />
              <FormInput
                form={ form }
                name={ `${formKey}.incomes.${i}.amount` }
                label='Monthly income'
                trail={ CURRENCY.AED }
                type='number'
                data-test={ `input-monthly-income-${i}` }
              />
              {incomes.length !== 1 && (
                <Button
                  onClick={ () => removeIncome(i) }
                  type='button'
                  variant='secondary'
                  size='xs'
                  borderRadius='full'
                  data-test={ `button-remove-income-${i}` }
                  position='relative'
                  top='2'
                  p='0'
                  // to be remove
                  display='none'
                >
                  <Cancel height={ 15 } width={ 15 } />
                </Button>
              )}
            </Flex>
          </Flex>
        ))}
        <Flex gap='3' justify='flex-end' mt='3'>
          <Button
            onClick={ addAnotherIncome }
            variant='secondary'
            type='button'
            disabled={ incomes.length >= 4 }
            mt='2'
            data-test='button-add-income'
          >
            Add income
          </Button>
        </Flex>
      </Box>
      <Divider my='10' />
      <Text size='xl' my='4'>
        Employment information
      </Text>
      <Flex className={ formRowStyles }>
        <FormSelectInput
          form={ form }
          name={ `${formKey}.employment.employment_type` }
          label='Employment type'
          data={ [
            {
              label: 'Self employed',
              value: EMPLOYMENT_STATUS.selfEmployed,
            },
            {
              label: 'Salaried',
              value: EMPLOYMENT_STATUS.salaried,
            }
          ] }
          data-test='select-employment-type'
        />
        <FormInput
          form={ form }
          name={ `${formKey}.employment.company_name` }
          label={ "Company's name" }
          data-test='input-company-name'
        />
      </Flex>
      <Flex className={ formRowStyles }>
        <FormSelectInput
          form={ form }
          name={ `${formKey}.employment.industry` }
          label={ "Company's industry" }
          data={ industries }
          placeholder='Select an option'
          data-test='select-company-industry'
        />
        <FormInput
          form={ form }
          name={ `${formKey}.employment.job_title` }
          label='Job title'
          data-test='input-job-title'
        />
      </Flex>
      <Flex className={ formRowStyles }>
        <FormInput
          form={ form }
          name={ `${formKey}.employment.company_personal_email` }
          label={ "Company's personal email" }
          data-test='input-company-personal-email'
        />
        <FormInput
          form={ form }
          name={ `${formKey}.employment.company_address.street` }
          label='Company address'
          data-test='input-company-address'
        />
      </Flex>
      <Flex className={ formRowStyles }>
        <FormInput
          form={ form }
          name={ `${formKey}.employment.company_address.city` }
          label='Company City'
          data-test='input-company-city'
        />
        <FormSelectInput
          form={ form }
          name={ `${formKey}.employment.company_address.country_code` }
          label='Company Country'
          data={ COUNTRIES }
          placeholder='Pick a country'
          data-test='select-company-country'
        />
      </Flex>
      <Flex className={ formRowStyles }>
        <FormInput
          form={ form }
          name={ `${formKey}.employment.company_address.postal_code` }
          label='Company ZIP'
          data-test='input-company-zip'
        />
        <FormInput
          form={ form }
          name={ `${formKey}.employment.company_contact_no` }
          label='Company phone number'
          type='phone'
          data-test='input-company-phone'
        />
      </Flex>
      <Flex className={ formRowStyles }>
        <FormInput
          form={ form }
          name={ `${formKey}.employment.no_of_employees` }
          label='No of employees'
          type='number'
          data-test='input-no-of-employees'
        />
        <FormInput
          form={ form }
          name={ `${formKey}.employment.company_hr_email` }
          label='Company HR email'
          type='text'
          data-test='input-company-hr-email'
        />
      </Flex>
      <FormInput
        form={ form }
        name={ `${formKey}.employment.company_website` }
        label='Company website'
        type='text'
        data-test='input-company-website'
      />
    </Box>
  );
};

export default KYCEmployment;
